<div
  class="tile-wrapper focusable"
  [ngSwitch]="fileType"
  [ngClass]="[fileType, mode]"
  [tabindex]="mode === 'thumbnail' ? 0 : -1"
  [style.pointerEvents]="nonStreamMedia() ? 'initial' : 'none'"
  [attr.role]="fileType === FILE_TYPE.Page ? 'link' : 'button'"
  [attr.aria-label]="
    fileType === FILE_TYPE.Page
      ? 'Open Page: ' + entry.submission_name
      : 'Preview Content Dialog: ' + entry.submission_name
  "
  (keyup.enter)="
    fileType !== FILE_TYPE.Unknown && tileChange(PlayerStatus.PREVIEW)
  "
  style="margin-right: 20px; margin-bottom: 10px; background-color: black;"
>
  <div
    class="file-name"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxShow.gt-xs
    fxHide
    *ngIf="mode === 'thumbnail'"
  >
    <h1 class="mat-body">
      {{
        entry.submission_name
          ? entry.submission_name
          : 'Unknown submisison name'
      }}
    </h1>
  </div>
  <portal-image-player
  *ngSwitchCase="FILE_TYPE.Image"
  (imageChange)="tileChange($event)"
  [src]="entry.cdn_Link"
  [alt]="entry.submission_name"
  [mode]="mode"
  fxLayout="row"
  fxFlex="100%"
  fxLayoutAlign="center center"
></portal-image-player>
<portal-video-player
  *ngSwitchCase="FILE_TYPE.Video"
  (videoChange)="tileChange($event)"
  [autoplay]="autoplay"
  [mode]="mode"
  [src]="entry.cdn_Link"
></portal-video-player>
<portal-youtube-player
  *ngSwitchCase="FILE_TYPE.YouTube"
  (youtubeChange)="tileChange($event)"
  [index]="tileIndex"
  [src]="entry.cdn_Link"
  [mode]="mode"
  [class]="mode"
  [autoplay]="autoplay ? 1 : 0"
></portal-youtube-player>
<portal-page-player
  *ngSwitchCase="FILE_TYPE.Page"
  (pageChange)="tileChange($event)"
  [src]="entry.cdn_Link"
  [alt]="entry.submission_name"
  [mode]="mode"
></portal-page-player>

  <div
    class="unknown-file"
    *ngSwitchCase="FILE_TYPE.Unknown"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
  >
    <mat-icon>image_search</mat-icon>
    <p class="mat-body" fxFlex="100%">
      Missing or<br />
      Broken File
    </p>
  </div>
</div>
