import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { University, Universities } from '@shared/types';
import { SharedHttpService } from '@shared/services/api/shared-http/shared-http.service';
import {
  GetUniversitySuccess,
  GetUniversityError
} from '@shared/states/university/university.actions';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UniversityService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'university';
  private port = '8000';
  private success = 'success';
  private error = 'error';
  constructor(private sharedHttp: SharedHttpService, private store: Store) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  getUniversityByDomain(domain: string): void {
    const path = `${this.API}/portals/university/find/url/${domain}`;
    this.sharedHttp.get(path).subscribe(
      (res: Universities) => {
        this.store.dispatch(new GetUniversitySuccess(res));
      },
      err => {
        // Throw error
        const { error } = err;
        this.store.dispatch(new GetUniversityError(error.message, this.error));
      }
    );
  }
}
