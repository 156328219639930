<div class="container" *ngIf="entries">

  <div
   *ngIf="showMetadata"
   (mouseenter)="checkShow()"
   fxLayout="row">
    <button
      name="prev"
      mat-icon-button
      (click)="prevSlide()"
      class="prev btn-nav"
      aria-label="Move to previous element">
      <mat-icon class="black">arrow_back_ios_new<mat-icon class="white">arrow_back_ios_new</mat-icon></mat-icon>
    </button>
    <button
    name="next"
      class="next btn-nav"
      mat-icon-button
      (click)="nextSlide()"
      aria-label="Move to next element">
      <mat-icon class="white">arrow_forward_ios<mat-icon class="black">arrow_forward_ios</mat-icon></mat-icon>
    </button>
  </div>

  <ngb-carousel
  #myCarousel class="carousel"
  [interval]="15000"
  [showNavigationIndicators]="showMetadata"
  [showNavigationArrows]="false"
  (mouseenter)="checkShow()"
  (mouseleave)="delayMetadata()"
  (slid)="onSlid($event)"
  (slide)="onSlide($event)">

    <ng-template ngbSlide *ngFor="let entry of entries; let i=index">
      <div *ngIf="showMetadata" class="tools top row">
        <p class="desc">{{ entry.submission_name }}<br>{{ entry.description }}</p>
        <mat-icon [matMenuTriggerFor]="share" aria-label="Share on social media">share</mat-icon>
      </div>
      <div [ngSwitch]="getFileType(entry.cdn_Link)">
        <ng-container *ngSwitchCase="FILE_TYPE.Image">
          <div
          fxLayoutAlign="center center"
          fxFlexFill
          class="picsum-img-wrapper">
            <img [src]="entry.cdn_Link" alt="Image {{entry.id}}"/>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="FILE_TYPE.Video">
          <portal-video-player
          [src]="entry.cdn_Link"
          [autoplay]="false"
          [mode]="'player'"
          (videoChange)="videoStatus($event)"
          ></portal-video-player>
          <!-- <video controls preload="metadata" [id]="'playermp4'+i"  (click)="onPlayPauseMp4($event)"  (playing)="videoStatus(1)" (pause)="videoStatus(2)">
            <source [src]="entry.cdn_Link" type="video/mp4" />
          </video>
          <button *ngIf="pausedMp4" class="btn-mp4-play" (click)="onPlayPauseMp4($event)"><mat-icon class="icon-play">play_arrow</mat-icon></button>
           -->
        </ng-container>

        <ng-container *ngSwitchCase="FILE_TYPE.YouTube">
          <div style="padding-top: 12vh;"></div>
          <div class="embed-container">
            <youtube-player
            [id]="'playerYT'+i"
            [videoId]="getId(entry.cdn_Link)"
            suggestedQuality="highres"
            (stateChange)="_stateChange($event)"
            (ready)="_ready($event)">
            </youtube-player>
          </div>
        </ng-container>
      </div>
    </ng-template>

  </ngb-carousel>

  <mat-menu #share="matMenu" >
    <button
      mat-menu-item
      class="title-menu"
      [disableRipple]="true"
      (click)="$event.stopPropagation()">
      Share on:
    </button>
    <button mat-menu-item (click)="shareOn('facebook')">
      <mat-icon [style.color]="'#465993'" svgIcon="facebook"></mat-icon>
      Facebook
    </button>
    <button mat-menu-item (click)="shareOn('twitter')">
      <mat-icon [style.color]="'#76a9ea'" svgIcon="twitter"></mat-icon>
      Twitter
    </button>
  </mat-menu>
</div>


