import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { InputsModule } from '../inputs/inputs.module';
import { FiltersComponent } from './filters.component';




@NgModule({
  declarations: [FiltersComponent],
  imports: [
    InputsModule,
    CommonModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule

  ],
  exports: [FiltersComponent],
})
export class FiltersModule { }
