<iframe
  *ngIf="mode === 'player'"
  [src]="_src"
  frameborder="0"
  #page
  (error)="error()"
  (load)="load()"
></iframe>
<div
  class="placeholder"
  [class.loading]="loading"
  *ngIf="mode === 'thumbnail'"
  fxLayout="row wrap"
  fxLayoutAlign="center center"
>
  <img *ngIf="thumbnail" [src]="thumbnail" aria-hidden="true" alt="" />
  <ng-container *ngIf="!thumbnail && !loading">
    <mat-icon>web</mat-icon>
    <span fxFlex="100%">WEB CONTENT</span>
  </ng-container>
</div>
<button
  *ngIf="mode === 'thumbnail'"
  tabindex="-1"
  class="play-icon"
  fxLayout="row"
  mat-icon-button
  fxLayoutAlign="center center"
  matTooltip="Open Page Content"
  aria-label="Open page content"
  role="link"
  (click)="preview()"
>
  <mat-icon>link</mat-icon>
</button>
