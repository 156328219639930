<div class="login-wrapper" fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card fxLayout="column">
    <div
      class="mat-elevation-z4"
      fxLayoutAlign="center start"
      mat-card-lg-image
      *ngIf="logo"
      [style.minHeight.px]="logo.logo_height"
    >
      <portal-logo
        [hidden]="!logoLoaded"
        [@fadeOnLoad]="logoLoaded ? 'visible' : 'hidden'"
        [logo]="logo"
        (loaded)="logoLoaded = true"
      ></portal-logo>
    </div>
    <mat-card-content fxLayout="row wrap" fxFlex fxLayoutAlign="center start">
      <h1 fxFlex="100%" fxLayoutAlign="center start">ACCESS DENIED</h1>
      <p fxFlex="100%" fxLayoutAlign="center start">
        You don't have access to this local form
      </p>
      <button
        type="submit"
        mat-stroked-button
        class="primary"
        (click)="mainScreen()"
      >
        RETURN TO MAIN SCREEN
      </button>
    </mat-card-content>
    <mat-card-footer fxLayoutAlign="center start">
      <p class="mat-body" aria-label="Login issues">
        For content related support,  please contact <a href="mailto:ces-content@apogee.us" target="_blank">ces-content@apogee.us</a>
        <br>
        For technical or other support, please contact <a href="mailto:ces-support@apogee.us" target="_blank">ces-support@apogee.us</a>
      </p>
    </mat-card-footer>
  </mat-card>
  <div
    class="apogee"
    fxFlex="100%"
    fxLayout="row wrap"
    fxLayoutAlign="center start"
  >
    <img
      src="assets/icons/logo-black.png"
      alt="Apogee logo"
      aria-label="Apogee logo"
      width="120px"
    />
    <p fxFlex="100%">{{ apogee }}</p>
  </div>
</div>
