<div
  class="filters-container"
  [fxLayout]="isLandscape ? 'column' : 'row'"
  fxLayoutAlign="start center"
>
  <div class="v-line" fxHide fxShow.gt-sm></div>
  <portal-select
    fxHide
    fxShow.gt-sm
    ariaLabel="Sort combobox"
    [value]="config.sortBy"
    class="not-underline"
    [options]="sortBy"
    [disabled]="!!viewmode"
    label="Sort by"
    [isFilterBy]="false"
    (selectionChange)="filterChange($event, 'sortBy')"
  ></portal-select>
  <div class="v-line" fxHide fxShow.gt-sm></div>
  <portal-select
    *ngIf="filterBy && filterBy.length > 0"
    fxHide
    fxShow.gt-sm
    ariaLabel="Filter combobox"
    [value]="config.filterBy"
    class="not-underline"
    [groupOpts]="filterBy"
    [disabled]="!!viewmode"
    label="Filter by"
    [isFilterBy]="true"
    (selectionChange)="filterChange($event, 'filterBy')"
  ></portal-select>
  <div class="v-line" fxHide fxShow.gt-sm></div>
  <portal-select
    fxHide
    fxShow.gt-sm
    ariaLabel="Order combobox"
    [value]="config.orderBy"
    class="not-underline"
    [options]="orderBy"
    [disabled]="!!viewmode"
    label="Order"
    [isFilterBy]="false"
    (selectionChange)="filterChange($event, 'orderBy')"
  ></portal-select>
  <div class="v-line" fxHide fxShow.gt-sm></div>
  <div
    class="view-mode"
    [style.paddingRight.px]="!!viewmode && !isLandscape ? 15 : 0"
    [class.landscape]="isLandscape"
  >
    <span fxHide fxShow.gt-sm>View mode</span>
    <div
      fxLayoutAlign="space-between center"
      [fxLayout]="isLandscape ? 'column' : 'row'"
    >
      <mat-icon [class.active]="!viewmode">view_module</mat-icon>
      <mat-slide-toggle
        ariaLabel="view mode"
        class="themed"
        [checked]="!!viewmode"
        (toggleChange)="selectViewMode()"
      >
        <label [style.visibility]="'hidden'" [style.position]="'absolute'"
          >view mode</label
        >
      </mat-slide-toggle>
      <mat-icon [class.active]="!!viewmode">view_carousel</mat-icon>
    </div>
  </div>
  <div class="v-line" fxHide fxShow.gt-sm></div>
  <div class="h-line" *ngIf="isLandscape && !viewmode"></div>
</div>
<button
  mat-icon-button
  fxShow
  fxHide.gt-sm
  (click)="filtersDialog()"
  *ngIf="!viewmode"
>
  <mat-icon>filter_alt</mat-icon>
</button>
