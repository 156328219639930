<div class="slide-container">
  <div class="tools top" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon (click)="leave()" *ngIf="hasWrapper">arrow_back_ios</mat-icon>
    <p class="name">
      {{ entries[selected].submission_name }}
    </p>
    <mat-icon [matMenuTriggerFor]="share" aria-label="Share on social media"
      >share</mat-icon
    >
    <mat-icon
      [class.hidden]="!showInfo"
      (click)="showInfo = !showInfo"
      [attr.arial-label]="showInfo ? 'Hide description' : 'Show description'"
      >description</mat-icon
    >
  </div>
  <div class="controls" fxLayout="row">
    <button
      mat-icon-button
      *ngIf="selected > 0"
      (click)="prevSlide()"
      class="prev"
      aria-label="Move to previous element"
    >
      <mat-icon>arrow_back_ios_new</mat-icon>
    </button>
    <button
      class="next"
      mat-icon-button
      *ngIf="selected < entries.length - 1"
      (click)="nextSlide()"
      aria-label="Move to next element"
    >
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
  <div class="slides" #slides [style.width]="fixedWidth()">
    <ng-container *ngFor="let entry of entries; let i = index">
      <ng-container
        *ngTemplateOutlet="slide; context: { entry: entry, index: i }"
      ></ng-container>
    </ng-container>
  </div>
  <div class="tools bottom" fxLayout="row" *ngIf="showInfo">
    <p *ngIf="selected > -1">{{ entries[selected].description }}</p>
  </div>
</div>

<ng-template #slide let-entry="entry" let-index="index">
  <div
    [ngSwitch]="getFileType(entry.cdn_Link)"
    class="slide"
    [ngStyle]="indexPosition(index)"
    *ngIf="isRenderRange(index)"
  >
    <div class="content-wrapper" fxLayoutAlign="center center">
      <ng-container *ngSwitchCase="FILE_TYPE.Image">
        <div class="blur-background" *ngIf="hasWrapper">
          <img [src]="entry.cdn_Link" aria-hidden="true" />
        </div>
        <portal-image-player [src]="entry.cdn_Link"></portal-image-player>
      </ng-container>
      <portal-video-player
        *ngSwitchCase="FILE_TYPE.Video"
        [src]="entry.cdn_Link"
        [autoplay]="selected === index ? videoPlay : false"
        (videoChange)="videoStatus($event)"
      ></portal-video-player>
      <ng-container *ngSwitchCase="FILE_TYPE.YouTube">
        <div class="drag" fxLayoutAlign="center center">
          <div (click)="toggleAutoplay()"></div>
        </div>
        <portal-youtube-player
          [src]="entry.cdn_Link"
          [index]="index"
          [autoplay]="selected === index ? ytPlay : 0"
          (youtubeChange)="ytStatus($event)"
        ></portal-youtube-player>
      </ng-container>
      <div
        class="unknown-file"
        *ngSwitchCase="FILE_TYPE.Unknown"
        fxLayout="row wrap"
        fxLayoutAlign="center center"
      >
        <mat-icon>image_search</mat-icon>
        <p class="mat-body" fxFlex="100%">
          Missing or<br />
          Broken File
        </p>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #share="matMenu">
  <button
    mat-menu-item
    class="title-menu"
    [disableRipple]="true"
    (click)="$event.stopPropagation()"
  >
    Share on:
  </button>
  <button mat-menu-item (click)="shareOn('facebook')">
    <mat-icon [style.color]="'#465993'" svgIcon="facebook"></mat-icon>
    Facebook
  </button>
  <button mat-menu-item (click)="shareOn('twitter')">
    <mat-icon [style.color]="'#76a9ea'" svgIcon="twitter"></mat-icon>
    Twitter
  </button>
</mat-menu>
