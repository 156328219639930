<div
  class="field-wrapper"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxFlex="100%"
  [formGroup]="form"
  [attr.aria-label]="ariaLabel"
>
  <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
  <mat-form-field fxFlex floatLabel="always" >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <span matPrefix *ngIf="prefix">{{ prefix }}</span>
    <!-- REACTIVE FORM -->
    <select
      matInput
      matNativeControl
      [formControlName]="controlName"
      [placeholder]="placeholder"
      [attr.aria-label]="ariaLabel || 'Filter combobox'"
      *ngIf="controlName && !isRequired && !groupOpts && !activeSelectAdmin"
      multiple="multiple"
    >
      <option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
    <!-- Custom control applied for admin dropdown options  -->
    <mat-select
    *ngIf="controlName && activeSelectAdmin"
    [formControlName]="controlName"
    [multiple]="multiple"
    (selectionChange)="selectionChange.emit($event)"
  >
    <mat-select-trigger *ngIf="!multiple">
      {{ options && setTriggerText(control.value) }}
    </mat-select-trigger>

    <mat-option *ngFor="let option of options" [value]="option.value">
      <div
        class="option-wrapper"
        [class.multiple]="multiple"
        fxLayout="row"
        fxLayoutAlign=" center"
      >
        <mat-icon *ngIf="!multiple">
      {{  control.value === option.value
          ? 'check_circle'
          : 'radio_button_unchecked'}}
      </mat-icon>
        <span [class.has-hint]="!!option.hint" fxLayoutAlign="start center">
          {{ option.label }}
          <span *ngIf="option.hint" class="hint">{{ option.hint }}</span>
        </span>
      </div>
    </mat-option>
  </mat-select>


    <!-- EVENT DRIVEN  -->
    <select
      matNativeControl
      *ngIf="!controlName && !isRequired && !groupOpts"
      [multiple]="multiple"
      [placeholder]="placeholder"
      [attr.aria-label]="ariaLabel || 'Filter combobox'"
      (change)="changeEvent($event)"
      class="focusable"
      [disabled]="disabled"
    >
      <option
        *ngFor="let option of options"
        [value]="option.value"
        [disabled]="option.disabled"
        [selected]="value === option.value"
      >
        {{ option.label }}</option
      >
    </select>
    <!-- Need it this way for screen readers D: -->
    <select
      matNativeControl
      *ngIf="!controlName && isRequired && !groupOpts"
      [multiple]="multiple"
      [attr.aria-label]="ariaLabel || 'Filter combobox'"
      [placeholder]="placeholder"
      (change)="changeEvent($event)"
      required
    >
      <option
        *ngFor="let option of options"
        [value]="option.value"
        [disabled]="option.disabled"
        [selected]="value === option.value"
      >
        {{ option.label }}</option
      >
    </select>
    <!-- Group Select -->
    <select
      matNativeControl
      *ngIf="groupOpts && !isFilterBy"
      [placeholder]="placeholder"
      [attr.aria-label]="ariaLabel || 'Filter combobox'"
      (change)="changeEvent($event)"
      class="focusable"
      [disabled]="disabled"

    >
        <optgroup *ngFor="let group of groupOpts" [label]="group.title">
          <option
            *ngFor="let opt of group.options"
            [value]="opt.value"
            [selected]="value === opt.value"
            >{{opt.label}}</option
          >
        </optgroup>
    </select>

    <mat-select *ngIf="groupOpts && isFilterBy"
                multiple
                [placeholder]="placeholder?placeholder:'None'"
                [disabled]="disabled"
                (selectionChange)="changeEventFilter($event)"
                [aria-label]="ariaLabel || 'Filter combobox'"
                disableOptionCentering
                [value]="['-1']">
        <mat-optgroup *ngFor="let group of groupOpts" [label]="group.title">
          <mat-option *ngFor="let option of group.options" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-optgroup>
    </mat-select>

    <span matSuffix *ngIf="suffix">{{ suffix }}</span>
    <mat-hint align="start" *ngIf="hintStart">{{ hintStart }}</mat-hint>
    <mat-hint align="end" *ngIf="hintEnd">{{ hintEnd }}</mat-hint>
    <mat-error *ngIf="controlName && errors(control)">
      <mat-icon>error</mat-icon>
      {{ errors(control) }}
    </mat-error>
  </mat-form-field>
</div>
