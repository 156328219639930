import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionListChange } from '@angular/material/list';

import { Filters } from '@shared/types';

@Component({
  selector: 'portal-mobile-filter',
  templateUrl: './mobile-filter.component.html',
  styleUrls: ['./mobile-filter.component.scss']
})
export class MobileFilterComponent {
  get filterBy(): any {
    return this.data.filterBy;
  }

  get orderBy(): any {
    return this.data.orderBy;
  }
  get sortBy(): any {
    return this.data.sortBy;
  }
  get config(): any {
    return this.data.config;
  }
  get selectedValues():any{
    return this.data.selectedValues;
  }

  set selectedValues(data:any){
    this.data.selectedValues=data;
  }

  _config = this.config;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  filterChange(value: number, id: string): void {
    switch (id) {
      case 'filterBy':
        this._config = {
          ...this._config,
          filterBy: value
        }
        break;
      case 'orderBy':
        this._config = {
          ...this._config,
          orderBy: value
        }
        break;
      case 'sortBy':
        this._config = {
          ...this._config,
          sortBy: value
        }
        break;
    }
  }

  filterByChange(value:MatSelectionListChange){

    let selectedOptions=value.source.selectedOptions.selected;
    let _arrayCompare:any[]=[];
    value.source.options.map(item=>{
      if(!item.selected){
        _arrayCompare.push({value:item.value,selected:item.selected});
      }
    })

    //Add selected values
    if(selectedOptions.length>0){
      let _array=selectedOptions.map(x=>x.value);

      let _newVal:any[]=[];
      _array.forEach(element => {
        if(!this.selectedValues.some(x=>x==element)){
          _newVal.push(element);
        }
      });
      this.data.selectedValues=this.data.selectedValues.concat(_newVal);
    }

    //Only None
    if(selectedOptions.length==1 && selectedOptions.some(x=>x.value=='-1')){
      let _newArray:number[]=[];
        this.selectedValues.forEach((item, index) => {
          if(item!='-1'){
            _newArray.push(index);
          }
        });
        _newArray.reverse().forEach(item=>{
          this.selectedValues.splice(item,1);
        })
    }

    //Only filters valid, not None
    if(this.selectedValues.length>1 && this.selectedValues.some(x=>x=='-1')){
      this.selectedValues.splice(this.selectedValues.indexOf('-1'),1);
    }

    //clean array items not selected
    _arrayCompare.reverse().forEach((item, index)=>{
      if(this.selectedValues.some(x=>x==item.value)){
        const pos=this.selectedValues.indexOf(item.value);
        var _selectedValues=Array.from(this.selectedValues);
        if(_selectedValues.length==1)
          _selectedValues=[];
        else
          _selectedValues.splice(pos,1);

        this.selectedValues=_selectedValues;
      }
    })

    if(this.selectedValues.length==0){
      this.selectedValues.push('-1');
    }

    //Applying filters
    this._config={
      ...this.config,
      filterBy:this.selectedValues
    }
  }

  checkSelected(value:any){
    return this.selectedValues.some(x=>x==value);
  }

}
