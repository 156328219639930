<div class="embed-player" fxLayout="row" fxLayoutAlign="center center">
  <div [ngSwitch]="fileType" [ngClass]="[mode, fileType]" *ngIf="url && !error">
    <portal-image-player *ngSwitchCase="FILE_TYPE.Image" [src]="url" [mode]="mode"
      (imageChange)="mediaChange($event)"></portal-image-player>
    <portal-video-player *ngSwitchCase="FILE_TYPE.Video" [src]="url" [mode]="mode" [autoplay]="autoplay"
      (videoChange)="mediaChange($event)"></portal-video-player>
    <portal-youtube-player *ngSwitchCase="FILE_TYPE.YouTube" [src]="url" [mode]="mode" [autoplay]="autoplay" [index]="index"
      (youtubeChange)="mediaChange($event)"></portal-youtube-player>
    <portal-page-player *ngSwitchCase="FILE_TYPE.Page" [src]="url" [mode]="mode"
      (pageChange)="mediaChange($event)"></portal-page-player>
  </div>
  <!-- SPINNER -->
  <mat-spinner *ngIf="!previewLoaded && url" diameter="70"></mat-spinner>
  <p *ngIf="!url || error" class="mat-body not-file" fxLayout="row wrap" fxLayoutAlign="center center">
    <chia-icon icon="image_search"></chia-icon>
    <span fxFlex="100%">Source File<br />Missing or Broken</span>
  </p>
</div>
