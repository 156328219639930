<mat-toolbar
  class="mat-elevation-z4"
  *ngIf="!hideTemplate"
  [class.mobile-landscape]="orientation === mobileLandscape"
>
  <mat-toolbar-row
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    *ngIf="logo"
  >
    <nav class="mat-body" fxFlex="100%" class="main-content">
      <a mat-stroked-button href (click)="goToMainContent($event)"
        >Skip to main content.</a
      >
    </nav>
    <portal-logo
      [hidden]="!logoLoaded"
      [@fadeOnLoad]="logoLoaded ? 'visible' : 'hidden'"
      [logo]="logo"
      [universityName]="universityName"
      (loaded)="logoLoaded = true"
    ></portal-logo>
    <mat-spinner
      *ngIf="!logoLoaded"
      diameter="40"
      [@fadeOnLoad]="logoLoaded ? '' : 'visible'"
    ></mat-spinner>
    <portal-filters
      *ngIf="orientation === mobileLandscape"
      [isLandscape]="true"
      fxFlex="100%"
      fxLayout="column"
      fxLayoutAlign="center center"
    ></portal-filters>
  </mat-toolbar-row>
  <mat-toolbar-row
    fxLayout="row"
    fxLayoutAlign="center center"
    fxShow.gt-sm
    fxHide
    [class.integrate]="isIntegrate"
  >
    <nav mat-tab-nav-bar class="mat-body" *ngIf="menus" aria-label="Main Menu">
      <ng-container *ngFor="let menu of menus" [ngSwitch]="menu.type">
        <a
          *ngSwitchCase="menuType.LINK"
          [attr.aria-label]="
            menu.name === 'SUBMIT' ? 'Submit an Ad' : 'Navigate to ' + menu.name
          "
          [active]="activeLink === menu.value"
          (click)="navigationType(menu.value)"
          (keyup.enter)="navigationType(menu.value)"
          mat-tab-link
        >
          <h1 [style.fontSize.px]="'14'">
            {{ menu.name }}
          </h1>
        </a>
        <ng-container *ngSwitchCase="menuType.DROPDOWN">
          <button
          aria-label="testing"
            [title]="'Expand ' + menu.name"
            mat-tab-link
            [matMenuTriggerFor]="dynamicMenu.menuRef"
          >
            <h1 [style.fontSize.px]="'14'">
              {{ menu.name }}
            </h1>
          </button>
          <portal-menus [menus]="menu.menus" #dynamicMenu></portal-menus>
        </ng-container>
      </ng-container>
    </nav>
    <button class="login" mat-stroked-button *ngIf="fixed" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      Log Out
    </button>
  </mat-toolbar-row>
</mat-toolbar>
