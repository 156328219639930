import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { HomeModule } from '@modules/home/home.module';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { SubmissionSuccessComponent } from './submission-success/submission-success.component';
import { PreviewContentComponent } from './preview-content/preview-content.component';
import { SlideshowWarpperComponent } from './slideshow-wrapper/slideshow-wrapper.component';
import { BlockerComponent } from './blocker/blocker.component';
import { LogoModule } from '../logo/logo.module';
import { RearrangeComponent } from './rearrange/rearrange.component';
import { FeedPlayerModule } from '../feed-player/feed-player.module';
import { MobileFilterComponent } from './mobile-filter/mobile-filter.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { EditPlaylistDataComponent } from './edit-playlist-data/edit-playlist-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '../inputs/inputs.module';
import { MatTabsModule } from '@angular/material/tabs';
import { IconModule } from '../icon/icon.module';
import { TableModule } from '../table/table.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    SubmissionSuccessComponent,
    PreviewContentComponent,
    BlockerComponent,
    RearrangeComponent,
    SlideshowWarpperComponent,
    MobileFilterComponent,
    EditPlaylistDataComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    HomeModule,
    FlexLayoutModule,
    FlexModule,
    LogoModule,
    DragDropModule,
    FeedPlayerModule,
    MatRadioModule,
    MatListModule,
    FormsModule,
    InputsModule,
    ReactiveFormsModule,
    IconModule,
    TableModule
  ],
  entryComponents: [
    SubmissionSuccessComponent,
    PreviewContentComponent,
    BlockerComponent,
    RearrangeComponent,
    SlideshowWarpperComponent,
    EditPlaylistDataComponent
  ]
})
export class DialogsModule { }
