import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RevolutionSliderContent } from '@shared/types';

@Component({
  selector: 'portal-slice',
  templateUrl: './slice.component.html',
  styleUrls: ['./slice.component.scss']
})
export class SliceComponent implements OnInit {
  @Input() slice: RevolutionSliderContent;
  @Output() focused: EventEmitter<boolean> = new EventEmitter(false);
  message: string;
  background: string;
  href: string;
  url: string;
  width: number;
  height: number;
  fixAlt = 'Open video link for tutorial on using the Orcatv system';
  constructor() {}

  ngOnInit() {
    const { message, background_color, href, url, width, height } = this.slice;
    this.message = message;
    this.background = background_color;
    this.href = href;
    this.url = url;
    this.width = width;
    this.height = height;
  }

  isFixAlt(message: string): boolean {
    const regexp = /What is Orcatv\? Create and submit an ad to screens across campus!/i;
    return regexp.test(message);
  }
}
