import { Injectable } from '@angular/core';
import { SharedHttpService } from '../shared-http/shared-http.service';
import { Store } from '@ngxs/store';
import {
  GetPublicPortalSuccess,
  GetPublicPortalError,
  GetFormNetworksPublicSuccess,
  GetFormNetworksPublicError
} from '@shared/states/public-portal/public-portal.actions';
import {
  GetGuidelinesSuccess,
  GetGuidelinesError
} from '@shared/states/guidelines/guidelines.actions';
import { PublicPortal, Guidelines, AdContent, Network, PortalMenuItem, PortalMenuItems, RevolutionSliderContent, ThemeColors, ThemeFeatures, LogoUpdate, VisualPortal } from '@shared/types';
import {
  GetAdContentSuccess,
  GetAdContentError
} from '@shared/states/create-and-ad/create-and-ad.actions';
import { environment } from 'environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreatePortalMenuError, CreatePortalMenuSuccess, CreateSliderItemError, CreateSliderItemSuccess, DeletePortalMenuError, DeletePortalMenuSuccess, DeleteSliderItemError, DeleteSliderItemSuccess, GetVisualPortalError, GetVisualPortalSuccess, UpdateLogoError, UpdateLogoSuccess, UpdatePortalMenuError, UpdatePortalMenuSuccess, UpdateThemeColorsError, UpdateThemeColorsSuccess } from '../../../states/visual/visual.actions';

@Injectable({
  providedIn: 'root'
})
export class PublicPortalService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'publicportal';
  private port = '8002';
  private success = 'success';
  private error = 'error';
  constructor(private store: Store, private sharedHttp: SharedHttpService) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  getPublicPortal(universityID: number): void {
    const path = `${this.API}/portals/publicportal/find/${universityID}`;

    this.sharedHttp.get(path).subscribe(
      (res: PublicPortal) => {
        const message = 'Public Portal Retrieved Successfully';
        this.store.dispatch(
          new GetPublicPortalSuccess(res, this.success, message)
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(
          new GetPublicPortalError(error.message, this.error)
        );
      }
    );
  }

  getAdContent(portalID: number): void {
    const path = `${this.API}/portals/publicportal/adcontent/find/${portalID}`;

    this.sharedHttp.get(path).subscribe(
      res => { },
      err => { }
    );
  }

  getGuidelines(portalID: number): void {
    const path = `${this.API}/portals/publicportal/guideline/find/${portalID}`;

    this.sharedHttp.get(path).subscribe(
      (res: Guidelines) => {
        const message = 'Guidlines Retrieved Successfully';
        this.store.dispatch(
          new GetGuidelinesSuccess(res, message, this.success)
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(new GetGuidelinesError(error.message, this.error));
      }
    );
  }

  getAddContent(portalID: number): void {
    const path = `${this.API}/portals/publicportal/adcontent/find/${portalID}`;

    this.sharedHttp.get(path).subscribe(
      (res: AdContent) => {
        const message = 'Ad Content Retrieved Successfully';
        this.store.dispatch(
          new GetAdContentSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetAdContentError(message, this.error));
      }
    );
  }

  getPortalNetworks(formID: number): void {
    const path = `${this.API}/portals/getformnetworks/${formID}`;
    this.sharedHttp.get(path).subscribe(
      (res: Array<any>) => {
        const networks = res.map(n => ({
          value: n.network_id,
          label: n.network_name
        }));
        this.store.dispatch(new GetFormNetworksPublicSuccess(networks));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetFormNetworksPublicError(message));
      }
    );
  }

  getHTMLPage(url: string): Observable<string> {
    const path = `${this.API}/portals/gethtml?url=${url}`;
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'text/html');
    return this.sharedHttp.get(path, headers, null, 'text');
  }

  createPortalMenuItem(menuItem: PortalMenuItem, customerID: number): void {
    const path = `${this.API}/publicportal/menuItems/create/${customerID}`;
    this.sharedHttp.post(path, [menuItem]).subscribe(
      (res: PortalMenuItems) => {
        this.store.dispatch(new CreatePortalMenuSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new CreatePortalMenuError(message));
      }
    );
  }


  deletePortalMenuItem(
    menuItem: PortalMenuItem,
    menuIDs: Array<number>,
    customerID: number
  ): void {
    const path = `${this.API}/publicportal/menuItems/remove/portal/${customerID}`;

    this.sharedHttp.post(path, menuIDs).subscribe(
      (res: any) => {
        this.store.dispatch(new DeletePortalMenuSuccess(menuItem));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new DeletePortalMenuError(message));
      }
    );
  }

  deleteSliderItem(sliderID: number): void {
    const path = `${this.API}/publicportal/slidercontent/remove/${sliderID}`;
    this.sharedHttp.delete(path).subscribe(
      res => {
        const message = 'Slider Item Deleted Successfully';
        this.store.dispatch(new DeleteSliderItemSuccess(message, this.success));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new DeleteSliderItemError(message, this.error));
      }
    );
  }

  createSliderItem(sliderContent: RevolutionSliderContent): void {
    const path = `${this.API}/publicportal/slidercontent/create`;
    this.sharedHttp.post(path, sliderContent).subscribe(
      (res: RevolutionSliderContent) => {
        const message = 'Slider Item Changes Applied';
        this.store.dispatch(
          new CreateSliderItemSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new CreateSliderItemError(message, this.error));
      }
    );
  }

  updatePortalMenuItem(
    menuItem: Array<PortalMenuItem>,
    customerID: number
  ): void {
    const path = `${this.API}/publicportal/menuItems/update/${customerID}`;
    this.sharedHttp.put(path, menuItem).subscribe(
      (res: PortalMenuItems) => {
        const menu = {
          portalMenu: {
            ...res.portalMenu,
            menus: menuItem[0].menus
          },
          message: res.message
        };
        this.store.dispatch(new UpdatePortalMenuSuccess(menu));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new UpdatePortalMenuError(message));
      }
    );
  }

  updateThemeColors(themeColors: ThemeColors, customerID: number): void {
    const path = `${this.API}/publicportal/themeFeatures/update/${customerID}`;
    this.sharedHttp.put(path, themeColors).subscribe(
      (res: ThemeFeatures) => {
        this.store.dispatch(new UpdateThemeColorsSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new UpdateThemeColorsError(message));
      }
    );
  }

  updateLogo(customerID: number, body: LogoUpdate): void {
    const path = `${this.API}/publicportal/updatelogo/${customerID}`;

    this.sharedHttp.put(path, body).subscribe(
      (res: ThemeFeatures) => {
        this.store.dispatch(new UpdateLogoSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new UpdateLogoError(message));
      }
    );
  }

  getVisualPortal(customerID: number): void {
    const path = `${this.API}/publicportal/find/${customerID}`;
    this.sharedHttp.get(path).subscribe(
      (res: VisualPortal) => {
        this.store.dispatch(new GetVisualPortalSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new GetVisualPortalError(message));
      }
    );
  }

}
