import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { GetEntriesAdminContentError, GetEntriesAdminContentSuccess, GetEntriesAdminError, GetEntriesAdminSuccess } from "@shared/states/files/files.actions";
import { EntriesBodyParams, FilesResponse } from "@shared/types";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SharedHttpService } from "../api/shared-http/shared-http.service";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'form';
  private port = '8004';
  private success = 'success';
  private error = 'error';
  readonly imageRegExp = new RegExp(/^(https|http):\/\/.*(\.jpg|\.jpeg)$/, 'i');
  readonly videoRegExp = new RegExp(/^(https|http):\/\/.*(\.mp4)$/, 'i');
  readonly youtubeRegExp = new RegExp(
    /^(https|http):\/\/(www\.youtube\.com\/watch\?v=|youtu.be|www\.youtube\.com\/embed\/).{11}$/,
    'i'
  );
  pageRegExp = new RegExp(/^(https|http):\/\/.*\.([a-z]{2,3})(\/.*)*/, 'i');
  constructor(private httpService: SharedHttpService, private store: Store) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  getEntriesAdmin(customerID: number, userId:string, body: EntriesBodyParams, isEntriesContent?:boolean): void {
    let path = `${this.API}/admin/get/${customerID}/entries/${userId}`;
    if(isEntriesContent)
      path=path+'?src=entries';

    this.httpService.post(path, body).subscribe(
      (res: FilesResponse) => {
        const message = 'Entries Admin Retrieved Successfully';
        if(isEntriesContent){
          this.store.dispatch(new GetEntriesAdminContentSuccess(res, message, this.success));
        }else{
          this.store.dispatch(new GetEntriesAdminSuccess(res, message, this.success));
        }

      },
      err => {
        const { message } = err.error;
        if(isEntriesContent){
          this.store.dispatch(new GetEntriesAdminContentError(message, this.error));
        }else{
          this.store.dispatch(new GetEntriesAdminError(message, this.error));
        }
      }
    );
  }

  getEntriesAdmin2(customerID: number, userId:string, body: EntriesBodyParams, isEntriesContent?:boolean): void {
    let path = `${this.API}/admin/get/${customerID}/entries/${userId}`;
    if(isEntriesContent)
      path=path+'?src=entries';

    this.httpService.post(path, body).subscribe(
      (res: FilesResponse) => {
        const message = 'Entries Admin Retrieved Successfully';
        if(isEntriesContent){
          this.store.dispatch(new GetEntriesAdminContentSuccess(res, message, this.success));
        }else{
          this.store.dispatch(new GetEntriesAdminSuccess(res, message, this.success));
        }

      },
      err => {
        const { message } = err.error;
        if(isEntriesContent){
          this.store.dispatch(new GetEntriesAdminContentError(message, this.error));
        }else{
          this.store.dispatch(new GetEntriesAdminError(message, this.error));
        }
      }
    );
  }

  isPublicPortalDisabled(universityId: any):Observable<boolean>{
    const path = `${this.API}/portals/isdisabled/${universityId}`;
    return this.httpService.get(path);
  }
}
