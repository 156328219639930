import {
  FilesResponse,
  EntriesBodyParams,
  CHIAFile,
  EntrySubmitter,
  EntryColumn
} from '@shared/types';

export class GetEntriesAdmin {
  static readonly type = 'GetEntriesAdmin';
  constructor(
    public customerID: number,
    public userId: string,
    public bodyParams: EntriesBodyParams
  ) {}
}

export class GetEntriesAdminSuccess {
  static readonly type = 'GetEntriesAdminSuccess';
  constructor(
    public payload: FilesResponse,
    public message: string,
    public status: string
  ) {}
}

export class GetEntriesAdminError {
  static readonly type = 'GetEntriesAdminError';
  constructor(public message: string, public status: string) {}
}

export class GetEntriesAdminContent {
  static readonly type = 'GetEntriesAdminContent';
  constructor(
    public customerID: number,
    public userId: string,
    public bodyParams: EntriesBodyParams
  ) {}
}

export class GetEntriesAdminContentSuccess {
  static readonly type = 'GetEntriesAdminContentSuccess';
  constructor(
    public payload: FilesResponse,
    public message: string,
    public status: string
  ) {}
}

export class GetEntriesAdminContentError {
  static readonly type = 'GetEntriesAdminContentError';
  constructor(public message: string, public status: string) {}
}

export class GetEntryAdminColumns {
  static readonly type = 'GetEntryAdminColumns';
  constructor(public formID: number) {}
}

export class GetEntryAdminColumnsSuccess {
  static readonly type = 'GetEntryAdminColumnsSuccess';
  constructor(
    public payload: Array<EntryColumn>,
    public message: string,
    public status: string
  ) {}
}

export class GetEntryAdminColumnsError {
  static readonly type = 'GetEntryAdminColumnsError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntrieAdminPanel {
  static readonly type = 'UpdateEntrieAdminPanel';
  constructor(public fileID: number, public panelID: number) {}
}

export class UpdateEntrieAdminPanelSuccess {
  static readonly type = 'UpdateEntrieAdminPanelSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Panel Updated Successfully'
  ) {}
}

export class UpdateEntrieAdminPanelError {
  static readonly type = 'UpdateEntrieAdminPanelError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdateEntrieNetworksHome {
  static readonly type = 'UpdateEntriesNetworksHome';
  constructor(public fileID: number, public networksIDs: Array<number>) {}
}

export class UpdateEntrieNetworksHomeSuccess {
  static readonly type = 'UpdateEntrieNetworksHomeSuccess';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntrieNetworksHomeError {
  static readonly type = 'UpdateEntrieNetworksHomeError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntriePanelHome {
  static readonly type = 'UpdateEntriePanelHome';
  constructor(public fileID: number, public panelID: number) {}
}

export class UpdateEntriePanelHomeSuccess {
  static readonly type = 'UpdateEntriePanelHomeSuccess';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntriePanelHomeError {
  static readonly type = 'UpdateEntriePanelHomeError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntrieStartEndDateHome {
  static readonly type = 'UpdateEntrieStartEndDateHome';
  constructor(
    public fileID: number,
    public dates: { startDate: string; endDate: string }
  ) {}
}

export class UpdateEntrieStartEndDateHomeSuccess {
  static readonly type = 'UpdateEntrieStartEndDateHomeSuccess';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntrieStartEndDateHomeError {
  static readonly type = 'UpdateEntrieStartEndDateHomeError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntrieStartEndDateHome2 {
  static readonly type = 'UpdateEntrieStartEndDateHome2';
  constructor(
    public fileID: number,
    public dates: { startDate: string; endDate: string }
  ) {}
}

export class UpdateEntrieStartEndDateHome2Success {
  static readonly type = 'UpdateEntrieStartEndDateHome2Success';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntrieStartEndDateHome2Error {
  static readonly type = 'UpdateEntrieStartEndDateHome2Error';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntrieDurationHome {
  static readonly type = 'UpdateEntrieDurationHome';
  constructor(public fileID: number, public duration: number) {}
}

export class UpdateEntrieDurationHomeSuccess {
  static readonly type = 'UpdateEntrieDurationHomeSuccess';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntrieDurationHomeError {
  static readonly type = 'UpdateEntrieDurationHomeError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntrieDurationHome2 {
  static readonly type = 'UpdateEntrieDurationHome2';
  constructor(public fileID: number, public duration: number) {}
}

export class UpdateEntrieDurationHome2Success {
  static readonly type = 'UpdateEntrieDurationHome2Success';
  constructor(
    public payload: any,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntrieDurationHome2Error {
  static readonly type = 'UpdateEntrieDurationHome2Error';
  constructor(public message: string, public status: string) {}
}


export class UpdateHomeFile {
  static readonly type = 'UpdateHomeFile';
  constructor(
    public fileID: number,
    public fileReq: File,
    public files: any
  ) {}
}
export class UpdateHomeFileSuccess {
  static readonly type = 'UpdateHomeFileSuccess';
  constructor(
    public payload: any,
    public status: string,
    public message: string
  ) {}
}

export class UpdateHomeFileError {
  static readonly type = 'UpdateHomeFileError';
  constructor(public message: string, public status: string) {}
}

export class UpdateSubmitterHome {
  static readonly type = 'UpdateSubmitterHome';
  constructor(public fileID: number, public submitter: EntrySubmitter) {}
}

export class UpdateSubmitterHomeSuccess {
  static readonly type = 'UpdateSubmitterHomeSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Submitter Updated Successfully'
  ) {}
}

export class UpdateSubmitterHomeError {
  static readonly type = 'UpdateSubmitterHomeError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdateApprovedStatusHome {
  static readonly type = 'UpdateApprovedStatusHome';
  constructor(public approved: boolean, public fileIDs: Array<number>) {}
}

export class UpdateApprovedStatusHomeSuccess {
  static readonly type = 'UpdateApprovedStatusHomeSuccess';
  constructor(public payload: any, public message: string, public status) {}
}

export class UpdateApprovedStatusHomeError {
  static readonly type = 'UpdateApprovedStatusHomeError';
  constructor(public message: string, public status) {}
}

export class UploadFile {
  static readonly type = 'UploadFile';
  constructor(public customerID: number, public file: File) {}
}

export class UploadFileSuccess {
  static readonly type = 'UploadFileSuccess';
  constructor(
    public payload: any,
    public status: string,
    public message: string
  ) {}
}

export class UploadFileError {
  static readonly type = 'UploadFileError';
  constructor(public message: string, public status: string) {}
}

export class RetryTranscodeHome {
  static readonly type = 'RetryTranscodeHome';
  constructor(public fileId: number) {}
}

export class RetryTranscodeHomeSuccess {
  static readonly type = 'RetryTranscodeHomeSuccess';
  constructor(
    public fileID: number,
    public message: string,
    public status: string
  ) {}
}

export class RetryTranscodeHomeError {
  static readonly type = 'RetryTranscodeHomeError';
  constructor(public message: string, public status: string) {}
}

export class UpdateStarredStatusAdmin {
  static readonly type = 'UpdateStarredStatusAdmin';
  constructor(public starred: boolean, public fileIDs: Array<number>) {}
}

export class UpdateStarredStatusAdminSuccess {
  static readonly type = 'UpdateStarredStatusAdminSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Starred Status Updated Successfully'
  ) {}
}

export class UpdateStarredStatusAdminError {
  static readonly type = 'UpdateStarredStatusAdminError';
  constructor(public message: any, public status = 'error') {}
}

// export class DownloadFile {
//   static readonly type = 'DownloadFile';
//   constructor(
//     public fileID: number,
//     public fileVersion: 'transcoded' | 'original'
//   ) {}
// }

// export class DownloadFileSuccess {
//   static readonly type = 'DownloadFileSuccess';
//   constructor(
//     public data: Blob,
//     public fileName: string,
//     public status = 'success',
//     public message = 'File Donwloaded Successfully'
//   ) {}
// }

// export class DownloadFileError {
//   static readonly type = 'DownloadFileError';
//   constructor(public message: string, public status = 'error') {}
// }
