<div class="orca-player" fxLayout="row" *ngIf="config" [ngStyle]="style" (mousemove)="displayCtrls($event)">
  <ng-container *ngIf="files && files.length > 0">
    <div class="content" *ngIf="!animationTrigger" [@rightToLeft]="animationState"
      (@rightToLeft.done)="animationDone($event)">
      <portal-embed-player [url]="source" [duration]="duration" [mute]="mute" [index]="1"
        (embedChange)="feedPlayerChange($event)"></portal-embed-player>
    </div>
    <div class="content" *ngIf="animationTrigger" [@rightToLeft]="animationState"
      (@rightToLeft.done)="animationDone($event)">
      <portal-embed-player [url]="source" [duration]="duration" [mute]="mute" [index]="2"
        (embedChange)="feedPlayerChange($event)"></portal-embed-player>
    </div>
  </ng-container>
  <div *ngIf="notContent" class="content not-content">
    <img src="assets/placeholder.png" />
  </div>
  <div class="controls" *ngIf="controls && !notContent" @fadeCtrls>
    <button matTooltip="Previous" mat-icon-button class="main-control" (mouseenter)="overCtrl = true; clearCtrlsTimer()"
      (mouseleave)="overCtrl = false; setCtrlsTimer()" (click)="toLeft(); pause = false">
      <chia-icon icon="skip_previous"></chia-icon>
    </button>
    <button [matTooltip]="pause ? 'Play' : 'Pause'" mat-icon-button class="main-control"
      (mouseenter)="overCtrl = true; clearCtrlsTimer()" (mouseleave)="overCtrl = false; setCtrlsTimer()"
      (click)="pausePlayPlayer()">
      <chia-icon [icon]="pause ? 'play_arrow' : 'pause'"></chia-icon>
    </button>
    <button matTooltip="Next" mat-icon-button class="main-control" (mouseenter)="overCtrl = true; clearCtrlsTimer()"
      (mouseleave)="overCtrl = false; setCtrlsTimer()" (click)="toRight(); pause = false">
      <chia-icon icon="skip_next"></chia-icon>
    </button>
    <button matTooltip="File URL" mat-icon-button [matMenuTriggerFor]="menu"
      (mouseenter)="overCtrl = true; clearCtrlsTimer()" (mouseleave)="overCtrl = false; setCtrlsTimer()"
      (menuClosed)="overCtrl = false; setCtrlsTimer()">
      <chia-icon icon="link"></chia-icon>
    </button>
  </div>
</div>

<mat-menu #menu="matMenu">
  <div [style.padding]="'5px 10px'" (mouseenter)="overCtrl = true; clearCtrlsTimer()"
    (mouseleave)="overCtrl = false; setCtrlsTimer()">
    <mat-form-field (click)="$event.stopPropagation()">
      <mat-label [style.fontSize.px]="12">URL</mat-label>
      <input #inputURL readonly [style.fontSize.px]="12" [value]="source" matInput (click)="$event.stopPropagation()" />
    </mat-form-field>
    <button mat-icon-button matTooltip="Copy to clipboard"
      (click)="$event.stopPropagation(); copyToClipboard(inputURL)">
      <chia-icon icon="file_copy"></chia-icon>
    </button>
  </div>
</mat-menu>
