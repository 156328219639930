import { Location } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Entry } from '@shared/types';

@Component({
  selector: 'portal-slideshow-wrapper',
  templateUrl: './slideshow-wrapper.component.html',
  styleUrls: ['./slideshow-wrapper.component.scss']
})
export class SlideshowWarpperComponent {
  get entries(): Entry[] {
    return this.data.entries;
  }

  get selected(): number {
    return this.data.selected;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entries: Entry[], selected: number }) { }
}