import { Store, State, Action, StateContext, Selector } from '@ngxs/store';

import { EntriesAdminStateModel, EntriesAdminStateModel2 } from '@shared/types';
import {
  UpdateEntrieNetworksHome,
  UpdateEntrieNetworksHomeSuccess,
  UpdateEntrieNetworksHomeError,
  UpdateEntriePanelHome,
  UpdateEntriePanelHomeSuccess,
  UpdateEntriePanelHomeError,
  UpdateEntrieStartEndDateHome,
  UpdateEntrieStartEndDateHomeSuccess,
  UpdateEntrieStartEndDateHomeError,
  UpdateEntrieDurationHome,
  UpdateEntrieDurationHomeSuccess,
  UpdateEntrieDurationHomeError,
  UpdateApprovedStatusHome,
  UpdateApprovedStatusHomeError,
  UpdateApprovedStatusHomeSuccess,
  UpdateHomeFileError,
  UpdateHomeFile,
  UpdateHomeFileSuccess,
  UploadFile,
  UploadFileSuccess,
  UploadFileError,
  RetryTranscodeHome,
  RetryTranscodeHomeSuccess,
  RetryTranscodeHomeError,
  UpdateSubmitterHome,
  UpdateSubmitterHomeSuccess,
  UpdateSubmitterHomeError,
  GetEntriesAdminError,
  GetEntriesAdminSuccess,
  GetEntriesAdmin,
  GetEntriesAdminContent,
  GetEntriesAdminContentSuccess,
  GetEntriesAdminContentError,
  GetEntryAdminColumns,
  GetEntryAdminColumnsSuccess,
  GetEntryAdminColumnsError,
  UpdateEntrieAdminPanel,
  UpdateEntrieAdminPanelSuccess,
  UpdateEntrieAdminPanelError,
  UpdateEntrieDurationHome2Error,
  UpdateEntrieDurationHome2Success,
  UpdateEntrieDurationHome2,
  UpdateEntrieStartEndDateHome2Error,
  UpdateEntrieStartEndDateHome2Success,
  UpdateEntrieStartEndDateHome2,
  UpdateStarredStatusAdmin,
  UpdateStarredStatusAdminSuccess,
  UpdateStarredStatusAdminError
} from './files.actions';
import { FileService } from '@shared/services/features/file.service';
import { FileService as FileService8005 } from '@shared/services/api/file-service/file.service';

import { SubmissionFormService } from '@shared/services/api/submission-form/submission-form.service';
import { SubmissionFormState } from '../submission-form/submission-form.state';
import { Injectable } from '@angular/core';
import { AdminService } from '@shared/services/features/admin.service';

@State<EntriesAdminStateModel>({
  name: 'files',
  defaults: {
    entries: [],
    message: null,
    loading: false,
    status: null,
    count: null,
    panel_visible:false,
    network_visible: false,
    adtype_visible: false,
    affiliation_visible: false,
    tags_visible: false
  }
})

@State<EntriesAdminStateModel2>({
  name: 'entries',
  defaults: {
    entries: [],
    message: null,
    loading: false,
    status: null,
    count: null,
    columns:null
  }
})


@Injectable()
///////State for Curate Public Content Admin ////////////////
export class FilesState {
  constructor(
    private fileService: FileService,
    private fileService8005: FileService8005,
    private adminService: AdminService,
    private store: Store,
    private submissionForm: SubmissionFormService
  ) {}

  @Action(GetEntriesAdmin)
  getEntriesAdmin({ patchState }: StateContext<EntriesAdminStateModel>, action: GetEntriesAdmin) {
    const { customerID, userId, bodyParams} = action;
    patchState({
      entries: [],
      loading: true,
      message: null,
      status: null
    });
    this.adminService.getEntriesAdmin(customerID, userId, bodyParams);
  }

  @Action(GetEntriesAdminSuccess)
  getEntriesAdminSuccess(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: GetEntriesAdminSuccess
  ) {
    const { payload, message, status } = action;
    const { entries, count, panel_visible, network_visible, adtype_visible, affiliation_visible, tags_visible } = payload;

    patchState({
      loading: false,
      status,
      message,
      entries,
      count,
      panel_visible,
      network_visible,
      adtype_visible,
      affiliation_visible,
      tags_visible
    });
  }

  @Action(GetEntriesAdminError)
  getEntriesAdminError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: GetEntriesAdminError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntriePanelHome)
  updateEntriePanelHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntriePanelHome
  ) {
    const { fileID, panelID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntriePanels2(fileID, panelID);
  }

  @Action(UpdateEntriePanelHomeSuccess)
  updateEntriePanelHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntriePanelHomeSuccess
  ) {
    const panelsOpts = this.store.selectSnapshot(SubmissionFormState.getPanels);
    const { entries: entriesState } = getState();
    const { payload, status } = action;
    const { fileID, panelID } = payload;
    const panel = panelsOpts.find(panelOpt => panelOpt.id === panelID);

    const entries = entriesState.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          panel: {
            ...entry.panel,
            id: panel.id,
            name: panel.name
          }
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Panel Updated Successfully',
      status
    });
  }

  @Action(UpdateEntriePanelHomeError)
  updateEntriePanelHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntriePanelHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateHomeFile)
  updateHomeFile(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateHomeFile
  ) {
    const { fileID, fileReq, files } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.upDateAdminFile(fileID, fileReq, files);
  }

  @Action(UpdateHomeFileSuccess)
  updateHomeFileSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateHomeFileSuccess
  ) {
    const { entries: stateFiles } = getState();
    const { payload, status } = action;
    const { id } = payload;

    const entries = stateFiles.map(entry => {
      if (entry.id === id) {
        entry = {
          ...payload
        };
      }

      return entry;
    });

    patchState({
      entries,
      loading: false,
      status
    });
  }

  @Action(UpdateHomeFileError)
  updateHomeFileError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateHomeFileError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateSubmitterHome)
  updateSubmitter(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHome
  ) {
    const { fileID, submitter } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionForm.updateSubmitterHome(fileID, submitter);
  }

  @Action(UpdateSubmitterHomeSuccess)
  updateSubmitterSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHomeSuccess
  ) {
    const { entries: stateFiles } = getState();
    const { payload, status, message } = action;
    const { files_id, submitters_email, submitters_subm_name } = payload;
    const entries = stateFiles.map(file => {
      if (file.id === files_id) {
        file = {
          ...file,
          submitter: {
            ...file.submitter,
            email: submitters_email,
            subm_name: submitters_subm_name
          }
        };
      }
      return file;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateSubmitterHomeError)
  updateSubmitterError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateApprovedStatusHome)
  updateApprovedStatusHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateApprovedStatusHome
  ) {
    const { approved, fileIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.submissionForm.updateApprovedStatusHome(approved, fileIDs);
  }

  @Action(UpdateApprovedStatusHomeSuccess)
  updateApprovedStatusHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateApprovedStatusHomeSuccess
  ) {
    const { status } = action;

    patchState({
      loading: false,
      status,
      message: 'Approved Status Updated Successfully'
    });
  }

  @Action(UpdateApprovedStatusHomeError)
  updateApprovedStatusHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateApprovedStatusHomeError
  ) {
    const { status, message } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(UploadFile)
  uploadFile(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFile
  ) {
    const { customerID, file } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.updloadFile(customerID, file);
  }

  @Action(UploadFileSuccess)
  uploadFileSuccess(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFileSuccess
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UploadFileError)
  uploadFileError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFileError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(RetryTranscodeHome)
  retryTranscodeHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHome
  ) {
    const { fileId } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.retryTranscodeHome(fileId);
  }

  @Action(RetryTranscodeHomeSuccess)
  retryTranscodeHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHomeSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { fileID, status } = action;

    const entries = stateEntries.map(entry => {
      if (fileID === entry.id) {
        entry = {
          ...entry,
          transcodeStatus: 2
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Retry Sucessful',
      status
    });
  }

  @Action(RetryTranscodeHomeError)
  retryTranscodeHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHomeError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieDurationHome)
  updateEntrieDurationHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieDurationHome
  ) {
    const { fileID, duration } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntriesDurationAdmin(fileID, duration);
  }

  @Action(UpdateEntrieDurationHomeSuccess)
  updateEntrieDurationHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieDurationHomeSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status } = action;
    const { fileID, duration } = payload;
    const entries = stateEntries.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          duration
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Duration Updated Successfully',
      status
    });
  }

  @Action(UpdateEntrieDurationHomeError)
  updateEntrieDurationHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieDurationHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieStartEndDateHome)
  updateEntrieStartEndDateHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieStartEndDateHome
  ) {
    const { fileID, dates } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntrieStartEndDateAdmin(fileID, dates);
  }

  @Action(UpdateEntrieStartEndDateHomeSuccess)
  updateEntrieStartEndDateHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieStartEndDateHomeSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status } = action;
    const { fileID, dates } = payload;
    const { startDate, endDate } = dates;

    const entries = stateEntries.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          start_Date: startDate,
          end_Date: endDate
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Start / End Date Updated Successfully',
      status
    });
  }

  @Action(UpdateEntrieStartEndDateHomeError)
  updateEntrieStartEndDateHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieStartEndDateHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieNetworksHome)
  updateEntrieNetworksHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieNetworksHome
  ) {
    const { fileID, networksIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntrieNetworksAdmin(fileID, networksIDs);
  }

  @Action(UpdateEntrieNetworksHomeSuccess)
  updateEntrieNetworksHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieNetworksHomeSuccess
  ) {
    const networksOpts = this.store.selectSnapshot(
      SubmissionFormState.getNetworks
    );
    const state = getState();
    const { entries: stateEntries } = state;
    const { payload, status } = action;
    const { fileID, networkIDs } = payload;
    const filteredNetworks = networksOpts.filter(networkOpt =>
      networkIDs.some(networkID => networkID === networkOpt.network_id)
    );
    const networks: any = filteredNetworks.map(network => {
      const { network_id, network_name } = network; //################################
      return {
        id: network_id,
        name: network_name
      };
    });

    const entries = stateEntries.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          networks
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Networks Updated Successfully',
      status
    });
  }

  @Action(UpdateEntrieNetworksHomeError)
  updateEntrieNetworksHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieNetworksHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }
}

///////State for Entries Content Admin ////////////////
@Injectable()
export class FilesEntriesState {
  constructor(
    private fileService: FileService,
    private fileService8005:FileService8005,
    private adminService:AdminService,
    private store: Store,
    private submissionForm: SubmissionFormService
  ) {}

  @Action(UpdateEntrieDurationHome2)
  updateEntrieDurationHome2(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieDurationHome2
  ) {
    const { fileID, duration } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntriesDurationAdmin2(fileID, duration);
  }

  @Action(UpdateEntrieDurationHome2Success)
  updateEntrieDurationHome2Success(
    { patchState, getState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieDurationHome2Success
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status } = action;
    const { fileID, duration } = payload;
    const entries = stateEntries.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          duration
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Duration Updated Successfully',
      status
    });
  }

  @Action(UpdateEntrieDurationHome2Error)
  updateEntrieDurationHome2Error(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieDurationHome2Error
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieStartEndDateHome2)
  updateEntrieStartEndDateHome2(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieStartEndDateHome2
  ) {
    const { fileID, dates } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntrieStartEndDateAdmin(fileID, dates);
  }

  @Action(UpdateEntrieStartEndDateHome2Success)
  updateEntrieStartEndDateHome2Success(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateEntrieStartEndDateHome2Success
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status } = action;
    const { fileID, dates } = payload;
    const { startDate, endDate } = dates;

    const entries = stateEntries.map(entry => {
      if (entry.id === fileID) {
        entry = {
          ...entry,
          start_Date: startDate,
          end_Date: endDate
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Start / End Date Updated Successfully',
      status
    });
  }

  @Action(UpdateEntrieStartEndDateHome2Error)
  updateEntrieStartEndDateHome2Error(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieStartEndDateHome2Error
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetEntriesAdminContent)
  getEntriesAdminContent({ patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntriesAdminContent) {
    const { customerID, userId, bodyParams } = action;
    patchState({
      entries: [],
      loading: true,
      message: null,
      status: null
    });
    this.adminService.getEntriesAdmin2(customerID, userId, bodyParams, true);
  }

  @Action(GetEntriesAdminContentSuccess)
  getEntriesAdminContentSuccess(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntriesAdminContentSuccess
  ) {
    const { payload, message, status } = action;
    const { entries, count, panel_visible, network_visible, adtype_visible, affiliation_visible, tags_visible } = payload;

    patchState({
      loading: false,
      status,
      message,
      entries,
      count
    });
  }

  @Action(GetEntriesAdminContentError)
  getEntriesAdminContentError(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntriesAdminContentError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetEntryAdminColumns)
  getEntryColumns(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntryAdminColumns
  ) {
    const { formID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionForm.getEntryAdminColumns(formID);
  }

  @Action(GetEntryAdminColumnsSuccess)
  getEntryColumnsSuccess(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntryAdminColumnsSuccess
  ) {
    const { payload, message, status } = action;

    patchState({
      loading: false,
      status,
      message,
      columns: payload.sort((a, b) => a.position - b.position)
    });
  }

  @Action(GetEntryAdminColumnsError)
  getEntryColumnsError(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: GetEntryAdminColumnsError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(UpdateEntrieAdminPanel)
  updateEntrieAdminPanel(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieAdminPanel
  ) {
    const { fileID, panelID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService8005.updateEntrieAdminPanels(fileID, panelID);
  }

  @Action(UpdateEntrieAdminPanelSuccess)
  updateEntrieAdminPanelSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieAdminPanelSuccess
  ) {
    const panelsOpts = this.store.selectSnapshot(SubmissionFormState.getPanels);
    const { entries: entriesState } = getState();
    const { payload, status, message } = action;
    const { fileID, panelID } = payload;
    const panel = panelsOpts.find(panelOpt => panelOpt.id === panelID);

    const entries = entriesState.map(entry => {
      let _entry=Object.assign({}, entry);
      if (_entry.id === fileID) {
        _entry.panel = {
          ..._entry.panel,
          id: panel.id,
          name: panel.name
        };
        entry=_entry;
      }

      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieAdminPanelError)
  updateEntrieAdminPanelError(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateEntrieAdminPanelError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  // @Action(UpdateHomeFile)
  // updateHomeFile(
  //   { patchState }: StateContext<EntriesAdminStateModel2>,
  //   action: UpdateHomeFile
  // ) {
  //   const { fileID, fileReq, files } = action;

  //   patchState({
  //     loading: true,
  //     message: null,
  //     status: null
  //   });

  //   this.fileService.upDateHomeFile(fileID, fileReq, files);
  // }

  // @Action(UpdateHomeFileSuccess)
  // updateHomeFileSuccess(
  //   { patchState, getState }: StateContext<EntriesAdminStateModel>,
  //   action: UpdateHomeFileSuccess
  // ) {
  //   const { entries: stateFiles } = getState();
  //   const { payload, status } = action;
  //   const { id } = payload;

  //   const entries = stateFiles.map(entry => {
  //     if (entry.id === id) {
  //       entry = {
  //         ...payload
  //       };
  //     }

  //     return entry;
  //   });

  //   patchState({
  //     entries,
  //     loading: false,
  //     status
  //   });
  // }

  // @Action(UpdateHomeFileError)
  // updateHomeFileError(
  //   { patchState }: StateContext<EntriesAdminStateModel>,
  //   action: UpdateHomeFileError
  // ) {
  //   const { message, status } = action;

  //   patchState({
  //     loading: false,
  //     message,
  //     status
  //   });
  // }

  @Action(UpdateSubmitterHome)
  updateSubmitter(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHome
  ) {
    const { fileID, submitter } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionForm.updateSubmitterHome(fileID, submitter);
  }

  @Action(UpdateSubmitterHomeSuccess)
  updateSubmitterSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHomeSuccess
  ) {
    const { entries: stateFiles } = getState();
    const { payload, status, message } = action;
    const { files_id, submitters_email, submitters_subm_name } = payload;
    const entries = stateFiles.map(file => {
      if (file.id === files_id) {
        file = {
          ...file,
          submitter: {
            ...file.submitter,
            email: submitters_email,
            subm_name: submitters_subm_name
          }
        };
      }
      return file;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateSubmitterHomeError)
  updateSubmitterError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UpdateSubmitterHomeError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateApprovedStatusHome)
  updateApprovedStatusHome(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateApprovedStatusHome
  ) {
    const { approved, fileIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.submissionForm.updateApprovedStatusHome(approved, fileIDs);
  }

  @Action(UpdateApprovedStatusHomeSuccess)
  updateApprovedStatusHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateApprovedStatusHomeSuccess
  ) {
    const { status } = action;

    patchState({
      loading: false,
      status,
      message: 'Approved Status Updated Successfully'
    });
  }

  @Action(UpdateApprovedStatusHomeError)
  updateApprovedStatusHomeError(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateApprovedStatusHomeError
  ) {
    const { status, message } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(UploadFile)
  uploadFile(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFile
  ) {
    const { customerID, file } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.updloadFile(customerID, file);
  }

  @Action(UploadFileSuccess)
  uploadFileSuccess(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFileSuccess
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UploadFileError)
  uploadFileError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: UploadFileError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(RetryTranscodeHome)
  retryTranscodeHome(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHome
  ) {
    const { fileId } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.retryTranscodeHome(fileId);
  }

  @Action(RetryTranscodeHomeSuccess)
  retryTranscodeHomeSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHomeSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { fileID, status } = action;

    const entries = stateEntries.map(entry => {
      if (fileID === entry.id) {
        entry = {
          ...entry,
          transcodeStatus: 2
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message: 'Retry Sucessful',
      status
    });
  }

  @Action(RetryTranscodeHomeError)
  retryTranscodeHomeError(
    { patchState }: StateContext<EntriesAdminStateModel>,
    action: RetryTranscodeHomeError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateStarredStatusAdmin)
  updateStarredStatusAdmin(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateStarredStatusAdmin
  ) {
    const { starred, fileIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.fileService8005.updateStarredStatusAdmin(starred, fileIDs);
  }

  @Action(UpdateStarredStatusAdminSuccess)
  updateStarredStatusAdminSuccess(
    { patchState, getState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateStarredStatusAdminSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { starredStatus, fileIDs } = payload;

    const entries = stateEntries.map(entry => {
      if (fileIDs.some(ids => ids === entry.id)) {
        entry = {
          ...entry,
          starred_Status: starredStatus ? 1 : 0
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateStarredStatusAdminError)
  updateStarredStatusAdminError(
    { patchState }: StateContext<EntriesAdminStateModel2>,
    action: UpdateStarredStatusAdminError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }
}
