import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Credentials } from '@shared/types';
import { FeaturesService } from '@shared/services/features/features.service';

@Component({
  selector: 'portal-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent {
  isAdmin:boolean;
  portalName: string;
  notCredentials: boolean;
  credentials: Credentials = {
    token: null,
    userId: null,
    role: null
  };
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private featuresService: FeaturesService
  ) {
    this.portalName = this.featuresService.getCredential('portalName');
    this.isAdmin=JSON.parse(this.featuresService.getCredential('isAdmin'));
    this.route.queryParams.subscribe(query => {
      for (const key in this.credentials) {
        if (query[key]) {
          this.credentials[key] = query[key];
        } else {
          this.notCredentials = true;
        }
      }
      return this.notCredentials
        ? this.featuresService.logIn()
        : this.setCredentials();
    });
  }

  setCredentials(): void {
    this.featuresService.setCredentials(this.credentials);
    if (this.portalName && !this.isAdmin) {
      this.router.navigate([`/local-portal/${this.portalName}/home`]);
    } else if(this.isAdmin) {
      this.router.navigate(['/admin/home']);
     } else {
      this.router.navigate(['/submit']);
    }

  }
}
