import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRoutingModule } from '@modules/admin/admin.routing';
import { ForbiddenComponent } from '@modules/forbidden/forbidden.component';
import { AuthenticatedComponent } from '@modules/local-portal/authenticated/authenticated.component';
import { SlideShowADComponent } from '@modules/slide-show-ad/slide-show-ad.component';
import { FeedPlayerAdminComponent } from '@shared/modules/feed-player-admin/feed-player-admin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'submit',
    loadChildren: () =>
      import('@modules/submission-form/submission-form.module').then(
        mod => mod.SubmissionFormModule
      )
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'integrate',
    loadChildren: () =>
      import('./modules/home/home.module').then(mod => mod.HomeModule)
  },
  {
    path: 'guidelines',
    loadChildren: () =>
      import('./modules/guidelines/guidelines.module').then(
        mod => mod.GuidelinesModule
      )
  },
  {
    path: 'create-an-ad',
    loadChildren: () =>
      import('./modules/create-and-ad/create-and-ad.module').then(
        mod => mod.CreateAndAdModule
      )
  },
  {
    path: 'local-portal',
    loadChildren: () =>
      import('./modules/local-portal/local-portal.module').then(
        mod => mod.LocalPortalModule
      )
  },
  {
    path:'admin',
    loadChildren:()=>
    import('./modules/admin/admin.module').then(
      mod => mod.AdminModule
    )
  },
  {
    path: 'playlist/:school/:form/:playlist',
    component: FeedPlayerAdminComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'authorized',
    component: AuthenticatedComponent
  },
  {
    path: 'slideshow',
    loadChildren: () =>
    import('./modules/slide-show-ad/slide-show-ad.module').then(
      mod => mod.SlideShowAdModule
    )
  },
  {
    path: '**',
    loadChildren: () =>
      import('@modules/page-not-found/page-not-found.module').then(
        mod => mod.PageNotFoundModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
