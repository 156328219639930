<div
  #slider
  tabindex="0"
  aria-label="Use left and right arrow buttons to move through the content"
  class="slider-wrapper focusable"
  fxLayout="row"
  fxLayoutAlign="start center"
  (mouseenter)="mouseover = true; sliderIn()"
  (mouseleave)="mouseover = false; sliderOut()"
  (focus)="sliderIn()"
  [style.height.px]="revolutionSlider[index].height - 1"
  [style.backgroundColor]="revolutionSlider[index].background_color"
>
  <div *ngIf="revolutionSlider.length>1" 
  class="controls">
    <button
      *ngIf="controls"
      @fadeCtrls
      matTooltip="Previous"
      tabindex="0"
      mat-icon-button
      class="main-control"
      (click)="!animating && toLeft()"
    >
      <mat-icon>skip_previous</mat-icon>
    </button>
    <button
      *ngIf="controls"
      @fadeCtrls
      aria-label="Next"
      matTooltip="Next"
      tabindex="0"
      mat-icon-button
      class="main-control"
      (click)="!animating && toRight()"
    >
      <mat-icon>skip_next</mat-icon>
    </button>
    <mat-radio-group
      *ngIf="controls"
      @fadeCtrls
      [value]="index"
      fxLayoutAlign="center start"
      disabled
    >
      <mat-radio-button
        *ngFor="let slice of revolutionSlider; let i = index"
        [value]="i"
        (change)="sliceByIndex($event)"
      ></mat-radio-button>
    </mat-radio-group>
    <div class="progress-bar">
      <div class="progress" [style.width.%]="(timer * 100) / timerEnd"></div>
    </div>
  </div>
  <portal-slice
    *ngIf="!animationTrigger"
    [slice]="revolutionSlider[index]"
    [@rightToLeft]="animationState"
    (@rightToLeft.done)="animationDone($event)"
    (focused)="sliderIn()"
  ></portal-slice>
  <portal-slice
    *ngIf="animationTrigger"
    [slice]="revolutionSlider[index]"
    [@rightToLeft]="animationState"
    (@rightToLeft.done)="animationDone($event)"
    (focused)="sliderIn()"
  ></portal-slice>
</div>
