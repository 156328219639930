import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostBinding,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

import { PlayerStatus } from '../types';
import { FeaturesService } from '@shared/services/features/features.service';

@Component({
  selector: 'portal-page-player',
  templateUrl: './page-player.component.html',
  styleUrls: ['./page-player.component.scss']
})
export class PagePlayerComponent implements OnInit {
  @ViewChild('page', { static: true }) page: ElementRef<HTMLIFrameElement>;
  @HostBinding('style.width') width = 'inherit';
  @HostBinding('style.height') height = 'inherit';
  @Input() alt: string;
  @Input() src: string;
  @Input() mode: 'player' | 'thumbnail' = 'player';
  @Output() pageChange: EventEmitter<PlayerStatus> = new EventEmitter(null);
  _src: SafeResourceUrl;
  thumbnail: string;
  title: string;
  loading = true;
  constructor(
    private sanitizer: DomSanitizer,
    private featuresService: FeaturesService
  ) { }

  ngOnInit() {
    // Create Source Snapshot
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    if (this.mode === 'thumbnail') {
      this.getThumbnail();
    }
  }

  getThumbnail(): void {
    this.featuresService.getPageMetadata(encodeURI(this.src)).subscribe(
      res => {
        this.thumbnail = res.image;
        this.title = res.title || this.alt;
        this.pageChange.emit(PlayerStatus.PLAYING);
        this.loading = false;
      },
      err => {
        console.log('err: ', err);

        this.pageChange.emit(PlayerStatus.PLAYING);
        this.loading = false;
        this.title = this.alt;
      }
    );
  }

  preview(): void {
    this.pageChange.emit(PlayerStatus.PREVIEW);
  }

  load(): void {
    this.pageChange.emit(PlayerStatus.PLAYING);
  }

  error(): void {
    this.pageChange.emit(PlayerStatus.ERROR);
  }
}
