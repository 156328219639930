import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';

import { FeaturesService } from '@shared/services/features/features.service';
import { PublicPortalState } from '@shared/states/public-portal/public-portal.state';
import { PublicPortalStateModel, Logo } from '@shared/types';
import { FadeOut, FadeIn } from '@shared/animations';

@Component({
  selector: 'portal-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  animations: [
    trigger('fadeOnLoad', [
      transition('hidden => visible', [
        useAnimation(FadeIn, { params: { time: '0.15s' } })
      ]),
      transition(':leave', [
        useAnimation(FadeOut, { params: { time: '0.15s' } })
      ])
    ])
  ]
})
export class ForbiddenComponent implements OnInit, OnDestroy {
  publicPortal$ = this.store.select<PublicPortalStateModel>(PublicPortalState);

  
  apogee: string;
  unsubscribe$ = new Subject();
  logo: Logo;
  logoLoaded: boolean;
  constructor(private featuresService: FeaturesService, private store:Store) {
    this.featuresService.hideTemplate();
    this.subscriptions();
  }

  ngOnInit() {
    this.apogee = this.featuresService.getCompany();
  }

  subscriptions(): void {
    this.publicPortal$.pipe(takeUntil(this.unsubscribe$)).subscribe(portal => {
      const { logo } = portal;
      this.logo = logo;
    });
  }

  mainScreen(): void {
    this.featuresService.logOut();
  }

  ngOnDestroy() {
    this.featuresService.showTemplate();
  }
}
