<mat-menu #menuRef="matMenu" [overlapTrigger]="false">
  <ng-container *ngFor="let menu of menus" [ngSwitch]="menu.type">
    <a
      *ngSwitchCase="menuTypes.LINK"
      [href]="menu.value"
      [attr.aria-label]="'Open new ' + menu.name + ' tab'"
      target="_blank"
      mat-menu-item
    >
      {{ menu.name }}
    </a>
    <ng-container *ngSwitchCase="menuTypes.DROPDOWN">
      <button mat-menu-item [matMenuTriggerFor]="dynamicMenu.menuRef">
        {{ menu.name }}
      </button>
      <portal-menus [menus]="menu.menus" #dynamicMenu></portal-menus>
    </ng-container>
  </ng-container>
  <button disabled *ngIf="menus.length === 0" mat-menu-item>
    <b>No options available.</b>
  </button>
</mat-menu>
