import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ChangeDetectorRef,
  ElementRef,
  Inject
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import {
  MenuItem,
  PublicPortalStateModel,
  MenuItemType,
  Logo,
  ThemeColors,
  HomeStateModel,
  Orientation
} from '@shared/types';
import { PublicPortalState } from '@shared/states/public-portal/public-portal.state';
import { FadeIn, FadeOut } from '@shared/animations';
import { FeaturesService } from '@shared/services/features/features.service';
import {
  UniversityState,
  UniversityStateModel
} from '@shared/states/university/university.state';
import { HomeState } from '@shared/states/home/home.state';

@Component({
  selector: 'portal-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [
    trigger('fadeOnLoad', [
      transition('hidden => visible', [
        useAnimation(FadeIn, { params: { time: '0.15s' } })
      ]),
      transition(':leave', [
        useAnimation(FadeOut, { params: { time: '0.15s' } })
      ])
    ])
  ]
})
export class ToolbarComponent implements OnInit {
  university$ = this.store.select<UniversityStateModel>(UniversityState);
  publicPortal$ = this.store.select<PublicPortalStateModel>(PublicPortalState);
  home$ = this.store.select<HomeStateModel>(HomeState);
  @Input() mainContentRef: HTMLDivElement;
  unsubscribe$ = new Subject();
  universityName: string;
  logo: Logo;
  logoLoaded: boolean;
  hideTemplate: boolean;
  fixed: boolean;
  menus: Array<MenuItem>;
  publicMenus: Array<MenuItem>;
  theme: ThemeColors;
  menuType = MenuItemType;
  activeLink: string;
  isIntegrate: boolean;
  orientation: Orientation;
  mobileLandscape = Orientation.LANDSCAPE_MOBILE;
  constructor(
    @Inject(DOCUMENT) private document,
    private featuresService: FeaturesService,
    private renderer: Renderer2,
    private router: Router,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private titleService: Title
  ) {
    this.isIntegrate = this.document.location.pathname === '/integrate';
  }

  ngOnInit() {
    this.subscriptons();
  }

  subscriptons(): void {
    this.featuresService.templateHidden
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(hidden => {
        this.hideTemplate = hidden;
      });
    this.featuresService.page.subscribe(page => {
      this.activeLink = page;
      if (this.universityName && this.activeLink) {
        this.setPageTitle(this.activeLink);
      }
      this.cdRef.detectChanges();
    });
    this.featuresService.fixedMenu.subscribe(menus => {
      if (menus) {
        this.fixed = true;
        this.menus = menus;
      } else {
        this.menus = this.publicMenus;
        this.fixed = false;
      }
    });
    this.university$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(university$ => {
        const { loading, university } = university$;
        if (loading === false && university) {
          const { name } = university;
          this.universityName = name;
          if (this.activeLink) {
            this.setPageTitle(this.activeLink);
          }
        }
      });
    this.publicPortal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(publicportal => {
        const { menuItems, logo, theme } = publicportal;
        this.publicMenus = menuItems;
        if (!this.menus) {
          this.menus = menuItems;
        }
        this.logo = logo;
        if (theme) {
          this.theme = theme;
          this.setThemePalette();
        }
      });
    this.home$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ orientation }) => {
      this.orientation = orientation;
    });
  }

  setPageTitle(page: string): void {
    const formatPage = page
      .replace(/[\/\-]/g, ' ')
      .toUpperCase()
      .trim();
    const title = `${this.universityName} - ${formatPage}`;

    this.titleService.setTitle(title);
  }

  setThemePalette(): void {
    for (const key in this.theme) {
      if (this.theme[key].hasOwnProperty) {
        document.documentElement.style.setProperty(`--${key}`, this.theme[key]);
        if (key === 'footerBgColor') {
          const color = this.getContrastColor(this.theme[key]);
          document.documentElement.style.setProperty(
            '--footerTextColor',
            color
          );
          this.featuresService.footerTextColor.next(color);
        }
        if (key === 'headerBgColor') {
          const color = this.getContrastColor(this.theme[key]);
          document.documentElement.style.setProperty(
            '--headerTextColor',
            color
          );
        }
      }
    }
  }

  hexToRGB(hex: string): { red: number; green: number; blue: number } {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      }
      : {
        red: 0,
        green: 0,
        blue: 0
      };
  }

  getContrastColor(hex: string): string {
    const baseColor = this.hexToRGB(hex);
    const whiteRGB = 255;
    const { red, green, blue } = baseColor;
    const brightness = Math.round(
      red * 299 + green * 587 + (blue * 114) / 1000
    );

    const lightColor = Math.round(
      whiteRGB * 299 + whiteRGB * 587 + (whiteRGB * 114) / 1000
    );
    return Math.abs(brightness) < lightColor / 2 ? '#FFFFFF' : '#000000';
  }

  goToMainContent(event: Event): void {
    event.preventDefault();
    this.renderer.setAttribute(this.mainContentRef, 'tabindex', '-1');
    this.mainContentRef.focus();
    this.renderer.removeAttribute(this.mainContentRef, 'tabindex');
  }

  navigationType(link: string): void {
    const pageRegExp = new RegExp(
      /^(https|http):\/\/.*\.([a-z]{2,3})(\/.*)*/,
      'i'
    );
    if (pageRegExp.test(link)) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
      this.featuresService.navigateToTop();
      this.featuresService.navigatedToTop.next(true);
    }
  }

  logout(): void {
    this.featuresService.logOut();
  }
}
