import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FeaturesService } from '@shared/services/features/features.service';
import { PlayerStatus } from '../types';

@Component({
  selector: 'portal-image-player',
  templateUrl: './image-player.component.html',
  styleUrls: ['./image-player.component.scss']
})
export class ImagePlayerComponent implements OnInit {
  @ViewChild('image', {static: true}) image : ElementRef<HTMLImageElement>;
  @Input() src: string;
  @Input() alt: string;
  @Input() mode: 'player' | 'thumbnail' = 'player';
  @Input() slideshow = false
  @Output() imageChange: EventEmitter<PlayerStatus> = new EventEmitter(null);
  _src: string;
  _duration: number;
  loaded: boolean;
  renderMode: Partial<CSSStyleDeclaration>;
  isPortrait: boolean;
  imgOverflow: boolean;
  maxHeight = 514;
  constructor(
    private featuresService: FeaturesService
  ) { 
  }

  ngOnInit() {
    // Source snapshot
    this._src = this.src;
    if (this._src) {
      const metadata = new Image();
      metadata.src = this._src;
      metadata.onload = () => {
        const { width, height } = metadata;
        this.imgOverflow = !this.featuresService.isMobile() && this.image.nativeElement.clientHeight > this.maxHeight;
        this.isPortrait = width < height || this.imgOverflow ? true : false;
      }
    }
  }

  preview(): void {
    this.imageChange.emit(PlayerStatus.PREVIEW);
  }
}
