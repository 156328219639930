import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';

import { HomeState } from '@shared/states/home/home.state';
import { HomeStateModel, Logo, Orientation } from '@shared/types';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'portal-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {
  home$ = this.store.select<HomeStateModel>(HomeState);

  
  @Input() universityName: string;
  @Input() logo: Logo;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();
  unsubscribe$ = new Subject();
  orientation: Orientation;
  mobileLandscape = Orientation.LANDSCAPE_MOBILE;

  get width(): string {
    return `${this.logo.logo_width}px`;
  }

  get height(): string {
    return `${this.logo.logo_height}px`;
  }
  constructor(private store: Store) { }

  ngOnInit() {
    this.home$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ orientation }) => {
      this.orientation = orientation;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
  }
}
