import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideMainTemplateService {
  isContentVisible: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor() {
    if (window.location.href.includes(`${window.location.host}/playlist/`)) {
      this.isContentVisible.next(false);
    }
  }

  hideContent(val: boolean): void {
    this.isContentVisible.next(val);
  }
}
