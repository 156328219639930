<div
  class="wrapper"
  [fxLayout]="orientation === mobileLandscape ? 'row' : 'column'"
  [style.visibility]="portalLoaded ? 'visible' : 'hidden'"
  [class.slideshow-mode]="!!viewmode"
  [@fadeOnLoad]="portalLoaded ? 'visible' : 'hidden'"
  [ngStyle]="{'background-color': isSlideShow ? 'black' : 'white'}"
>
  <button
    tabindex="-1"
    class="focus-reset"
    #resetFocus
    aria-label="Focus on top of the page"
    aria-hidden="true"
  ></button>
  <portal-toolbar
    [fxFlex]="orientation === mobileLandscape ? '80px' : 'initial'"
    [mainContentRef]="mainContent"
    *ngIf="!isSlideShow"
  ></portal-toolbar>
  <div
    #mainContent
    fxLayout="column"
    fxFlex="100%"
    [class.grid-mode]="!viewmode"
  >
    <router-outlet *ngIf="!isSlideShow"></router-outlet>
    <div>
      <portal-slide-show-ad *ngIf="isSlideShow"></portal-slide-show-ad>
    </div>

  </div>
  <portal-footer
    *ngIf="(!viewmode && orientation !== mobileLandscape) && !isSlideShow"
  ></portal-footer>
</div>
<div
  class="spinner"
  fxLayout="row wrap"
  fxLayoutAlign="center center"
  [@fadeOnLoad]="!portalLoaded ? 'visible' : ''"
  *ngIf="!portalLoaded"
>
  <mat-spinner></mat-spinner>
  <p fxFlex="100%">Retrieving portal, please wait...</p>
</div>
