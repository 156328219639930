<div class="filter-wrapper">
  <h3 class="mat-h3">Sort by</h3>
  <mat-radio-group
    [value]="config.sortBy"
    (change)="filterChange($event.value, 'sortBy')"
  >
    <mat-radio-button *ngFor="let sort of sortBy" [value]="sort.value">{{
      sort.label
    }}</mat-radio-button>
  </mat-radio-group>
  <ng-container *ngIf="filterBy && filterBy.length > 0">
    <h3 class="mat-h3">Filter by</h3>
    <!-- <ng-container *ngFor="let filter of filterBy">
      <p class="mat-small">{{ filter.title }}</p>
      <mat-radio-group
        name="filterGroup"
        (change)="filterChange($event.value, 'filterBy')"
        [value]="config.filterBy"
      >
        <mat-radio-button
          *ngFor="let opt of filter.options"
          [value]="opt.value"
          >{{ opt.label }}</mat-radio-button
        >
      </mat-radio-group>
    </ng-container> -->

    <mat-selection-list *ngFor="let filter of filterBy"
      [multiple]="true"
      (selectionChange)="filterByChange($event)"
      name="filterGroup">
      <p class="mat-small">{{ filter.title }}</p>
      <mat-list-option *ngFor="let option of filter.options" [selected]="checkSelected(option.value)" [value]="option.value">{{option.label}}</mat-list-option>
    </mat-selection-list>

  </ng-container>
  <p class="divider">Order</p>
  <mat-radio-group
    [value]="config.orderBy"
    (change)="filterChange($event.value, 'orderBy')"
  >
    <mat-radio-button *ngFor="let order of orderBy" [value]="order.value">{{
      order.label
    }}</mat-radio-button>
  </mat-radio-group>
</div>
<div
  mat-dialog-actions
  fxLayout="row nowrap"
  fxLayoutAlign="space-around center"
>
  <button mat-button mat-dialog-close fxFlex="100%">Cancel</button>
  <span class="h-divider"></span>
  <button mat-button fxFlex="100%" [matDialogClose]="{config:this._config, selected:this.selectedValues}">Done</button>
</div>
