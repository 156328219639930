import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersChange, SetViewMode } from '@shared/states/home/home.actions';
import { HomeState } from '@shared/states/home/home.state';
import { FiltersConfig, HomeStateModel, Option, ViewMode } from '@shared/types';
import { MobileFilterComponent } from '../dialogs/mobile-filter/mobile-filter.component';

@Component({
  selector: 'portal-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  home$ = this.store.select<HomeStateModel>(HomeState);

  
  @Input() isLandscape: boolean;
  filterBy: { title: string, options: Option[] }[];
  orderBy: Option[];
  sortBy: Option[];
  config: FiltersConfig;
  viewmode: ViewMode;
  selectedValues:any[]=['-1'];
  unsubscribe$ = new Subject();

  constructor(private store: Store, private matDialog: MatDialog) { }

  ngOnInit() {
    this.home$.pipe(takeUntil(this.unsubscribe$)).subscribe(home$ => {
      const { filters, viewmode, adtype_visible, affiliation_visible, network_visible, panel_visible, tags_visible } = home$;
      const { sortBy, orderBy, filterByNetwork, filterByAffiliation, filterByAdTypes, filterByPanels, filterByTags, config } = filters;
      this.viewmode = viewmode;
      this.sortBy = sortBy;
      this.orderBy = orderBy;
      // Note(Danny): Added identifiers to filter options, theres is a chance to have the same id value on networks and affiliations.
      // this.filterBy = [
      //   { title: 'None', options: [{ value: -1, label: 'None' }] }
      //   ,
      //   {
      //     title: 'Network', options: filterByNetwork ? filterByNetwork.map(n => ({
      //       ...n,
      //       value: `N-${n.value}`
      //     })) : []
      //   },
      //   {
      //     title: 'Affiliation', options: filterByAffiliation ? filterByAffiliation.map(a => ({
      //       ...a,
      //       value: `A-${a.value}`
      //     })) : []
      //   },
      // ];
      this.filterBy = [{ title: 'None', options: [{ value: '-1', label: 'None' }] }];

      if(filterByNetwork && network_visible){
        if(filterByNetwork.length>1){
          this.filterBy.push({
            title: 'Network', options: filterByNetwork.map(n => ({
              ...n,
              value: `N-${n.label}`
            }))
          })
        }
      }

      if(filterByAffiliation && affiliation_visible){
       if(filterByAffiliation.length>1){
        this.filterBy.push({
          title: 'Affiliation', options: filterByAffiliation.map(n => ({
            ...n,
            value: `A-${n.label}`
          }))
        });
       }
      }

      if(filterByPanels && panel_visible){
       if(filterByPanels.length>1){
        this.filterBy.push({
          title: 'Panels', options: filterByPanels.map(n => ({
            ...n,
            value: `P-${n.label}`
          }))
        });
       }
      }

      if(filterByTags && tags_visible){
        if(filterByTags.length>1){
          this.filterBy.push({
            title: 'Tags', options: filterByTags.map(n => ({
              ...n,
              value: `T-${n.label}`
            }))
          });
        }
      }

      if(filterByAdTypes && adtype_visible){
        if(filterByAdTypes.length>1){
          this.filterBy.push({
            title: 'Ad Types', options: filterByAdTypes.map(n => ({
              ...n,
              value: `AD-${n.label}`
            }))
          });
        }
      }

      this.config = config;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
  }

  selectViewMode(): void {
    this.viewmode = !!this.viewmode ? 0 : 1;
    this.store.dispatch(new SetViewMode(this.viewmode));
  }

  filtersDialog(): void {
    this.matDialog.open(MobileFilterComponent, {
      width: '100%',
      maxWidth: '500px',
      panelClass: 'mobile-view',
      position: { bottom: 'bottom' },
      data: {
        filterBy: this.filterBy,
        orderBy: this.orderBy,
        sortBy: this.sortBy,
        config: this.config,
        selectedValues:this.selectedValues
      }
    }).afterClosed().subscribe((result) => {
      if (result.config) {
        this.selectedValues=result.selected;
        this.store.dispatch(new FiltersChange(result.config));
      }
    })
  }

  filterChange(value: number, id: string): void {
    let config: FiltersConfig;
    switch (id) {
      case 'filterBy':
        config = {
          ...this.config,
          filterBy: value
        }
        break;
      case 'orderBy':
        config = {
          ...this.config,
          orderBy: value
        }
        break;
      case 'sortBy':
        config = {
          ...this.config,
          sortBy: value
        }
        break;
    }

    this.store.dispatch(new FiltersChange(config));

  }

}
