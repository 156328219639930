<footer
  class="mat-body"
  fxLayout="row wrap"
  fxLayoutAlign="space-around center"
  [fxLayoutAlign.gt-sm]="isIntegrate ? 'space-around center' : 'center center'"
  *ngIf="!hideTemplate"
>
  <div
    fxLayout="row"
    fxLayoutAlign.gt-sm="flex-start center"
    fxLayoutAlign="space-between center"
    fxFlex.gt-sm="325px"
    fxFlex="100%"
  >
    <div tabindex="0" class="logo-holder">
      <img [src]="logo" alt="" />
    </div>
    <span [style.marginRight.px]="20" [attr.aria-label]="apogee">{{
      apogee
    }}</span>
  </div>
  <div
    fxFlex
    fxLayoutAlign="center center"
    fxLayout="row"
    fxShow.gt-sm
    fxHide
    [class.integrate]="isIntegrate"
  >
    <nav mat-tab-nav-bar class="footer-links">
      <a
        [attr.aria-label]="
          menu.name === 'SUBMIT' ? 'Submit an Ad' : 'Navigate to ' + menu.name
        "
        [routerLink]="menu.value"
        mat-tab-link
        *ngFor="let menu of menus"
      >
        {{ menu.name }}
      </a>
    </nav>
  </div>
  <div fxLayout="row" fxShow.gt-sm fxHide [class.integrate]="isIntegrate">
    <nav mat-tab-nav-bar class="footer-social">
      <a
        aria-label="Back to top of page"
        mat-tab-link
        href
        (click)="navigateToTop($event)"
        >Back to top of page</a
      >
    </nav>
  </div>
  <div fxShow.gt-sm fxHide fxLayout="row">
    <nav
      mat-tab-nav-bar
      class="footer-social"
      fxLayoutAlign="flex-end center"
      fxLayout="row"
    >
      <a disabled aria-hidden="true" mat-tab-link>Share</a>
      <a
        *ngIf="universityName"
        [attr.aria-label]="'Share ' + [universityName] + ' portal on Facebook'"
        mat-tab-link
        [href]="facebookShare"
        target="_blank"
        ><mat-icon svgIcon="facebook"></mat-icon>
      </a>

      <a
        *ngIf="universityName"
        [attr.aria-label]="'Share ' + [universityName] + ' portal on Twitter'"
        mat-tab-link
        [href]="twitterShare"
        target="_blank"
        ><mat-icon svgIcon="twitter"></mat-icon
      ></a>
    </nav>
  </div>
</footer>
