<div mat-dialog-title fxLayoutAlign="center center" class="mat-elevation-z4">
  <img [src]="logo.logo_Url" alt="Portal Logo" />
</div>
<div fxLayout="row wrap" fxLayoutAlign="center center" class="content">
  <img width="50px" src="assets/icons/blocker.png" alt="" />
  <h1 fxFlex="100%">
    This site does not have ads!
  </h1>
  <p class="message">
    Please whitelist this site.<br />
    The site is not fully functional with <br />
    an adblocker active.
  </p>

  <div fxFlex="100%" fxLayout="row wrap" fxLayoutAlign="center start">
    <img
      src="assets/icons/logo-black.png"
      alt="Apogee logo"
      aria-label="Apogee logo"
      width="120px"
    />
    <p class="apooge" fxFlex="100%">{{ apogee }}</p>
  </div>
</div>
