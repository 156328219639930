import { Injectable } from '@angular/core';
import { Action, StateContext, State } from '@ngxs/store';

export enum UserPermissions {
  Read = 'read'
}

export interface AuthStateModel {
  userId: string;
  role: string;
  token: string;
  permissions: Array<UserPermissions>;
}

export class UserLoggedIn {
  static readonly type = 'UserLoggedIn';
}

export class UsserLoggedOut {
  static readonly type = 'UsserLoggedOut';
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    userId: localStorage.getItem('userId'),
    role: localStorage.getItem('role'),
    token: localStorage.getItem('token'),
    permissions: []
  }
})
@Injectable()
export class AuthState {
  @Action(UserLoggedIn)
  userLoggedIn({ patchState }: StateContext<AuthStateModel>) {
    patchState({
      userId: localStorage.getItem('userId'),
      role: localStorage.getItem('role'),
      token: localStorage.getItem('token')
    });
  }
}
