import { Guidelines } from '@shared/types';

export class GetGuidelines {
  static readonly type = 'GetGuidelines';
  constructor(public portalID: number) {}
}

export class GetGuidelinesSuccess {
  static readonly type = 'GetGuidelinesSuccess';
  constructor(
    public payload: Guidelines,
    public message: string,
    public status: string
  ) {}
}

export class GetGuidelinesError {
  static readonly type = 'GetGuidelinesError';
  constructor(public message: string, public status: string) {}
}
