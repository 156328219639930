import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatRadioModule } from '@angular/material/radio';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';

import { HomeRoutes } from './home.routing';
import { HomeComponent } from './home.component';
import { SlideshowComponent } from './slideshow/slideshow.component';
import { RevolutionSliderComponent } from './revolution-slider/revolution-slider.component';
import { HubTileComponent } from './hub-tile/hub-tile.component';
import { FeedPlayerModule } from '@shared/modules/feed-player/feed-player.module';
import { SliceComponent } from './revolution-slider/slice/slice.component';
import { WrongModule } from '@shared/modules/wrong/wrong.module';
import { FiltersModule } from '@shared/modules/filters/filters.module';


@NgModule({
  declarations: [
    HomeComponent,
    RevolutionSliderComponent,
    HubTileComponent,
    SliceComponent,
    SlideshowComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(HomeRoutes),
    FlexLayoutModule,
    FlexModule,
    FeedPlayerModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    WrongModule,
    MatGridListModule,
    FiltersModule
  ],
  exports: [HubTileComponent, SlideshowComponent, RevolutionSliderComponent],
  entryComponents: [HubTileComponent, SlideshowComponent]
})
export class HomeModule { }
