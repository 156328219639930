import { PublicPortal, Network, Option } from '@shared/types';

export class GetPublicPortal {
  static readonly type = 'GetPublicPortal';
  constructor(public univeristyID: number) { }
}

export class GetPublicPortalSuccess {
  static readonly type = 'GetPublicPortalSuccess';
  constructor(
    public payload: PublicPortal,
    public status: string,
    public message: string
  ) { }
}

export class GetPublicPortalError {
  static readonly type = 'GetPublicPortalError';
  constructor(public message: string, public status: string) { }
}

export class GetFormNetworksPublic {
  static readonly type = 'GetFormNetworksPublic';
  constructor(public formID: number) { }
}

export class GetFormNetworksPublicSuccess {
  static readonly type = 'GetFormNetworksPublicSuccess';
  constructor(public networks: Array<Option>, public status = 'success') { }
}

export class GetFormNetworksPublicError {
  static readonly type = 'GetFormNetworksPublicError';
  constructor(public message: string, public status = 'error') { }
}
