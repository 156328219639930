import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from '@shared/services/helpers/interceptor/interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SubmissionFormState } from '@shared/states/submission-form/submission-form.state';
import { UniversityState } from '@shared/states/university/university.state';
import { PublicPortalState } from '@shared/states/public-portal/public-portal.state';
import { environment } from 'environments/environment';
import { ToolbarComponent } from '@modules/toolbar/toolbar.component';
import { MenusComponent } from './modules/toolbar/menus/menus.component';
import { FooterComponent } from './modules/footer/footer.component';
import { GuidelinesState } from '@shared/states/guidelines/guidelines.state';
import { CreateAndAdState } from '@shared/states/create-and-ad/create-and-ad.state';
import { LogoModule } from '@shared/modules/logo/logo.module';
import { HomeState } from '@shared/states/home/home.state';
import { ManageState } from '@shared/states/manage/manage.state';
import { DialogsModule } from '@shared/modules/dialogs/dialogs.module';
import { ForbiddenComponent } from './modules/forbidden/forbidden.component';
import { AuthenticatedComponent } from '@modules/local-portal/authenticated/authenticated.component';
import { FiltersModule } from '@shared/modules/filters/filters.module';
import { SlideShowAdModule } from '@modules/slide-show-ad/slide-show-ad.module';
import { PlaylistState } from '@shared/states/playlists/playlists.state';
import { FeedPlayerAdminModule } from '@shared/modules/feed-player-admin/feed-player-admin.module';
import { FilesEntriesState, FilesState } from '@shared/states/files/files.state';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    MenusComponent,
    FooterComponent,
    ForbiddenComponent,
    AuthenticatedComponent
  ],
  entryComponents: [MenusComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatMenuModule,
    FlexLayoutModule,
    HttpClientModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    FeedPlayerAdminModule,
    NgxsModule.forRoot(
      [
        SubmissionFormState,
        UniversityState,
        PublicPortalState,
        GuidelinesState,
        CreateAndAdState,
        HomeState,
        ManageState,
        PlaylistState,
        FilesState,
        FilesEntriesState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    LogoModule,
    DialogsModule,
    FiltersModule,
    SlideShowAdModule,
    GoogleTagManagerModule.forRoot({id:'GTM-MQT9BFP',})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
