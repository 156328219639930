import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { FileService } from '@shared/services/features/file.service';

import { FILE_TYPE } from '@shared/types';
import { PlayerStatus } from './types';

@Component({
  selector: 'portal-embed-player',
  templateUrl: './portal-embed-player.component.html',
  styleUrls: ['./portal-embed-player.component.scss']
})
export class PortalEmbedPlayerComponent implements OnInit {
  @Input() url: string;
  @Input() mode: 'player' | 'thumbnail' = 'player';
  @Input() width: string;
  @Input() height: string;
  @Input() mute: boolean;
  @Input() duration: number;
  @Input() index: number;
  @Input() autoplay: boolean;
  @Output() embedChange: EventEmitter<PlayerStatus> = new EventEmitter(null);
  @Output() fileTypeRecieved: EventEmitter<FILE_TYPE> = new EventEmitter(null);
  FILE_TYPE = FILE_TYPE;
  fileType: FILE_TYPE;
  previewLoaded=false;
  error: boolean;
  constructor(
    private fileService: FileService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.url) {
      this.fileType = this.fileService.getFileType(this.url);
      this.fileTypeRecieved.emit(this.fileType);
    } else {
      this.embedChange.emit(PlayerStatus.ENDED);
    }
    this.previewLoaded=true;
  }

  mediaChange(event: PlayerStatus): void {
    if (event === PlayerStatus.PLAYING) {
      this.previewLoaded = true;
    }
    if (event === PlayerStatus.ERROR) {
      this.previewLoaded = true;
      this.error = true;
    }
    if(event===PlayerStatus.PREVIEW){
      this.previewLoaded=true;
    }
    this.cdRef.detectChanges();
    this.embedChange.emit(event);
  }
}
