import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  GetSocialHUB,
  GetSocialHUBSuccess,
  GetSocialHUBError,
  SetViewMode,
  SetOrientation,
  SetFilterByNetworkOpts,
  SetFilterByAffiliationOpts,
  FiltersChange,
  SetFilterByTagOpts,
  SetFilterByPanelOpts,
  SetFilterByAdTypeOpts
} from './home.actions';
import { FileService } from '@shared/services/api/file-service/file.service';
import { HomeStateModel, OrderBy, SortBy, ViewMode } from '@shared/types';

@State<HomeStateModel>({
  name: 'home',
  defaults: {
    loading: null,
    message: null,
    status: null,
    entries: null,
    count: null,
    viewmode: ViewMode.GRID,
    orientation: null,
    adtype_visible:false,
    affiliation_visible:false,
    network_visible:false,
    panel_visible:false,
    tags_visible:false,
    filters: {
      orderBy: [
        {
          label: 'Ascending',
          value: OrderBy.ASC
        },
        {
          label: 'Descending',
          value: OrderBy.DESC
        },
      ],
      sortBy: [
        {
          label: 'Start Date',
          value: SortBy.START_DATE
        },
        {
          label: 'End Date',
          value: SortBy.END_DATE
        },
        {
          label: 'Submission Date',
          value: SortBy.SUBMISSION_DATE
        },
        {
          label: 'Name',
          value: SortBy.NAME
        },
      ],
      filterByNetwork: null,
      filterByAffiliation: null,
      filterByTags:null,
      filterByPanels:null,
      filterByAdTypes:null,
      config: {
        orderBy: OrderBy.ASC,
        filterBy: -1,
        sortBy: SortBy.START_DATE
      }
    }
  }
})
@Injectable()
export class HomeState {
  constructor(private fileService: FileService) { }

  @Action(GetSocialHUB)
  getSocialHUB(
    { patchState }: StateContext<HomeStateModel>,
    action: GetSocialHUB
  ) {
    const { formID } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.getSocialHUB(formID);
  }

  @Action(GetSocialHUBSuccess)
  getSocialHUBSuccess(
    { patchState }: StateContext<HomeStateModel>,
    action: GetSocialHUBSuccess
  ) {
    const { payload, message, status } = action;

    const { entries, count, adtype_visible, affiliation_visible, network_visible, panel_visible, tags_visible } = payload;

    patchState({
      loading: false,
      message,
      status,
      entries,
      count,
      adtype_visible,
      affiliation_visible,
      network_visible,
      panel_visible,
      tags_visible
    });
  }

  @Action(GetSocialHUBError)
  getSocialHUBError(
    { patchState }: StateContext<HomeStateModel>,
    action: GetSocialHUBError
  ) {
    const { message, status } = action;

    patchState({
      loading: true,
      message,
      status
    });
  }

  @Action(SetViewMode)
  setViewMode({ patchState }: StateContext<HomeStateModel>, action: SetViewMode) {
    patchState({
      viewmode: action.mode
    });
  }

  @Action(SetOrientation)
  setOrientation({ patchState }: StateContext<HomeStateModel>, action: SetOrientation) {
    const { orientation } = action;
    patchState({
      orientation
    });
  }

  @Action(SetFilterByNetworkOpts)
  setFilterByNetworkOpts({ patchState, getState }: StateContext<HomeStateModel>, action: SetFilterByNetworkOpts) {
    const { filterByNetwork } = action;
    const filters = getState().filters;
    patchState({
      filters: {
        ...filters,
        filterByNetwork
      }
    });
  }

  @Action(SetFilterByAffiliationOpts)
  setFilterByAffiliationOpts({ patchState, getState }: StateContext<HomeStateModel>, action: SetFilterByAffiliationOpts) {
    const { filterByAffiliation } = action;
    const filters = getState().filters;
    patchState({
      filters: {
        ...filters,
        filterByAffiliation
      }
    });
  }

  @Action(SetFilterByTagOpts)
  setFilterByTagOpts({patchState, getState}:StateContext<HomeStateModel>, action: SetFilterByTagOpts){
    const {filterByTags}=action;
    const filters=getState().filters;
    patchState({
      filters:{
        ...filters,
        filterByTags
      }
    });
  }

  @Action(SetFilterByPanelOpts)
  setFilterByPanelOpts({patchState, getState}:StateContext<HomeStateModel>, action: SetFilterByPanelOpts){
    const {filterByPanels}=action;
    const filters=getState().filters;
    patchState({
      filters:{
        ...filters,
        filterByPanels
      }
    });
  }

  @Action(SetFilterByAdTypeOpts)
  SetFilterByAdTypeOpts({patchState, getState}:StateContext<HomeStateModel>, action: SetFilterByAdTypeOpts){
    const {filterByAdTypes}=action;
    const filters=getState().filters;
    patchState({
      filters:{
        ...filters,
        filterByAdTypes
      }
    });
  }

  @Action(FiltersChange)
  filtersChange({ patchState, getState }: StateContext<HomeStateModel>, action: FiltersChange) {
    const filters = getState().filters;
    const { config } = action;
    patchState({
      filters: {
        ...filters,
        config
      }
    });
  }
}
