import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { FormGroup, AbstractControl } from '@angular/forms';

import { ControlValidationService } from '../control-validation.service';
import { Option } from '@shared/types';


@Component({
  selector: 'portal-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() form: FormGroup = new FormGroup({});
  @Input() controlName: string;
  @Input() value: any = null;
  @Input() options: Array<Option> = [];
  @Input() multiple: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() hintStart: string;
  @Input() hintEnd: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() customErrors: { [key: string]: any };
  @Input() ariaLabel: string;
  @Input() required: any;
  @Input() disabled: boolean;
  @Input() activeSelectAdmin:boolean;
  @Input() groupOpts: [{ title: string, options: Option[] }];
  @Input() isFilterBy:boolean=false;
  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter(
    null
  );
  control: AbstractControl;
  isRequired: boolean;
  errorList: { [key: string]: any };

  constructor(
    private controlValidationService: ControlValidationService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!Number.isInteger(this.value) && !this.activeSelectAdmin) {
      this.options = [{
        label: 'No value selected',
        value: this.value,
        disabled: true
      }, ...this.options];
    }
    if (this.controlName) {
      this.control = this.form.get(this.controlName);
    }
    if (this.required && this.required.errors) {
      this.isRequired = this.required.errors.required && true;
    }
    /*
      Force change detection, need it to avoid
      "Expression has changed after it was checked" on event driven selects
    */
    this.cdRef.detectChanges();
  }

  setTriggerText(value: any): string {
    let txt: Option;
    txt = this.options.find(option => option.value === value);
    return txt ? txt.label : '';
  }

  changeEventFilter(event: MatSelectChange): void {
    //none selected
    if(Array.isArray(this.value)){
      if(!this.value.some(x=>x==='-1') && event.value.some(x=>x==='-1')){
        let _newArray:number[]=[];
        event.value.forEach((item, index) => {
          if(item!='-1'){
            _newArray.push(index);
          }
        });
        _newArray.reverse().forEach(item=>{
          event.value.splice(item,1);
        })
      }
    }

    this.value = event.value;
    //remove none, only loop filter array valid
    if(this.value.length>1 && this.value.some(x=>x==='-1')){
      let pos=this.value.indexOf('-1');
      this.value.splice(pos,1)
    }

    if(this.value.length==0){
      this.value.push('-1');
    }

    this.selectionChange.emit(this.value);
  }

  changeEvent(event: { target: HTMLSelectElement }): void {
    if (Number.isInteger(parseInt(event.target.value, 10))) {
      this.value = parseInt(event.target.value, 10);
    } else {
      this.value = event.target.value;
    }
    this.selectionChange.emit(this.value);
  }


  errors(control: AbstractControl): string {
    const { errors } = control;
    if (errors) {
      const inputName = this.label || this.controlName;
      this.errorList = this.controlValidationService.commonErrors(
        this.control,
        inputName
      );
      if (this.customErrors) {
        this.errorList = { ...this.errorList, ...this.customErrors };
      }

      return this.errorList[Object.keys(errors)[0]];
    }
  }
}
