<div
  class="preview-content"
  #content
  [style.height]="contentOverflow ? contentOverflow : 'initial'"
  [class.overflow]="!!contentOverflow"
>
  <!-- <portal-hub-tile
    [tileIndex]="-1"
    [entry]="data.entry"
    mode="player"
  ></portal-hub-tile> -->
<!-- new version adjusted support videos & images -->
  <portal-embed-player
  [url]="url"
  [ngClass]="type"
  [autoplay]="true"
  (fileTypeRecieved)="fileTypeRecieved($event)">
  </portal-embed-player>

  <button
    class="close-dialog"
    mat-icon-button
    mat-dialog-close
    fxLayout="row"
    fxLayoutAlign="center center"
    fxHide
    fxShow.gt-xs
    aria-label="Close dialog"
  >
    <mat-icon fxLayout="row" fxLayoutAlign="center center">cancel</mat-icon>
  </button>
  <div class="details" fxHide fxShow.gt-xs>
    <h1 class="mat-h1" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>error</mat-icon>
      Description
    </h1>
    <p class="mat-body">{{ data.entry.description }}</p>
  </div>
  <div
    class="social"
    fxLayout="row"
    fxLayoutAlign="statr center"
    fxLayoutGap="20px"
    fxShow.gt-xs
    fxHide
  >
    <a mat-icon-button aria-hidden="true" disabled>
      <mat-icon>share</mat-icon>
    </a>
    <a
      mat-icon-button
      aria-label="Share on facebook"
      *ngIf="facebookShare"
      [href]="facebookShare"
      target="_blank"
    >
      <mat-icon svgIcon="facebook"></mat-icon>
    </a>
    <a
      mat-icon-button
      aria-label="Share on twitter"
      *ngIf="twitterShare"
      [href]="twitterShare"
      target="_blank"
    >
      <mat-icon svgIcon="twitter"></mat-icon>
    </a>
  </div>
</div>
