import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ManageStateModel } from '@shared/types';
import {
  GetEntries,
  GetEntriesSuccess,
  GetEntriesError,
  GetFormPanels,
  GetFormPanelsSuccess,
  GetFormPanelsError,
  UpdateStarredStatusError,
  UpdateApprovedStatus,
  UpdateApprovedStatusSuccess,
  UpdateApprovedStatusError,
  UpdateStarredStatus,
  UpdateStarredStatusSuccess,
  RetryEntry,
  RetryEntrySuccess,
  RetryEntryError,
  UpdateEntriePanel,
  UpdateEntriePanelSuccess,
  UpdateEntriePanelError,
  UpdateEntrieDuration,
  UpdateEntrieDurationSuccess,
  UpdateEntrieDurationError,
  DownloadFile,
  UpdateEntryColumns,
  UpdateEntryColumnsSuccess,
  UpdateEntryColumnsError,
  GetEntryColumns,
  GetEntryColumnsError,
  GetEntryColumnsSuccess,
  UpdateEntrieStartEndDate,
  UpdateEntrieStartEndDateSuccess,
  UpdateEntrieStartEndDateError,
  DeleteFile,
  DeleteFileSuccess,
  DeleteFileError
} from './manage.actions';
import { SubmissionFormService } from '@shared/services/api/submission-form/submission-form.service';
import { FileService } from '@shared/services/api/file-service/file.service';

@State<ManageStateModel>({
  name: 'manage',
  defaults: {
    entries: [],
    panels: [],
    columns: [],
    count: null,
    loading: null,
    message: null,
    status: null
  }
})
@Injectable()
export class ManageState {
  constructor(
    private submissionFormService: SubmissionFormService,
    private fileService: FileService
  ) { }

  @Action(GetEntries)
  getEntries(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntries
  ) {
    const { customerID, bodyParams } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getSubmissionFormsList(customerID, bodyParams);
  }

  @Action(GetEntriesSuccess)
  getEntriesSuccess(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntriesSuccess
  ) {
    const { payload, status, message } = action;
    const { entries, count } = payload;
    patchState({
      loading: false,
      status,
      message,
      entries,
      count
    });
  }

  @Action(GetEntriesError)
  getEntriesError(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntriesError
  ) {
    const { message, status } = action;

    patchState({
      entries: [],
      loading: false,
      message,
      status
    });
  }

  @Action(GetFormPanels)
  getFormPanels(
    { patchState }: StateContext<ManageStateModel>,
    action: GetFormPanels
  ) {
    const { formID } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getSubmissionFormsPanels(formID);
  }

  @Action(GetFormPanelsSuccess)
  getFormPanelsSuccess(
    { patchState }: StateContext<ManageStateModel>,
    action: GetFormPanelsSuccess
  ) {
    const { status, panels } = action;

    patchState({
      loading: false,
      message: 'Form Panels Retrieve',
      panels,
      status
    });
  }

  @Action(GetFormPanelsError)
  getFormPanelsError(
    { patchState }: StateContext<ManageStateModel>,
    action: GetFormPanelsError
  ) {
    const { status, message } = action;
    patchState({
      loading: false,
      message,
      status,
      panels: []
    });
  }

  @Action(UpdateStarredStatus)
  updateStarredStatus(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateStarredStatus
  ) {
    const { starred, fileIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.fileService.updateStarredStatus(starred, fileIDs);
  }

  @Action(UpdateStarredStatusSuccess)
  updateStarredStatusSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: UpdateStarredStatusSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { starredStatus, fileIDs } = payload;

    const entries = stateEntries.map(entry => {
      if (fileIDs.some(ids => ids === entry.files_id)) {
        entry = {
          ...entry,
          files_starred_Status: starredStatus ? 1 : 0
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateStarredStatusError)
  updateStarredStatusError(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateStarredStatusError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateApprovedStatus)
  updateApprovedStatus(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateApprovedStatus
  ) {
    const { approved, fileIDs } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.fileService.updateApprovedStatus(approved, fileIDs);
  }

  @Action(UpdateApprovedStatusSuccess)
  updateApprovedStatusSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: UpdateApprovedStatusSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { approvedStatus, fileIDs } = payload;

    const entries = stateEntries.map(entry => {
      if (fileIDs.some(id => id === entry.files_id)) {
        entry = {
          ...entry,
          files_approved_Status: approvedStatus ? 1 : 0
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      status,
      message
    });
  }

  @Action(UpdateApprovedStatusError)
  updateApprovedStatusError(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateApprovedStatusError
  ) {
    const { status, message } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(RetryEntry)
  retryEntry(
    { patchState }: StateContext<ManageStateModel>,
    action: RetryEntry
  ) {
    const { fileID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.retryTranscodeEntries(fileID);
  }

  @Action(RetryEntrySuccess)
  retryEntrySuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: RetryEntrySuccess
  ) {
    const stateEntries = getState().entries;
    const { fileID, message, status } = action;

    const entries = stateEntries.map(entry => {
      if (entry.files_id === fileID) {
        entry = {
          ...entry,
          files_transcodeStatus: 2
        };
      }
      return entry;
    });

    patchState({
      loading: false,
      message,
      status,
      entries
    });
  }

  @Action(RetryEntryError)
  retryEntryError(
    { patchState }: StateContext<ManageStateModel>,
    action: RetryEntryError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntriePanel)
  updateEntriePanel(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntriePanel
  ) {
    const { fileID, panelID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.updateEntriePanels(fileID, panelID);
  }

  @Action(UpdateEntriePanelSuccess)
  updateEntriePanelSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: UpdateEntriePanelSuccess
  ) {
    const { entries: entriesState, panels } = getState();
    const { payload, status, message } = action;
    const { fileID, panelID } = payload;
    const panel = panels.find(panelOpt => panelOpt.id === panelID);

    const entries = entriesState.map(entry => {
      if (entry.files_id === fileID) {
        entry = {
          ...entry,
          panel_id: panel.id,
          panel_name: panel.name
        };
      }

      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntriePanelError)
  updateEntriePanelError(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntriePanelError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieDuration)
  updateEntrieDuration(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntrieDuration
  ) {
    const { fileID, duration } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.updateEntriesDuration(fileID, duration);
  }

  @Action(UpdateEntrieDurationSuccess)
  updateEntrieDurationSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: UpdateEntrieDurationSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { fileID, duration } = payload;
    const entries = stateEntries.map(entry => {
      if (entry.files_id === fileID) {
        entry = {
          ...entry,
          files_duration: duration
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieDurationError)
  updateEntrieDurationErro(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntrieDurationError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(DownloadFile)
  dowloadFile(
    { patchState }: StateContext<ManageStateModel>,
    action: DownloadFile
  ) {
    const { fileID, fileVersion } = action;

    patchState({
      message: null,
      status: null
    });

    this.fileService.downloadFile(fileID, fileVersion);
  }

  @Action(GetEntryColumns)
  getEntryColumns(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntryColumns
  ) {
    const { formID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getEntryColumns(formID);
  }

  @Action(GetEntryColumnsSuccess)
  getEntryColumnsSuccess(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntryColumnsSuccess
  ) {
    const { payload, message, status } = action;

    patchState({
      loading: false,
      status,
      message,
      columns: payload
    });
  }

  @Action(GetEntryColumnsError)
  getEntryColumnsError(
    { patchState }: StateContext<ManageStateModel>,
    action: GetEntryColumnsError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(UpdateEntryColumns)
  updateEntryColumns(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntryColumns
  ) {
    const { formID, columns } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.updateEntryColumns(formID, columns);
  }

  @Action(UpdateEntryColumnsSuccess)
  updateEntryColumnsSuccess(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntryColumnsSuccess
  ) {
    const { columns, message, status } = action;

    patchState({
      loading: false,
      message,
      status,
      columns
    });
  }

  @Action(UpdateEntryColumnsError)
  updateEntryColumnsError(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntryColumnsError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieStartEndDate)
  updateEntrieStartEndDate(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntrieStartEndDate
  ) {
    const { fileID, dates } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.fileService.updateEntrieStartEndDate(fileID, dates);
  }

  @Action(UpdateEntrieStartEndDateSuccess)
  updateEntrieStartEndDateSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: UpdateEntrieStartEndDateSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { fileID, dates } = payload;
    const { startDate, endDate } = dates;

    const entries = stateEntries.map(entry => {
      if (entry.files_id === fileID) {
        entry = {
          ...entry,
          files_start_Date: startDate,
          files_end_Date: endDate
        };
      }
      return entry;
    });

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateEntrieStartEndDateError)
  updateEntrieStartEndDateError(
    { patchState }: StateContext<ManageStateModel>,
    action: UpdateEntrieStartEndDateError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(DeleteFile)
  deleteFile(
    { patchState }: StateContext<ManageStateModel>,
    action: DeleteFile
  ) {
    const { fileID } = action;

    patchState({
      loading: true,
      status: null,
      message: null
    });

    this.fileService.deleteFile(fileID);
  }

  @Action(DeleteFileSuccess)
  deleteFileSuccess(
    { patchState, getState }: StateContext<ManageStateModel>,
    action: DeleteFileSuccess
  ) {
    const { entries: stateEntries } = getState();
    const { payload, status, message } = action;
    const { fileID } = payload;

    const entries = stateEntries.filter(entry => entry.files_id !== fileID);

    patchState({
      entries,
      loading: false,
      message,
      status
    });
  }

  @Action(DeleteFileError)
  deleteFileError(
    { patchState }: StateContext<ManageStateModel>,
    action: DeleteFileError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }
}
