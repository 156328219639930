import {
  EntriesBodyParams,
  EntriesResponse,
  Panel,
  EntryColumn
} from '@shared/types';

export class GetEntries {
  static readonly type = 'GetEntries';
  constructor(
    public customerID: number,
    public bodyParams: EntriesBodyParams
  ) {}
}

export class GetEntriesSuccess {
  static readonly type = 'GetEntriesSuccess';
  constructor(
    public payload: EntriesResponse,
    public status = 'success',
    public message = 'Entries Retrieved Successfully'
  ) {}
}

export class GetEntriesError {
  static readonly type = 'GetEntriesError';
  constructor(public message: string, public status = 'error') {}
}

export class GetFormPanels {
  static readonly type = 'GetFormPanels';
  constructor(public formID: number) {}
}

export class GetFormPanelsSuccess {
  static readonly type = 'GetFormPanelsSuccess';
  constructor(public panels: Array<Panel>, public status = 'success') {}
}

export class GetFormPanelsError {
  static readonly type = 'GetFormPanelsError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdateStarredStatus {
  static readonly type = 'UpdateStarredStatus';
  constructor(public starred: boolean, public fileIDs: Array<number>) {}
}

export class UpdateStarredStatusSuccess {
  static readonly type = 'UpdateStarredStatusSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Starred Status Updated Successfully'
  ) {}
}

export class UpdateStarredStatusError {
  static readonly type = 'UpdateStarredStatusError';
  constructor(public message: any, public status = 'error') {}
}

export class UpdateApprovedStatus {
  static readonly type = 'UpdateApprovedStatus';
  constructor(public approved: boolean, public fileIDs: Array<number>) {}
}

export class UpdateApprovedStatusSuccess {
  static readonly type = 'UpdateApprovedStatusSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Approve Status Updated Successfully'
  ) {}
}

export class UpdateApprovedStatusError {
  static readonly type = 'UpdateApprovedStatusError';
  constructor(public message: string, public status = 'error') {}
}

export class RetryEntry {
  static readonly type = 'RetryEntry';
  constructor(public fileID: number) {}
}

export class RetryEntrySuccess {
  static readonly type = 'RetryEntrySuccess';
  constructor(
    public fileID: number,
    public message: string,
    public status: string
  ) {}
}

export class RetryEntryError {
  static readonly type = 'RetryEntryError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntriePanel {
  static readonly type = 'UpdateEntriePanel';
  constructor(public fileID: number, public panelID: number) {}
}

export class UpdateEntriePanelSuccess {
  static readonly type = 'UpdateEntriePanelSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Panel Updated Successfully'
  ) {}
}

export class UpdateEntriePanelError {
  static readonly type = 'UpdateEntriePanelError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdateEntrieDuration {
  static readonly type = 'UpdateEntrieDuration';
  constructor(public fileID: number, public duration: number) {}
}

export class UpdateEntrieDurationSuccess {
  static readonly type = 'UpdateEntrieDurationSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Duration Updated Successfully'
  ) {}
}

export class UpdateEntrieDurationError {
  static readonly type = 'UpdateEntrieDurationError';
  constructor(public message: string, public status = 'error') {}
}

export class DownloadFile {
  static readonly type = 'DownloadFile';
  constructor(
    public fileID: number,
    public fileVersion: 'transcoded' | 'original'
  ) {}
}

export class GetEntryColumns {
  static readonly type = 'GetEntryColumns';
  constructor(public formID: number) {}
}

export class GetEntryColumnsSuccess {
  static readonly type = 'GetEntryColumnsSuccess';
  constructor(
    public payload: Array<EntryColumn>,
    public message: string,
    public status: string
  ) {}
}

export class GetEntryColumnsError {
  static readonly type = 'GetEntryColumnsError';
  constructor(public message: string, public status: string) {}
}

export class UpdateEntryColumns {
  static readonly type = 'UpdateEntryColumns';
  constructor(public formID: number, public columns: Array<EntryColumn>) {}
}

export class UpdateEntryColumnsSuccess {
  static readonly type = 'UpdateEntryColumnsSuccess';
  constructor(
    public columns: Array<EntryColumn>,
    public message: string,
    public status: string
  ) {}
}

export class UpdateEntryColumnsError {
  static readonly type = 'UpdateEntryColumnsError';
  constructor(public message: string, public status: string) {}
}
export class UpdateEntrieStartEndDate {
  static readonly type = 'UpdateEntrieStartEndDate';
  constructor(
    public fileID: number,
    public dates: { startDate: string; endDate: string }
  ) {}
}

export class UpdateEntrieStartEndDateSuccess {
  static readonly type = 'UpdateEntrieStartEndDateSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Dates Updated Successfully'
  ) {}
}

export class UpdateEntrieStartEndDateError {
  static readonly type = 'UpdateEntrieStartEndDateError';
  constructor(public message: string, public status = 'error') {}
}

export class DeleteFile {
  static readonly type = 'DeleteFile';
  constructor(public fileID: number) {}
}

export class DeleteFileSuccess {
  static readonly type = 'DeleteFileSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Entry File Deleted Successfully'
  ) {}
}

export class DeleteFileError {
  static readonly type = 'DeleteFileError';
  constructor(public message: string, public status = 'error') {}
}
