import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
  Inject
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
// import 'hammerjs';

import { environment } from 'environments/environment';
import {
  UniversityState,
  UniversityStateModel
} from '@shared/states/university/university.state';
import {
  GetPublicPortal,
  GetPublicPortalSuccess
} from '@shared/states/public-portal/public-portal.actions';
import { FadeIn, FadeOut } from '@shared/animations';
import { GetUniversityByURL } from '@shared/states/university/university.actions';
import { FeaturesService } from '@shared/services/features/features.service';
import { GetSubmissionForm } from '@shared/states/submission-form/submission-form.actions';
import { YouTubePlayerService } from '@shared/modules/feed-player/youtube-player/youtube-player.service';
import { MatDialog } from '@angular/material/dialog';
import { BlockerComponent } from '@shared/modules/dialogs/blocker/blocker.component';
import { HomeState } from '@shared/states/home/home.state';
import { HomeStateModel, Orientation } from '@shared/types';
import { SetOrientation } from '@shared/states/home/home.actions';
import { GoogleTagManagerConfig, GoogleTagManagerService } from 'angular-google-tag-manager';

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeOnLoad', [
      transition('hidden => visible', [
        useAnimation(FadeIn, { params: { time: '0.5s' } })
      ]),
      transition(':leave', [
        useAnimation(FadeOut, { params: { time: '0.5s' } })
      ])
    ])
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  home$ = this.store.select<HomeStateModel>(HomeState);
  university$ = this.store.select<UniversityStateModel>(UniversityState);
  @ViewChild('resetFocus', { static: true }) resetFocus: ElementRef<
    HTMLButtonElement
  >;
  portalLoaded: boolean;
  viewmode: number;
  orientation: Orientation;
  mobileLandscape = Orientation.LANDSCAPE_MOBILE;
  isMobile = this.featuresService.isMobile();
  unsubscribe$ = new Subject();
  isSlideShow:boolean;
  // Note(Danny): Orientation change event skipped caused safari browser...
  @HostListener('window:resize')
  orientationChange() {
    this.setOrientation();
  }

  constructor(
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    public router: Router,
    private actions$: Actions,
    private featuresService: FeaturesService,
    private youTubePlayerService: YouTubePlayerService,
    private matDialog: MatDialog,
    private gtmService: GoogleTagManagerService
  ) {
    //this.gtmService.addGtmToDom();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(event.url.includes('/slideshow'))
          this.isSlideShow=true;
        else
          this.isSlideShow=false;
      }
    });
    this.youTubePlayerService.loadAPI();
    this.subscriptions();
    this.registerIcons();
    this.setOrientation();
    if (this.featuresService.getCredential('token')) {
      this.featuresService.adminAuthenticated.next(JSON.parse(this.featuresService.getCredential('isAdmin')));
      this.featuresService.authenticated.next(true);
    }

  }

  ngOnInit() {
    this.adBlockerActive();
    
    this.router.events.forEach(item => {
      
      if (item instanceof NavigationEnd) {
        
        const gtmTag = {
              event: 'page',
              pageName: this.document.location.href
          };
          this.gtmService.pushTag(gtmTag);
      }
  });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
  }

  subscriptions(): void {
    this.featuresService.navigatedToTop
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(navigated => {
        if (navigated) {
          this.resetFocus.nativeElement.focus();
        }
      });

    this.university$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(university$ => {
        const { loading, university } = university$;
        if (loading === false && university) {
          const { id, publicPortalDisabled } = university;
          this.store.dispatch(new GetPublicPortal(id));
          this.store.dispatch(new GetSubmissionForm(id));

          if(publicPortalDisabled){
            this.router.navigate(['/admin']);
          }

        }
      });
    this.actions$
      .pipe(
        takeUntil(this.unsubscribe$),
        ofActionSuccessful(GetPublicPortalSuccess)
      )
      .subscribe(() => {
        this.portalLoaded = true;
      });
    this.home$.pipe((takeUntil(this.unsubscribe$))).subscribe(home$ => {
      const { viewmode } = home$;
      this.viewmode = Number(viewmode);
    });
    const domain = environment.domain;
    this.store.dispatch(new GetUniversityByURL(domain));
  }

  registerIcons(): void {
    const icons = [
      {
        path: 'assets/icons/facebook.svg',
        name: 'facebook'
      },
      {
        path: 'assets/icons/twitter.svg',
        name: 'twitter'
      },
      {
        path: 'assets/icons/arrange.svg',
        name: 'arrange'
      },
      {
        path: 'assets/icons/panel.svg',
        name: 'panel'
      }
    ];

    icons.forEach(icon =>
      this.featuresService.addSvgIcon(icon.name, icon.path)
    );
  }

  adBlockerActive(): void {
    this.featuresService.adBlockerPresent().then(res => { }, err => {
      setTimeout(() => {
        this.matDialog.open(BlockerComponent, {
          width: '500px',
          height: '450px',
          panelClass: 'not-padding'
        });
      }, 500);
    })
  }

  setOrientation(): void {
    const { matches } = window.matchMedia('(orientation: landscape)');
    if (matches && this.isMobile) {
      const { clientWidth, clientHeight } = this.document.body;
      this.orientation = clientWidth / clientHeight >= 1.77 ? Orientation.LANDSCAPE_MOBILE : Orientation.LANDSCAPE;
    } else {
      this.orientation = Orientation.PORTRAIT;
    }
    this.store.dispatch(new SetOrientation(this.orientation));
  }
}
