import { Component, ViewChild, Input } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

import { MenuItem, MenuItemType } from '@shared/types';

@Component({
  selector: 'portal-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss']
})
export class MenusComponent {
  @ViewChild('menuRef', { static: true }) public menuRef: MatMenu;
  @Input() menus: Array<MenuItem>;
  @Input() isRoot: boolean;
  menuTypes = MenuItemType;
  constructor() {}
}
