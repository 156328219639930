import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { Playlist, PlaylistContent, PlaylistStateModel } from "@shared/modules/table/types";
import { PlaylistService } from "@shared/services/api/playlist-service/playlist.service";
import { DeleteFileSuccess } from "../manage/manage.actions";
import { DeletePlaylist, DeletePlaylistContent, DeletePlaylistContentError, DeletePlaylistContentSuccess, DeletePlaylistError, DeletePlaylistSuccess, GetPlaylist, GetPlaylistContent, GetPlaylistContentError, GetPlaylistContentNonSSO, GetPlaylistContentSuccess, GetPlaylistError, GetPlaylistNonSSO, GetPlaylists, GetPlaylistsError, GetPlaylistsSuccess, GetPlaylistSuccess, GetXMLOutput, RearrangePlaylistContent, RearrangePlaylistContentError, RearrangePlaylistContentSuccess, SavePlaylist, SavePlaylistError, SavePlaylistSuccess, TogglePlaylistApproveStatus, TogglePlaylistApproveStatusError, TogglePlaylistApproveStatusSuccess, TogglePlaylistMuteStatus, TogglePlaylistMuteStatusError, TogglePlaylistMuteStatusSuccess } from "./playlists.actions";

@State<PlaylistStateModel>({
  name:'playlist',
  defaults:{
    playlists:null,
    playlistContent:null,
    count:null,
    status:null,
    message:null,
    loading:null
  }
})

@Injectable()
export class PlaylistState{
  constructor(private playlistService: PlaylistService){}

  @Action(GetPlaylists)
  getPlayLists(
    { patchState }: StateContext<PlaylistStateModel>,
    action: GetPlaylists
  ){
    const { customerID, formID, body }=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });

    this.playlistService.getPlaylistsAll(customerID, formID, body)
  }

  @Action(GetPlaylistsSuccess)
  getGetPlaylistsSuccess(
    {patchState}:StateContext<PlaylistStateModel>,
    action:GetPlaylistsSuccess
  ){
    const { payload, status, message }=action;
    const { playlists, count }=payload;
    patchState({
      loading:false,
      playlists,
      count,
      status,
      message
    });
  }

  @Action(GetPlaylistsError)
  getGetPlaylistsError(
    {patchState}:StateContext<PlaylistStateModel>,
    action:GetPlaylistsError
  ){
    const { message, status }=action;
    patchState({loading:false, message, status})
  }

  @Action(GetPlaylistNonSSO)
  getPlaylistNonSSO(
    { patchState }: StateContext<PlaylistStateModel>,
    action: GetPlaylistNonSSO
  ) {
    const { playlistID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.playlistService.getPlaylistNonSSO(playlistID);
  }

  @Action(GetPlaylistContentNonSSO)
  getPlaylistContentNonSSO(
    { patchState }: StateContext<PlaylistStateModel>,
    action: GetPlaylistContent
  ) {
    const { playlistID } = action;

    patchState({
      loading: true,
      status: null,
      message: null
    });

    this.playlistService.getPlaylistContentNonSSO(playlistID);
  }


  @Action(GetPlaylist)
  getPlaylist(
    {patchState}:StateContext<PlaylistStateModel>,
    action:GetPlaylist
  ){
    const {playlistID}=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });
    this.playlistService.getPlaylist(playlistID);
  }

  @Action(GetPlaylistSuccess)
  getPlaylistSuccess(
    {patchState, getState}:StateContext<PlaylistStateModel>,
    action:GetPlaylistSuccess
  ){
    const { payload:playlist, status, message}=action;
    const {playlists: statePlaylists}=getState();

    let playlists:Playlist[]=[];
    if(statePlaylists && statePlaylists.some(playlist$=>playlist$.id===playlist.id)){
      playlists=statePlaylists.map(playlist$=>{
        if(playlist$.id===playlist.id){
          playlist$={
            ...playlist
          }
        }
        return playlist$;
      });
    }else{
      playlists.push(playlist);
    }
    patchState({playlists, loading:false, message, status});
  }

  @Action(GetPlaylistError)
  getPlaylistError(
    {patchState}:StateContext<PlaylistStateModel>,
    action: GetPlaylistError
  ){
    const { message, status}=action;
    patchState({
      loading:false, status, message
    })
  }

  @Action(GetXMLOutput)
  getXMLOutput(
    ctx:StateContext<PlaylistStateModel>,
    action:GetXMLOutput
  ){
    const { playlistID, name }=action;
    this.playlistService.getPlaylistXML(playlistID, name)
  }

  @Action(GetPlaylistContent)
  getPlaylistContent(
    { patchState }:StateContext<PlaylistStateModel>,
    action:GetPlaylistContent
  ){
    const { playlistID }=action;
    patchState({
      loading:true,
      status:null,
      message:null
    });

    this.playlistService.getPlaylistContent(playlistID);
  }

  @Action(GetPlaylistContentSuccess)
  getPlaylistContentSuccess(
    { patchState }:StateContext<PlaylistStateModel>,
    action:GetPlaylistContentSuccess
  ){
    const { payload, status, message }=action;
    const playlistContent=this.sortPlaylistContent(payload);
    patchState({
      loading:false,
      playlistContent,
      message,
      status
    });
  }

  @Action(GetPlaylistContentError)
  getPlaylistContentError(
    { patchState }:StateContext<PlaylistStateModel>,
    action:GetPlaylistContentError
  ){
    const { message, status }=action;
    patchState({
      loading:false,
      message,
      status
    })
  }

  @Action(SavePlaylist)
  savePlaylist(
    {patchState}:StateContext<PlaylistStateModel>,
    action: SavePlaylist
  ){
    const{playlist}=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });
    this.playlistService.savePlaylist(playlist);
  }

  @Action(SavePlaylistSuccess)
  savePlaylistSuccess(
    {patchState, getState}:StateContext<PlaylistStateModel>,
    action:SavePlaylistSuccess
  ){
    const { playlists:statePlaylist }=getState();
    const { payload, status, message }=action;
    let playlists:Playlist[];

    if(statePlaylist.some(playlist=>playlist.id==payload.id)){
      playlists=statePlaylist.map(playlist=>{
        if(playlist.id===payload.id){
          playlist={
            ...payload
          };
        }
        return playlist;
      });
    }else{
      //playlist=[...statePlaylist, payload]
    }

    patchState({
      playlists,
      loading:false,
      message,
      status
    });
  }

  @Action(SavePlaylistError)
  savePlayListError(
    {patchState}:StateContext<PlaylistStateModel>,
    action:SavePlaylistError
  ){
    const { message, status }=action;

    patchState({
      loading:true,
      message,
      status
    });
  }

  @Action(DeletePlaylist)
  deletePlaylist(
    {patchState}:StateContext<PlaylistStateModel>,
    action:DeletePlaylist
  ){
    const { playlistID }=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });
    this.playlistService.deletePlaylist(playlistID);
  }

  @Action(DeletePlaylistSuccess)
  deleteFileSuccess(
    {patchState, getState}:StateContext<PlaylistStateModel>,
    action:DeletePlaylistSuccess
  ){
    const { payload:id, status }=action;
    const { playlists: statePlaylist, message}=getState();
    const playlists=statePlaylist.filter(playlist=>playlist.id!==id);

    patchState({
      playlists,
      loading:false,
      status,
      message
    });
  }

  @Action(DeletePlaylistError)
  deletePlaylistError(
    {patchState}:StateContext<PlaylistStateModel>,
    action:DeletePlaylistError
  ){
    const {message, status}=action;
    patchState({
      loading:false,
      message,
      status
    });
  }

  @Action(TogglePlaylistApproveStatus)
  togglePlaylistApproveStatus(
    {patchState}:StateContext<PlaylistStateModel>,
    action:TogglePlaylistApproveStatus
  ){
    const { playlistIDs, approveStatus}=action;

    patchState({
      loading:true,
      status:null,
      message: null
    });

    this.playlistService.toogleApproveStatus(playlistIDs, approveStatus);
  }

  @Action(TogglePlaylistApproveStatusSuccess)
  togglePlaylistApproveStatusSuccess(
    {patchState, getState}:StateContext<PlaylistStateModel>,
    action: TogglePlaylistApproveStatusSuccess
  ){
    const { playlists: statePlaylist }=getState();
    const { payload: ids, status, approveStatus, message }=action;
    const playlists=statePlaylist.map(playlist=>{
      if(ids.some(id=>playlist.id===id)){
        playlist={
          ...playlist,
          approval: approveStatus?1:0
        };
      }
      return playlist;
    });

    patchState({
      loading:false,
      message,
      status,
      playlists
    });
  }

  @Action(TogglePlaylistApproveStatusError)
  togglePlaylistApproveStatusError(
    { patchState }:StateContext<PlaylistStateModel>,
    action:TogglePlaylistApproveStatusError
  ){
    const{message, status}=action;

    patchState({
      loading:false,
      status:null,
      message:null
    });
  }

  @Action(TogglePlaylistMuteStatus)
  togglePlaylistMuteStatus(
    { patchState }: StateContext<PlaylistStateModel>,
    action: TogglePlaylistMuteStatus
  ) {
    const { playlistID, muteStatus } = action;

    patchState({
      loading: true,
      status: null,
      message: null
    });

    this.playlistService.toogleMuteStatus(playlistID, muteStatus);
  }

  @Action(TogglePlaylistMuteStatusSuccess)
  togglePlaylistMuteStatusSuccess(
    { patchState, getState }: StateContext<PlaylistStateModel>,
    action: TogglePlaylistMuteStatusSuccess
  ) {
    const { playlists: statePlaylists } = getState();
    const { playlistID, status, muteStatus, message } = action;
    const playlists = statePlaylists.map(playlist => {
      if (playlist.id === playlistID) {
        playlist = {
          ...playlist,
          mute: muteStatus
        };
      }
      return playlist;
    });

    patchState({
      loading: false,
      message,
      status,
      playlists
    });
  }

  @Action(TogglePlaylistMuteStatusError)
  togglePlaylistMuteStatusError(
    { patchState }: StateContext<PlaylistStateModel>,
    action: TogglePlaylistMuteStatusError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(DeletePlaylistContent)
  deletePlaylistContent(
    { patchState }:StateContext<PlaylistStateModel>,
    action: DeletePlaylistContent
  ){
    const { playlistID, contentID }=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });

    this.playlistService.deletePlaylistContent(playlistID, contentID);
  }

  @Action(DeletePlaylistContentSuccess)
  DeletePlaylistContentSuccess(
    {patchState, getState}:StateContext<PlaylistStateModel>,
    action:DeletePlaylistContentSuccess
  ){
    const { contentID, message, status }=action;
    const { playlistContent: content }=getState();
    const playlistContent=content.filter(c=>c.fileId!==contentID);

    patchState({
      loading:false,
      message,
      status,
      playlistContent
    });
  }

  @Action(DeletePlaylistContentError)
  DeletePlaylistContentError(
    {patchState}:StateContext<PlaylistStateModel>,
    action:DeletePlaylistContentError
  ){
    const { message, status }=action;
    patchState({
      loading:false,
      message,
      status
    });
  }


  sortPlaylistContent(content: PlaylistContent[]):PlaylistContent[]{
    return content.sort((playlistA, playlistB)=>{
      return playlistA.orderNum - playlistB.orderNum;
    });
  }

  @Action(RearrangePlaylistContent)
  rearrangePlaylistContent(
    { patchState }: StateContext<PlaylistStateModel>,
    action: RearrangePlaylistContent
  ) {
    const { playlistID, playlistContent } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.playlistService.rearrangePlaylistContent(playlistID, playlistContent);
  }

  @Action(RearrangePlaylistContentSuccess)
  rearrangePlaylistContentSuccess(
    { patchState }: StateContext<PlaylistStateModel>,
    action: RearrangePlaylistContentSuccess
  ) {
    const { payload, message, status } = action;
    patchState({
      loading: false,
      message,
      status,
      playlistContent: payload
    });
  }

  @Action(RearrangePlaylistContentError)
  rearrangePlaylistContentError(
    { patchState }: StateContext<PlaylistStateModel>,
    action: RearrangePlaylistContentError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

}
