import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { GuidelinesStateModel } from '@shared/types';
import {
  GetGuidelines,
  GetGuidelinesSuccess,
  GetGuidelinesError
} from './guidelines.actions';
import { PublicPortalService } from '@shared/services/api/public-portal/public-portal.service';

@State<GuidelinesStateModel>({
  name: 'guidelines',
  defaults: {
    loading: null,
    message: null,
    status: null,
    id: null,
    guidelines: null
  }
})
@Injectable()
export class GuidelinesState {
  constructor(private publicPortalService: PublicPortalService) { }

  @Action(GetGuidelines)
  getGuidelines(
    { patchState, getState }: StateContext<GuidelinesStateModel>,
    action: GetGuidelines
  ) {
    const { portalID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.publicPortalService.getGuidelines(portalID);
  }

  @Action(GetGuidelinesSuccess)
  getGuidelinesSuccess(
    { setState }: StateContext<GuidelinesStateModel>,
    action: GetGuidelinesSuccess
  ) {
    const { payload, message, status } = action;

    const { id, guideLineContent: guidelines } = payload;

    setState({
      loading: false,
      message,
      status,
      id,
      guidelines
    });
  }

  @Action(GetGuidelinesError)
  getGuidelinesError(
    { patchState }: StateContext<GuidelinesStateModel>,
    action: GetGuidelinesError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }
}
