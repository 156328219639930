import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlideShowADComponent } from './slide-show-ad.component';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FeedPlayerModule } from '@shared/modules/feed-player/feed-player.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SafePipe } from './safe-pipe';
import { YouTubePlayerModule } from '@angular/youtube-player';

export const SlideADRoutes:Routes=[{path:'', component:SlideShowADComponent}]

@NgModule({
  declarations: [SlideShowADComponent, SafePipe],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    FeedPlayerModule,
    NgbModule,
    YouTubePlayerModule,
    RouterModule.forChild(SlideADRoutes),
  ],
  exports:[SlideShowADComponent]
})
export class SlideShowAdModule { }
