import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

import { ImagePlayerComponent } from './image-player/image-player.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { PagePlayerComponent } from './page-player/page-player.component';
import { MatButtonModule } from '@angular/material/button';
import { PortalEmbedPlayerComponent } from './portal-embed-player.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [
    ImagePlayerComponent,
    VideoPlayerComponent,
    YoutubePlayerComponent,
    PagePlayerComponent,
    PortalEmbedPlayerComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    IconModule
  ],
  exports: [
    ImagePlayerComponent,
    VideoPlayerComponent,
    YoutubePlayerComponent,
    PagePlayerComponent,
    PortalEmbedPlayerComponent
  ],
  providers: []
})
export class FeedPlayerModule { }
