import {
  ThemeColors,
  ThemeFeatures,
  Logo,
  LogoUpload,
  RevolutionSliderContent,
  LogoUpdate,
  VisualPortal,
  PortalMenuItem,
  PortalMenuItems
} from '@shared/types';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

export class GetVisualPortal {
  static readonly type = 'GetVisualPortal';
  constructor(public customerID: number) {}
}

export class GetVisualPortalSuccess {
  static readonly type = 'GetVisualPortalSuccess';
  constructor(
    public payload: VisualPortal,
    public status = 'success',
    public message = 'Visual Portal Retrieved Successfully'
  ) {}
}

export class GetVisualPortalError {
  static readonly type = 'GetVisualPortalError';
  constructor(public message: string, public status = 'error') {}
}

export class SetLogoMeasures {
  static readonly type = 'SetLogoMeasures';
  constructor(
    public logoMasures: { logo_width: number; logo_height: number }
  ) {}
}

export class UploadLogo {
  static readonly type = 'UploadLogo';
  constructor(public customerID: number, public logo: File) {}
}

export class UploadLogoSuccess {
  static readonly type = 'UploadLogoSuccess';
  constructor(
    public payload: LogoUpload,
    public status = 'success',
    public message = 'Logo Uploaded Successfully'
  ) {}
}

export class UploadLogoError {
  static readonly type = 'UploadLogoError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdateLogo {
  static readonly type = 'UpdateLogo';
  constructor(public customerID: number, public body: LogoUpdate) {}
}

export class UpdateLogoSuccess {
  static readonly type = 'UpdateLogoSuccess';
  constructor(
    public payload: ThemeFeatures,
    public status = 'success',
    public message = 'Logo Updated Successfully'
  ) {}
}

export class UpdateLogoError {
  static readonly type = 'UpdateLogoError';
  constructor(public message: string, public status = 'error') {}
}

export class SetThemeColor {
  static readonly type = 'UpdateThemeColor';
  constructor(public colorKey: string, public color: string) {}
}

export class UpdateThemeColors {
  static readonly type = 'UpdateThemeColors';
  constructor(public customerID: number, public theme: ThemeColors) {}
}

export class UpdateThemeColorsSuccess {
  static readonly type = 'UpdateThemeColorsSuccess';
  constructor(
    public themeFeatures: ThemeFeatures,
    public status = 'success',
    public message = 'Theme Colors Updated Successfully'
  ) {}
}

export class UpdateThemeColorsError {
  static readonly type = 'UpdateThemeColorsError';
  constructor(public message: string, public status = 'error') {}
}

export class CreatePortalMenu {
  static readonly type = 'CreatePortalMenu';
  constructor(public menu: PortalMenuItem, public customerID: number) {}
}

export class CreatePortalMenuSuccess {
  static readonly type = 'CreatePortalMenuSuccess';
  constructor(
    public payload: PortalMenuItems,
    public status = 'success',
    public message = 'Portal Menu Created Successfully'
  ) {}
}

export class CreatePortalMenuError {
  static readonly type = 'CreatePortalMenuError';
  constructor(public message: string, public status = 'error') {}
}

export class UpdatePortalMenu {
  static readonly type = 'UpdatePortalMenu';
  constructor(
    public menu: PortalMenuItem | Array<PortalMenuItem>,
    public customerID: number,
    public parentChange: boolean
  ) {}
}

export class UpdatePortalMenuSuccess {
  static readonly type = 'UpdatePortalMenuSuccess';
  constructor(
    public payload: PortalMenuItems,
    public status = 'success',
    public message = 'Portal Menu Updated Successfully'
  ) {}
}

export class UpdatePortalMenuError {
  static readonly type = 'UpdatePortalMenuError';
  constructor(public message: string, public status = 'error') {}
}

export class DeletePortalMenu {
  static readonly type = 'DeletePortalMenu';
  constructor(
    public menuItem: PortalMenuItem,
    public menuIDs: Array<number>,
    public customerID: number
  ) {}
}

export class DeletePortalMenuSuccess {
  static readonly type = 'DeletePortalMenuSuccess';
  constructor(
    public menuItem: PortalMenuItem,
    public status = 'success',
    public message = 'Portal Menu Deleted Successfully'
  ) {}
}

export class DeletePortalMenuError {
  static readonly type = 'DeletePortalMenuError';
  constructor(public message: string, public status = 'error') {}
}

export class RearrangeMenu {
  static readonly type = 'RearrangeMenu';
  constructor(
    public event: CdkDragDrop<any>,
    public menus: Array<PortalMenuItem>,
    public customerID: number
  ) {}
}

export class CreateSliderItem {
  static readonly type = 'CreateSliderItem';
  constructor(public sliderContent: RevolutionSliderContent) {}
}

export class CreateSliderItemSuccess {
  static readonly type = 'CreateSliderItemSuccess';
  constructor(
    public payload: RevolutionSliderContent,
    public message: string,
    public status: string
  ) {}
}

export class CreateSliderItemError {
  static readonly type = 'CreateSliderItemError';
  constructor(public message: string, public status: string) {}
}

export class DeleteSliderItem {
  static readonly type = 'DeleteSliderItem';
  constructor(public sliderID: number) {}
}

export class DeleteSliderItemSuccess {
  static readonly type = 'DeleteSliderItemSuccess';
  constructor(public message: string, public status: string) {}
}

export class DeleteSliderItemError {
  static readonly type = 'DeleteSliderItemError';
  constructor(public message: string, public status: string) {}
}
