<div [fxLayout]="orientation === mobileLandscape ? 'row' : 'column'">
  <img
    role="img"
    [src]="logo.logo_Url"
    [alt]="universityName"
    [fxFlex.gt-md]="height"
    [fxFlex]="orientation === mobileLandscape ? 'auto' : '50px'"
    [class.mobile-landscape]="orientation === mobileLandscape"
    (load)="loaded.next(true)"
  />
</div>
