import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeaturesService } from '@shared/services/features/features.service';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  portalName: string;
  constructor(
    private featuresService: FeaturesService,
    private router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.featuresService.getCredential('token');
    const headers = {
      Authorization: `Bearer ${token}`
    };
    if (environment.production) {
      request = request.clone({
        url: request.url.replace('http://', 'https://')
      });
      if (!request.url.includes('user/portals/user/authenticate') && token) {
        request = request.clone({
          setHeaders: headers
        });
      }
    } else {
      if (!request.url.includes('8001/portals/user/authenticate') && token) {
        request = request.clone({
          setHeaders: headers
        });
      }
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.portalName = this.featuresService.getCredential(
                'portalName'
              );
              this.featuresService.logOut();
              this.featuresService.logIn();
            } else if (err.status === 403) {
              this.featuresService.logOut();
              // this.featuresService.logIn();
              this.router.navigate(['/forbidden']);
            }
             else {
            //   if (!err.url.includes("gethtml?")) {
            //     this.portalName = this.featuresService.getCredential(
            //       'portalName'
            //     );

                 this.featuresService.logOut();
                 this.featuresService.logIn();
                 if (this.portalName) {
                   this.router.navigate([`/local-portal/${this.portalName}/login`]);
                 }
            //   }
             }
          }
        }
      )
    );
  }
}
