<div
  fxLayout="row"
  fxFlex="100%"
  class="slider-content"
  fxLayoutAlign="space-between center"
  fxLayoutAlign.gt-md="space-around center"
>
  <div fxLayout="row">
    <h2
      class="mat-h2"
      (focus)="focused.emit(true)"
      (blur)="focused.emit(false)"
    >
      {{ message }}
    </h2>
  </div>
  <a
    *ngIf="href"
    [href]="href"
    target="_blank"
    (focus)="focused.emit(true)"
    (blur)="focused.emit(false)"
    class="focusable"
  >
    <img
      [src]="url"
      [alt]="isFixAlt(message) ? fixAlt : 'message'"
      [style.minWidth.px]="width"
      [style.height.px]="height"
    />
  </a>
  <img
    *ngIf="!href"
    [src]="url"
    [style.minWidth.px]="width"
    [style.height.px]="height"
    [alt]="message"
    (focus)="focused.emit(true)"
    (blur)="focused.emit(false)"
  />
</div>
