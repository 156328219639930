import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { Playlist, PlaylistContent, PlaylistCreation } from "@shared/modules/table/types";
import { DeletePlaylistContentError, DeletePlaylistContentSuccess, DeletePlaylistError, DeletePlaylistSuccess, GetPlaylistContentError, GetPlaylistContentSuccess, GetPlaylistError, GetPlaylistsError, GetPlaylistsSuccess, GetPlaylistSuccess, RearrangePlaylistContentError, RearrangePlaylistContentSuccess, SavePlaylistError, SavePlaylistSuccess, TogglePlaylistApproveStatusError, TogglePlaylistApproveStatusSuccess, TogglePlaylistMuteStatusError, TogglePlaylistMuteStatusSuccess } from "@shared/states/playlists/playlists.actions";
import { PlaylistResponse } from "@shared/types";
import { environment } from "environments/environment";
import { SharedHttpService } from "../shared-http/shared-http.service";

@Injectable({
  providedIn:'root'
})
export class PlaylistService{
  private API:string;
  private apiURL=environment.apiUrl;
  private apiService='playlist';
  private port='8006';

  constructor(private httpService:SharedHttpService, private store: Store){
    this.API=environment.production
      ? this.apiURL.replace('{service}', this.apiService)
      : this.apiURL.replace('{port}', this.port);
  }

  getPlaylistsAll(custumerID: number, formID: number, body:any){
    const path=`${this.API}/playlist/get/university/${custumerID}/form/${formID}`;
    this.httpService.post(path, body).subscribe(
      (res:PlaylistResponse)=>{
        this.store.dispatch(new GetPlaylistsSuccess(res));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new GetPlaylistsError(message));
      }
    );
  }

  getPlaylist(playlistID: number){
    const path = `${this.API}/playlist/find/${playlistID}`;
    this.httpService.get(path).subscribe(
      (res:Playlist)=>{
        this.store.dispatch(new GetPlaylistSuccess(res));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new GetPlaylistError(message));
      }
    );
  }

  getPlaylistContentNonSSO(playlistID: number): void {
    const path = `${this.API}/portals/playlist/getPlaylistContent/${playlistID}`;

    this.httpService.get(path).subscribe(
      (res: Array<PlaylistContent>) => {
        this.store.dispatch(new GetPlaylistContentSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new GetPlaylistContentError(message));
      }
    );
  }

  getPlaylistNonSSO(playlistID: number): void {
    const path = `${this.API}/portals/playlist/find/${playlistID}`;

    this.httpService.get(path).subscribe(
      (res: Playlist) => {
        this.store.dispatch(new GetPlaylistSuccess(res));
      },
      err => {
        const { message }: any =
          err.error || 'Unexpected error, pleaser try again';
        this.store.dispatch(new GetPlaylistError(message));
      }
    );
  }

  savePlaylist(playlist: PlaylistCreation){
    const path = `${this.API}/playlist/create`;
    this.httpService.post(path, playlist).subscribe(
      (res)=>{
        this.store.dispatch(new SavePlaylistSuccess(res));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new SavePlaylistError(message));
      }
    );
  }

  deletePlaylist(playlistID: number){
    const path = `${this.API}/playlist/${playlistID}/remove`;
    this.httpService.delete(path).subscribe(
      ()=>{
        this.store.dispatch(new DeletePlaylistSuccess(playlistID));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new DeletePlaylistError(message));
      }
    );
  }

  getPlaylistXML(playlistID: number, name:string){
    const path = `${this.API}/portals/playlist/getPlaylistContent/xml/${playlistID}`;
    window.open(path, '_blank');
  }

  getPlaylistContent(playlistID: number){
    const path = `${this.API}/playlist/getPlaylistContent/${playlistID}`;
    this.httpService.get(path).subscribe(
      (res:PlaylistContent[])=>{
        this.store.dispatch(new GetPlaylistContentSuccess(res));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new GetPlaylistContentError(message));
      }
    );
  }

  toogleApproveStatus(playlistIDs: number[], approveStatus: boolean){
    const path = `${this.API}/playlist/approve/${approveStatus}`;
    this.httpService.put(path, playlistIDs).subscribe(
      (res:boolean)=>{
        this.store.dispatch(new TogglePlaylistApproveStatusSuccess(playlistIDs, approveStatus));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new TogglePlaylistApproveStatusError(message));
      }
    );
  }

  toogleMuteStatus(playlistID: number, muteStatus: boolean){
    const path = `${this.API}/playlist/${playlistID}/mute/${muteStatus}`;
    this.httpService.put(path, null).subscribe(
      (res:boolean)=>{
        this.store.dispatch(new TogglePlaylistMuteStatusSuccess(playlistID, muteStatus));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new TogglePlaylistMuteStatusError(message));
      }
    );
  }

  deletePlaylistContent(playlistID: number, contentID: number){
    const path = `${this.API}/playlist/${playlistID}/remove/item/${contentID}`;
    this.httpService.put(path, null).subscribe(
      ()=>{
        const message='Item Deleted Successfully'
        this.store.dispatch(new DeletePlaylistContentSuccess(contentID, message));
      },
      err=>{
        const {message}:any=err.error || 'Unexpected error, please try again';
        this.store.dispatch(new DeletePlaylistContentError(message));
      }
    );
  }

  rearrangePlaylistContent(
    playlistID: number,
    playlistContent: Array<PlaylistContent>
  ): void {
    const path = `${this.API}/playlist/update/fileOrder/${playlistID}`;
    const content = playlistContent.map(c => ({
      fileId: c.fileId,
      orderNum: c.orderNum
    }));
    this.httpService.put(path, content).subscribe(
      (res: any) => {
        this.store.dispatch(
          new RearrangePlaylistContentSuccess(playlistContent)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new RearrangePlaylistContentError(message));
      }
    );
  }

}
