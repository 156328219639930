import { ValidatorFn } from '@angular/forms';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Playlist } from './modules/table/types';

export interface Credentials {
  token: string;
  userId: string;
  role: string;
}

export interface LoginResponse {
  authenticationToken: string;
  userProfile: {
    role: string;
    userId: string;
  };
}

export interface AsyncModel {
  loading: boolean;
  status: string;
  message: string;
}

export interface HttpOptions {
  headers?:
  | HttpHeaders
  | {
    [header: string]: string | string[];
  };
  observe?: any;
  params?:
  | HttpParams
  | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
}

export interface FileUpload {
  temporalUrl: string;
  message: string;
}

export interface University {
  id?: number;
  name?: string;
  domain?: string;
  affiliationDate?: string;
  capacity?: number;
  quota?: number;
  ssoIntegrated?: boolean;
  publicPortalDisabled:boolean;
}

export interface Universities {
  universities: Array<University>;
  message: string;
}

export interface PublicPortalStateModel extends AsyncModel {
  portalID: number;
  menuItems: Array<MenuItem>;
  slider: Array<RevolutionSliderContent>;
  logo: Logo;
  theme: ThemeColors;
  networks: Array<Option>;
  affiliations: Option[];
  tags:Option[];
  panels:Option[];
  adTypes:Option[];
}

export interface PublicPortal {
  id: number;
  menuItems: Array<MenuItem>;
  status: boolean;
  themeFeatures: ThemeFeatures;
  universityId: number;
}

export interface MenuItem {
  id: number;
  menus: Array<MenuItem>;
  name: string;
  order: number;
  parentId: number;
  type: MenuItemType;
  value: string;
}

export enum MenuItemType {
  DROPDOWN = 'dropdown',
  LINK = 'link'
}

export interface Logo {
  logo_Url: string;
  logo_height: number;
  logo_width: number;
}

export interface RevolutionSliderContent {
  background_color: string;
  height: number;
  id: number;
  message: string;
  portalId: number;
  url: string;
  width: number;
  href: string;
}

export interface ThemeFeatures extends Logo {
  id: number;
  sliderContentId: number;
  themeFeatures: ThemeColors;
  revolutionSliderContent: Array<RevolutionSliderContent>;
}

export interface ThemeColors {
  id: number;
  activeMenuBgColor: string;
  darkHeadingColor: string;
  footerBgColor: string;
  headerBgColor: string;
  liteHeadingColor: string;
  menuBgColor: string;
  menuTextColor: string;
  revolutionSliderBorderColor: string;
}

export interface GuidelinesStateModel extends AsyncModel {
  id: number;
  guidelines: string;
}

export interface Guidelines {
  guideLineContent: string;
  id: number;
  publicPortalId: number;
}

export interface CreatAndAddStateModel extends AsyncModel {
  adContent: Array<Section>;
}

export interface AdContent {
  message: string;
  portalId: number;
  sections: Array<Section>;
}

export interface Section {
  id: number;
  items: Array<Item>;
  name: string;
  positionOrder: number;
  section_multimedia_url: string;
}

export interface Item {
  body: string;
  id: number;
  image_url: string;
  item_position: number;
  portalId: number;
  sectionId: number;
  subtitle: string;
  screen_coordinates?: string;
}

export enum MAPS_ZOOM {
  WORLD = 1,
  CONTINENT = 4,
  CITY = 10,
  STREET = 15,
  BUILDING = 20
}

export enum ViewMode {
  GRID,
  SLIDESHOW
}

export interface PlaylistResponse {
  playlists: Array<Playlist>;
  count: number;
}

export interface Mark {
  text: string;
  location: { lat: number; lng: number };
}

export interface HomeStateModel extends AsyncModel {
  entries: Array<Entry>;
  count: number;
  viewmode: ViewMode,
  orientation: Orientation,
  filters: Filters,
  adtype_visible:boolean,
  affiliation_visible:boolean,
  network_visible:boolean,
  panel_visible:boolean,
  tags_visible:boolean
}

export interface SocialHUB {
  entries: Array<Entry>;
  message: string;
  count: 10;
  adtype_visible:boolean,
  affiliation_visible:boolean,
  network_visible:boolean,
  panel_visible:boolean,
  tags_visible:boolean
}

export interface Entry {
  universityName: string;
  affiliation: Affiliation;
  //tag:Tag;
  adType:AdType;
  approved_Status: number;
  cdn_Link: string;
  cdnuploadStatus: number;
  contents_Files_Tag: Array<Tag>;
  description: string;
  duration: number;
  end_Date: string;
  file_Name: string;
  file_Type: number;
  form_ID: number;
  id: number;
  networks: Array<Network>;
  panel: Panel;
  playListId: number;
  post_Transcode_File: string;
  pre_Transcode_File: string;
  starred_Status: number;
  start_Date: string;
  submission_Date: string;
  submission_name: string;
  submitter: Submitter;
  transcodeStatus: number;
  group_name: string;
  //admin module
  localform:boolean;
}

export interface File {
  id: number;
  universityName?: string;
  playListId: string;
  submitter: Submitter;
  networks: Array<Network>
  group_name: string;
  affiliation: Affiliation;
  panel: Panel;
  adType: AdType;
  duration: number;
  file_Type: number;
  form_ID: number;
  submission_name: string;
  submission_Date: string;
  file_Name: string;
  approved_Status: number;
  cdn_Link: string;
  cdnuploadStatus: 0;
  contents_Files_Tag: Array<string>;
  end_Date: string;
  post_Transcode_File: string;
  pre_Transcode_File: string;
  starred_Status: number;
  start_Date: string;
  transcodeStatus: number;
  description: string;
}

export interface Affiliation {
  active: boolean;
  formId: number;
  id: number;
  preselected: boolean;
  type: string;
}

export interface Network {
  active: boolean;
  form_id: number;
  id: number;
  name: string;
  preSelected: boolean;
  selected: boolean;
  type:string,
  position:number;
  //use in admin social hub
  network_id:number;
  network_netName:string;
  network_name:string;
}

export interface AdType {
  active: boolean;
  form_id: number;
  id: number;
  name: string;
  preSelected: boolean;
}

export interface Tag {
  id: number;
  tagName: string;
}

export interface Panel {
  active: boolean;
  form_id: number;
  id: number;
  name: string;
  preSelected: boolean;
  selected: boolean;
}


export interface Submitter {
  affiliation: Affiliation;
  email: string;
  group_Name: string;
  id: number;
  subm_name: string;
  university_ID: number;
  //admin social hub
  submitters_id:number;
  submitters_subm_name:string;
  submitters_email:string;
}

export enum FILE_TYPE {
  Image = 'image',
  Video = 'video',
  YouTube = 'youtube',
  Page = 'page',
  Unknown = 'unknown'
}

export interface SubmissionForm {
  id: number;
  type: string;
  name: string;
  sections: Array<SubmissionFormSection>;
}

export interface SubmissionForm {
  form_id: number;
  form_name:string;
  form_type: number;
  form_universityId: number;
  form_isActive: boolean;
  network: any;
}

export interface SubmissionFormSection {
  name: string;
  fields: Array<SubmissionFormField>;
}

export interface SubmissionFormField {
  id: number;
  fieldPosition: number;
  field_type: number;
  icon: string;
  name: string;
  value: any;
  isVisible: boolean;
  options?: Array<FieldOption>;
  conditional?: Conditional;
  position_under?: number;
  validators?: Array<ValidatorFn>;
  isRequired?: boolean;
}

export interface SubmissionFormFieldOpt {
  id?: number;
  active: boolean;
  preSelected: boolean;
  display_value: string;
  positionOrder: number;
}

export interface SubmissionFormStateModel extends AsyncModel {
  submissionForms:SubmissionForm[];
  submissionForm: SubmissionForm;
  localSubmissionForm: SubmissionForm;
  localForm: LocalForm;
  affiliations: Option[];
  tags:Option[];
  panels:Option[];
  adTypes:Option[];
  formPanels:Panel[];
  formNetworks:Network[];
  networksAdmin:Network[];
  socialHubAdmin:SocialHUB;
  networkRelationPanels:NetworkRelationPanel[];
}

export interface NetworkRelationPanel{
  id:number;
  name:string;
  type:string;
  panels: PanelByNetwork[];
}

export interface PanelByNetwork{
  panelId:number;
  panelName: string;
  panelIsActive:boolean;
  panelIsPreSelected: boolean;
  panelIsSelected: boolean;
  panelPositionOrder:number;
}

export interface Conditional {
  fieldId: number;
  optionId: number;
  visible: boolean;
}

export interface FieldOption {
  id: number;
  active: boolean;
  display_value: string;
  position_order: number;
  preSelected: boolean;
  selected: boolean;
}

export interface LogoUpload {
  fileName: string;
  fileUrl: string;
  message: string;
}

export interface FilesResponse {
  entries: Array<File>;
  count: number;
  message: string;
  panel_visible:boolean;
  network_visible:boolean;
  adtype_visible:boolean;
  affiliation_visible:boolean;
  tags_visible:boolean;
}

export interface LogoUpdate {
  logo_url: string;
  width?: number;
  height?: number;
}

export interface VisualPortal {
  id: number;
  universityId: number;
  themeFeatures: ThemeFeatures;
  menuItems: Array<PortalMenuItem>;
  status: boolean;
}

export interface PortalMenuItem {
  id?: number;
  name: string;
  parentId?: number;
  type: string;
  value?: string;
  order?: number;
  menus: Array<PortalMenuItem>;
}

export interface PortalMenuItems {
  portalMenu: Array<PortalMenuItem>;
  message: string;
}

export interface EntriesStateModel extends DefaultAsyncModel {
  entries: Array<Entrie>;
  columns: Array<EntryColumn>;
  count: number;
}

interface DefaultAsyncModel {
  message: string;
  loading: boolean;
  status: string;
}

export interface CHIAFile {
  Submission_Date: string;
  Start_Date: string;
  End_Date: string;
  Form_ID: number;
  Submitter_ID: number;
  TranscodeStatus: number;
  CDNUploadStatus: number;
  Post_Transcode_File: string;
  Pre_Transcode_File: string;
  CDN_Link: string;
  Duration: number;
  Contents_Files_Tag: string | string[];
  Description: string;
  Approved_Status: number;
  Starred_Status: number;
  File_Type: number;
  Networks: Array<number>;
  Panel: number;
  url: string;
}

export interface Option {
  label: string;
  value: any;
  hint?: string;
  icon?: string;
  customIcon?: boolean;
  disabled?: boolean;
}

export interface EntrySubmitter {
  submitters_subm_name: string;
  submitters_email: string;
  affiliation_id: number;
  submitters_Group_Name: string;
}

export interface EntriesBodyParams {
  form: number;
  orderField: string;
  order: string;
  start: number;
  records: number;
  searchField: string;
  searchType: string;
  searchValue: string;
}

export interface Entrie {
  files_id: number;
  files_File_name: string;
  files_file_Type: number;
  filesType_type: string;
  files_start_Date: string;
  files_end_Date: string;
  files_group_name:string;
  panel_id: number;
  panel_name: string;
  files_adtype_id: number;
  files_adtype_name: string;
  submitter_id: number;
  submitter_subm_name: string;
  submitter_Group_Name: string;
  submitter_email: string;
  affiliation_id: number;
  affiliation_type: string;
  files_description: string;
  files_duration: number;
  files_submission_Date: string;
  files_pre_Transcode_File: string;
  files_post_Transcode_File: string;
  files_cdn_Link: string;
  files_Form_ID: number;
  files_contents_Files_Tag: Array<string>;
  files_approved_Status: number;
  files_transcodeStatus: number;
  files_starred_Status: number;
  files_cdnuploadStatus: number;
  files_submission_name: string;
  files_Affiliation_ID: number;
  networks: Array<{ network_id: number; network_netName: string }>;
}

export interface EntryColumn {
  formId: number;
  position: number;
  columnName: string;
  isVisible: boolean;
}

export interface ManageStateModel extends AsyncModel {
  entries: Array<Entrie>;
  panels: Array<Panel>;
  columns: Array<EntryColumn>;
  count: number;
}
export interface EntriesResponse {
  entries: Array<Entrie>;
  count: number;
  message: string;
}

export enum FieldType {
  TextField = 1,
  Select = 2,
  Checkbox = 3,
  Radio = 4,
  Date = 5
}

export interface Option {
  label: string;
  value: any;
  hint?: string;
  icon?: string;
  customIcon?: boolean;
}

export interface LocalForm {
  id: number;
  name: string;
  url: string;
  status: string;
  university: number;
}

export enum OrderBy {
  ASC,
  DESC,
};

export enum SortBy {
  START_DATE,
  END_DATE,
  SUBMISSION_DATE,
  NAME
};

export enum Orientation {
  PORTRAIT,
  LANDSCAPE,
  LANDSCAPE_MOBILE,
}

export interface Filters {
  orderBy: Option[];
  filterByNetwork: Option[];
  filterByAffiliation: Option[];
  filterByTags:Option[];
  filterByPanels:Option[];
  filterByAdTypes:Option[];
  sortBy: Option[];
  config: FiltersConfig;
}

export interface FiltersConfig { orderBy: OrderBy, filterBy: any, sortBy: SortBy }

export interface EntriesAdminStateModel extends DefaultAsyncModel{
  entries:Array<File>;
  count:number;
  panel_visible:boolean;
  network_visible: boolean;
  adtype_visible: boolean;
  affiliation_visible: boolean;
  tags_visible: boolean;
}

export interface EntriesAdminStateModel2 extends DefaultAsyncModel{
  entries:Array<File>;
  columns:Array<EntryColumn>;
  count:number;
}

export interface VisualStateModel extends DefaultAsyncModel {
  id: number;
  customerID: number;
  logo: Logo;
  theme: Array<PortalTheme>;
  menus: Array<PortalMenuItem>;
  revolutionSlider: Array<RevolutionSliderContent>;
  parentChange: boolean;
}

export interface PortalTheme {
  id: string;
  label: string;
  color: string;
  description: string;
}

export interface PortalMenuItem {
  id?: number;
  name: string;
  parentId?: number;
  type: string;
  value?: string;
  order?: number;
  menus: Array<PortalMenuItem>;
}

export interface PortalMenuItems {
  portalMenu: Array<PortalMenuItem>;
  message: string;
}

