import { Universities } from '@shared/types';

export class GetUniversityByURL {
  static readonly type = 'GetUniversityByURL';
  constructor(public domain: string) {}
}

export class GetUniversitySuccess {
  static readonly type = 'GetUniversitySuccess';
  constructor(public payload: Universities) {}
}

export class GetUniversityError {
  static readonly type = 'GetUniversityError';
  constructor(public message: string, public status: string) {}
}
