export enum PlayerStatus {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
  PREVIEW = 6,
  ERROR = 7
}
