import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';

import { TableComponent } from './table.component';
import { CellComponent } from './cell/cell.component';
import { FloatingFieldsModule } from '../floating-field/floating-field.module';

@NgModule({
  declarations: [TableComponent, CellComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatMenuModule,
    MatCheckboxModule,
    DragDropModule,
    MatIconModule,
    FloatingFieldsModule,
    MatSortModule
  ],
  exports: [TableComponent]
})
export class TableModule {}
