import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { RevolutionSliderContent } from '@shared/types';
import {
  trigger,
  transition,
  animate,
  style,
  useAnimation,
  AnimationEvent
} from '@angular/animations';
import { FadeIn, FadeOut } from '@shared/animations';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'portal-revolution-slider',
  templateUrl: './revolution-slider.component.html',
  styleUrls: ['./revolution-slider.component.scss'],
  animations: [
    trigger('rightToLeft', [
      transition('void => inL', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('1s', style({ transform: 'translateX(0)' }))
      ]),
      transition('inL => void', [
        animate('1s', style({ transform: 'translateX(-100%)' }))
      ]),
      transition('void => inR', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('1s', style({ transform: 'translateX(0)' }))
      ]),
      transition('inR => void', [
        animate('1s', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('fadeCtrls', [
      transition(':enter', [
        useAnimation(FadeIn, { params: { time: '0.1s' } })
      ]),
      transition(':leave', [
        useAnimation(FadeOut, { params: { time: '0.1s' } })
      ])
    ])
  ]
})
export class RevolutionSliderComponent implements OnInit {
  @ViewChild('slider', { static: true }) slider: ElementRef<HTMLDivElement>;
  @Input() revolutionSlider: Array<RevolutionSliderContent>;
  index = 0;
  timer = 0;
  timerEnd = 3000;
  step = 1;
  interval: any;
  controls: boolean;
  animationTrigger: boolean;
  focused: boolean;
  mouseover: boolean;
  playing = true;
  animating = true;
  animationState = 'inL';
  constructor() {
    document.addEventListener('keyup', (e: KeyboardEvent) => {
      e.stopPropagation();
      this.focused = this.slider.nativeElement.contains(e.target as Node);
      if (e.keyCode === 9) {
        if (!this.focused) {
          this.sliderOut();
        }
      }
      if (this.focused && !this.animating) {
        if (e.keyCode === 37) {
          this.toLeft();
          // this.slider.nativeElement.focus();
        }
        if (e.keyCode === 39) {
          this.toRight();
          // this.slider.nativeElement.focus();
        }
      }
    });
  }

  ngOnInit() {
    if (this.revolutionSlider) {
      this.setTimer();
    }
  }

  sliderIn(): void {
    this.controls = true;
    if (this.playing) {
      this.playing = false;
      this.stopTimer();
    }
  }

  sliderOut(): void {
    if (!this.playing && !this.focused && !this.mouseover) {
      this.controls = false;
      this.playing = true;
      this.setTimer();
    }
  }

  setTimer(): void {
    if(this.revolutionSlider.length>1){
      this.interval = setInterval(() => {
        this.timer += this.step;
        if (this.timer >= this.timerEnd) {
          this.stopTimer();
          this.next();
        }
      }, this.step);
    }
     
  }

  stopTimer(): void {
    clearInterval(this.interval);
  }

  previous(): void {
    this.timer = 0;
    this.index =
      this.index - 1 >= 0 ? this.index - 1 : this.revolutionSlider.length - 1;
    this.animationTrigger = !this.animationTrigger;
    if (!this.controls) {
      this.setTimer();
    }
  }

  next(): void {
    this.timer = 0;
    this.animating = true;
    this.index =
      this.index + 1 <= this.revolutionSlider.length - 1 ? this.index + 1 : 0;
    this.animationTrigger = !this.animationTrigger;
    if (!this.controls) {
      this.setTimer();
    }
  }

  toLeft(): void {
    this.animationState = 'inR';
    this.animating = true;
    setTimeout(() => {
      this.previous();
    });
  }

  toRight(): void {
    this.animationState = 'inL';
    this.animating = true;
    setTimeout(() => {
      this.next();
    });
  }

  sliceByIndex(event: MatRadioChange): void {
    if (event.value > this.index) {
      this.toRight();
    } else {
      this.toLeft();
    }
  }

  animationDone(event: AnimationEvent): void {
    if (event.fromState === 'void') {
      this.animating = false;
      this.animationState = 'inL';
    }
  }
}
