import { Component, OnDestroy, Inject, HostListener, ElementRef } from '@angular/core';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { FeaturesService } from '@shared/services/features/features.service';
import { SubmissionFormState } from '@shared/states/submission-form/submission-form.state';
import {
  SubmissionFormStateModel,
  HomeStateModel,
  Entry,
  RevolutionSliderContent,
  PublicPortalStateModel,
  FILE_TYPE,
  ViewMode,
  Orientation,
  FiltersConfig,
  OrderBy,
  SortBy,
} from '@shared/types';
import {
  GetSocialHUB,
  GetSocialHUBError,
  SetFilterByNetworkOpts,
  SetFilterByAffiliationOpts,
  SetFilterByTagOpts,
  SetFilterByPanelOpts,
  SetFilterByAdTypeOpts
} from '@shared/states/home/home.actions';
import { HomeState } from '@shared/states/home/home.state';
import { PublicPortalState } from '@shared/states/public-portal/public-portal.state';
import { SlideshowWarpperComponent } from '@shared/modules/dialogs/slideshow-wrapper/slideshow-wrapper.component';
import { GetFormAdTypes, GetFormAdTypeSuccess, GetFormAffiliations, GetFormAffiliationsSuccess, GetFormPanel, GetFormPanelSuccess, GetFormTags, GetFormTagSuccess } from '@shared/states/submission-form/submission-form.actions';
import { GetFormNetworksPublic, GetFormNetworksPublicSuccess } from '@shared/states/public-portal/public-portal.actions';

@Component({
  selector: 'portal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {
  publicPortal$ = this.store.select<PublicPortalStateModel>(PublicPortalState);
  submissionForm$ = this.store.select<SubmissionFormStateModel>(SubmissionFormState);
  home$ = this.store.select<HomeStateModel>(HomeState);

  cols = 4;
  sizeScreen = 960;
  tileRatio = '2:1.2';
  tileSpacing = this.document.body.clientWidth > this.sizeScreen ? 5 : 2;
  revolutionSlider: Array<RevolutionSliderContent>;
  files: Array<Entry>;
  socialHUB: Array<Entry>;
  compareSocialHUB:Entry[]=[];
  loading: boolean;
  isIntegrate: boolean;
  error: boolean;
  viewmode: ViewMode;
  slideshowSize: number;
  orientation: Orientation;
  config: FiltersConfig;
  mobileLandscape = Orientation.LANDSCAPE_MOBILE;
  unsubscribe$ = new Subject();
  socialHUBCalled:boolean=false;
  affiliationsCalled:boolean=false;
  formTagsCalled:boolean=false;
  formPanelsCalled:boolean=false;
  formAdtypesCalled:boolean=false;
  filterCheck:boolean=false;
  isSameGroup:boolean=true;
  countGroup=0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private featuresService: FeaturesService,
    private store: Store,
    private actions$: Actions,
    private matDialog: MatDialog,
    private el: ElementRef
  ) {
    const page =  this.document.location.pathname;
    this.setCols(this.document.body.clientWidth);
    this.isIntegrate = this.document.location.pathname === '/integrate';
    this.featuresService.setCurrentPage(page);
    this.subscriptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(ev: Event) {
    this.setCols(this.document.body.clientWidth);
  }

  subscriptions(): void {
    this.submissionForm$.pipe(takeUntil(this.unsubscribe$)).subscribe(form$ => {
      const { submissionForm, affiliations, tags, panels, adTypes, loading } = form$;
      if (submissionForm && loading === false) {
        const { id } = submissionForm;
        if(!this.socialHUBCalled){
          this.socialHUBCalled=true;
          this.store.dispatch([new GetSocialHUB(id), new GetFormNetworksPublic(id)]);
        }
        if (!affiliations && !this.affiliationsCalled) {
          this.affiliationsCalled=true;
          this.store.dispatch(new GetFormAffiliations(id));
        }
        if(!tags && !this.formTagsCalled){
          this.formTagsCalled=true;
          this.store.dispatch(new GetFormTags(id));
        }
        if(!panels && !this.formPanelsCalled){
          this.formPanelsCalled=true;
          this.store.dispatch(new GetFormPanel(id));
        }
        if(!adTypes && !this.formAdtypesCalled){
          this.formAdtypesCalled=true;
          this.store.dispatch(new GetFormAdTypes(id));
        }
      }
    });
    this.home$.pipe(takeUntil(this.unsubscribe$)).subscribe(home$ => {
      const { entries, loading, viewmode, orientation, filters } = home$;
      this.loading = loading;
      this.viewmode = viewmode;
      this.orientation = orientation;
      this.config = filters.config;
      if (entries && loading === false) {
        this.files = this.featuresService.isMobile() || this.isIntegrate ? entries.filter(entry => this.featuresService.getFileType(entry.cdn_Link) !== FILE_TYPE.Page) : entries;
        this.socialHUB = this.files;
        this.slideshowSize = this.el.nativeElement.clientHeight;
        this.applyFilters();
      }
    });

    this.publicPortal$.pipe(takeUntil(this.unsubscribe$)).subscribe(portal$ => {
      const { loading, slider } = portal$;
      if (loading === false && slider) {
        this.revolutionSlider = slider;
      }
    });

    this.actions$
      .pipe(
        ofActionSuccessful(GetSocialHUBError, GetFormNetworksPublicSuccess, GetFormAffiliationsSuccess, GetFormTagSuccess, GetFormPanelSuccess, GetFormAdTypeSuccess),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(action => {
        if (action instanceof GetSocialHUBError) {
          this.error = true;
        }
        if (action instanceof GetFormNetworksPublicSuccess) {
          this.store.dispatch(new SetFilterByNetworkOpts(action.networks));
        }
        if (action instanceof GetFormAffiliationsSuccess) {
          this.store.dispatch(new SetFilterByAffiliationOpts(action.affiliations));
        }
        if(action instanceof GetFormTagSuccess){
          this.store.dispatch(new SetFilterByTagOpts(action.tags));
        }
        if(action instanceof GetFormPanelSuccess){
          this.store.dispatch(new SetFilterByPanelOpts(action.panels));
        }
        if(action instanceof GetFormAdTypeSuccess){
          this .store.dispatch(new SetFilterByAdTypeOpts(action.adTypes));
        }
      });
  }

  slideShow(selected: number) {
    this.matDialog.open(SlideshowWarpperComponent, {
      panelClass: ['not-padding', 'fullscreen'],
      closeOnNavigation: true,
      data: {
        entries: this.socialHUB,
        selected
      }
    });
  }

  setCols(width: number): void {
    if (width > 1000) {
      this.cols = 4;
    } else if (width > 700) {
      this.cols = 3;
    } else if (width > 500) {
      this.cols = 2
    } else {
      this.cols = 1;
    }
  }

  applyFilters(): void {
    const { sortBy, filterBy, orderBy } = this.config
    this.sort(sortBy, orderBy);
    this.filterBy(filterBy);
  }

  sort(value: SortBy, order: OrderBy): void {
    switch (value) {
      case SortBy.START_DATE:
        this.files = this.featuresService.sortByKey('start_Date', this.files, order);
        break;
      case SortBy.END_DATE:
        this.files = this.featuresService.sortByKey('end_Date', this.files, order);
        break;
      case SortBy.NAME:
        this.files = this.featuresService.sortByKey('submission_name', this.files, order);
        break;
      case SortBy.SUBMISSION_DATE:
        this.files = this.featuresService.sortByKey('submission_Date', this.files, order);
        break;
    }
    this.socialHUB = this.files;
    this.compareSocialHUB=this.files;
  }

  filterBy(value: Array<string>): void {
    let entriesFiltered = [];
    let panel=""; let network=""; let adtype=""; let tag=""; let affiliation="";
    let type:string;
    let val:string;
    if(value.length>0){
      value.forEach(element=>{
        if (typeof element === 'string') {
          [type, val] = (element as string).split('-');
        }
        type=element.substring(0,element.indexOf("-"));
        val=element.substring(element.indexOf("-")+1,element.length);
        switch (type) {
          case 'N':
            network=network+val+";";
            break;
          case 'AD':
            adtype=adtype+val+";";
              break;
          case 'A':
            affiliation=affiliation+val+";";
            break;
          case 'T':
            tag=tag+val+";";
              break;
          case 'P':
            panel=panel+val+";";
              break;

          default:
            this.filterCheck=false;
            break;
        }

      })
      panel=(panel || panel.length !== 0)?panel.substring(0,panel.length-1):null;
      network=(network || network.length !== 0)?network.substring(0,network.length-1):null;
      adtype=(adtype || adtype.length !== 0)?adtype.substring(0,adtype.length-1):null;
      tag=(tag || tag.length !== 0)?tag.substring(0,tag.length-1):null;
      affiliation=(affiliation || affiliation.length !== 0)?affiliation.substring(0,affiliation.length-1):null;
      this.compareSocialHUB.forEach(entry=>{
        if(this.filterEntries(entry,panel,network, adtype,tag,affiliation)){
          entriesFiltered.push(entry);
        }
      });
      this.socialHUB=entriesFiltered;
    }else{
      this.socialHUB=this.files;
    }
  }

  filterEntries(entry: Entry, panelF:string ,networkF:string, adtypeF:string,tagF:string,affiliationF:string): boolean {
    let panelFlag=false; let networkFlag=false; let adtypeFlag=false; let tagFlag=false; let affiliationFlag=false;
    panelFlag=(panelF==null)?true:false;
    networkFlag=(networkF==null)?true:false;
    adtypeFlag=(adtypeF==null)?true:false;
    tagFlag=(tagF==null)?true:false;
    affiliationFlag=(affiliationF==null)?true:false;
    if(panelF == null && adtypeF == null && tagF == null && affiliationF == null && networkF == null ) {
			return true;
		}
		if (panelF != null) {
			if(panelF.indexOf(";")) {
        panelF.split(";").forEach(singlePanel=>{
          if(entry.panel.name ===singlePanel) {
						panelFlag = true;
					}
        });
			}else {
				panelFlag = entry.panel.name ===panelF;
			}
    }
    if (tagF != null ) {
			if(tagF.indexOf(";")) {
				tagF.split(";").forEach(singleTag=>{
          entry.contents_Files_Tag.forEach(singleTagF=>{
            if(singleTagF.tagName===singleTag) {
              tagFlag = true;
            }
          });
				});
			}else {
        entry.contents_Files_Tag.forEach(singleTagF=>{
          if(singleTagF.tagName===tagF) {
            tagFlag = true;
          }
        });
			}
    }
		if (adtypeF != null) {
			if(adtypeF.indexOf(";")) {
				adtypeF.split(";").forEach(singleAd=>{
          if(entry.adType && entry.adType.name===singleAd) {
						adtypeFlag = true;
					}
				});
			}else {
				if(entry.adType.name===adtypeF) {
					adtypeFlag = true;
				}
			}
    }
		if (affiliationF != null) {
			if(affiliationF.indexOf(";")) {
        affiliationF.split(";").forEach(singleAff=>{
					if(entry.affiliation.type===singleAff) {
						affiliationFlag = true;
					}
				});
			}else {
        if(entry.affiliation.type===affiliationF) {
					 affiliationFlag = true;
				 }
			}
    }
    if (networkF != null ) {
			if(networkF.indexOf(";")) {
				networkF.split(";").forEach(singleNet=>{
          entry.networks.forEach(singleNetF=>{
            if(singleNetF.name===singleNet) {
              networkFlag = true;
            }
          });
				});
			}else {
        entry.networks.forEach(singleNetF=>{
          if(singleNetF.name===networkF) {
            networkFlag = true;
          }
        });
			}
    }
		if(panelFlag && networkFlag && adtypeFlag && tagFlag && affiliationFlag){
			return true;
		}else {
			return false;
		}
  }


  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.subscriptions
  }
}
