import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'portal-wrong',
  templateUrl: './wrong.component.html',
  styleUrls: ['./wrong.component.scss']
})
export class WrongComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
