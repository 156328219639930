import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LogoComponent],
  imports: [CommonModule, FlexLayoutModule],
  exports: [LogoComponent],
  entryComponents: [LogoComponent]
})
export class LogoModule {}
