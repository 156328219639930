import {
  Component,
  OnInit,
  Input,
  Inject
} from '@angular/core';
import { Entry, FILE_TYPE } from '@shared/types';
import { MatDialog } from '@angular/material/dialog';
import { PreviewContentComponent } from '@shared/modules/dialogs/preview-content/preview-content.component';
import { PlayerStatus } from '@shared/modules/feed-player/types';
import { FeaturesService } from '@shared/services/features/features.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'portal-hub-tile',
  templateUrl: './hub-tile.component.html',
  styleUrls: ['./hub-tile.component.scss']
})
export class HubTileComponent implements OnInit {
  @Input() entry: Entry;
  @Input() tileIndex: number;
  @Input() mode: 'player' | 'thumbnail' = 'player';
  @Input() autoplay = true;
  @Input() fileType: FILE_TYPE;
  PlayerStatus = PlayerStatus;
  FILE_TYPE = FILE_TYPE;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private matDialog: MatDialog,
    private featuresService: FeaturesService) { }

  ngOnInit() {
    this.fileType = this.featuresService.getFileType(this.entry.cdn_Link);
  }


  tileChange(event: PlayerStatus): void {
    if (event === PlayerStatus.PREVIEW) {
      if (this.fileType === FILE_TYPE.Page) {
        this.openPage();
      } else {
        this.previewContent();
      }
    }
  }

  previewContent(): void {
    const width = this.document.body.clientWidth > 900 ? '900px' : `${this.document.body.clientWidth - 20}px`;
    this.matDialog.open(PreviewContentComponent, {
      minWidth: this.fileType === FILE_TYPE.YouTube ? width : 'intial',
      maxWidth: width,
      panelClass: 'not-padding',
      ariaLabel: this.entry.description,
      data: {
        entry: this.entry,
        fileType: this.fileType
      }
    });
  }

  openPage(): void {
    const page =
      this.entry.cdn_Link.substring(0, 4).toLocaleLowerCase() === 'http'
        ? this.entry.cdn_Link
        : `http://${this.entry.cdn_Link}`;
    window.open(page, '_black');
    // window.open(this.entry.post_Transcode_File, '_black') THIS IS THE GOOD ONE
  }

  nonStreamMedia(): boolean {
    if (
      this.mode === 'thumbnail' ||
      this.fileType === this.FILE_TYPE.Video ||
      this.fileType === this.FILE_TYPE.YouTube
    ) {
      return true;
    }
    return false;
  }
}
