import { State, Action, StateContext } from '@ngxs/store';
import { CreatAndAddStateModel } from '@shared/types';
import {
  GetAdContent,
  GetAdContentSuccess,
  GetAdContentError
} from './create-and-ad.actions';
import { PublicPortalService } from '@shared/services/api/public-portal/public-portal.service';
import { Injectable } from '@angular/core';

@State<CreatAndAddStateModel>({
  name: 'createAndAd',
  defaults: {
    loading: null,
    message: null,
    status: null,
    adContent: null
  }
})
@Injectable()
export class CreateAndAdState {
  constructor(private publicPortalService: PublicPortalService) { }

  @Action(GetAdContent)
  getAdContent(
    { patchState }: StateContext<CreatAndAddStateModel>,
    action: GetAdContent
  ) {
    const { portalID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.publicPortalService.getAddContent(portalID);
  }

  @Action(GetAdContentSuccess)
  getAdContentSuccess(
    { setState }: StateContext<CreatAndAddStateModel>,
    action: GetAdContentSuccess
  ) {
    const { payload, message, status } = action;
    const { sections: adContent } = payload;

    setState({
      loading: false,
      message,
      status,
      adContent
    });
  }

  @Action(GetAdContentError)
  getAdContentError(
    { patchState }: StateContext<CreatAndAddStateModel>,
    action: GetAdContentError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }
}
