import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import {
  GetUniversityByURL,
  GetUniversitySuccess,
  GetUniversityError
} from './university.actions';
import { UniversityService } from '@shared/services/api/university/university.service';
import { University, AsyncModel } from '@shared/types';

export interface UniversityStateModel extends AsyncModel {
  university: University;
}

@State<UniversityStateModel>({
  name: 'university',
  defaults: {
    loading: false,
    university: null,
    status: null,
    message: null
  }
})
@Injectable()
export class UniversityState {
  constructor(protected universityService: UniversityService) { }

  @Action(GetUniversityByURL)
  get(ctx: StateContext<UniversityStateModel>, action: GetUniversityByURL) {
    this.universityService.getUniversityByDomain(action.domain);
  }

  @Action(GetUniversitySuccess)
  getUniversitySuccess(
    ctx: StateContext<UniversityStateModel>,
    action: GetUniversitySuccess
  ) {
    const { setState } = ctx;
    if (
      action.payload &&
      Array.isArray(action.payload.universities) &&
      action.payload.universities.length > 0
    ) {
      const university = action.payload.universities.pop();
      setState({
        loading: false,
        university,
        message: action.payload.message,
        status: null
      });
    }
  }

  @Action(GetUniversityError)
  getUniversityError(
    { patchState }: StateContext<UniversityStateModel>,
    action: GetUniversityError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }
}
