<div [id]="'player-' + index" *ngIf="mode === 'player'"></div>
<div *ngIf="mode === 'thumbnail'" fxLayout="row" fxLayoutAlign="center center">
  <img
    [src]="thumbnail()"
    (load)="previewLoad()"
    alt="External content, Youtube video"
  />
  <button
    mat-icon-button
    *ngIf="mode === 'thumbnail'"
    class="play-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
    matTooltip="Preview Content"
    tabindex="-1"
    aria-label="Preview Content Dialog"
    (click)="preview()"
  >
    <mat-icon>play_arrow</mat-icon>
  </button>
</div>
<button (click)="goToApp()" #openApp class="ios-handler">play video</button>
