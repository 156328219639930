import { Playlist, PlaylistContent, PlaylistCreation } from "@shared/modules/table/types";
import { PlaylistResponse } from "@shared/types";

export class GetPlaylists{
  static readonly type='GetPlaylists';
  constructor(
    public customerID:number,
    public formID:number,
    public body:any
  ){}
}

export class GetPlaylistNonSSO {
  static readonly type = 'GetPlaylistNonSSO';
  constructor(public playlistID: number) { }
}

export class GetPlaylistContentNonSSO {
  static readonly type = 'GetPlaylistContentNonSSO';
  constructor(public playlistID: number) { }
}

export class GetPlaylistsSuccess{
  static readonly type='GetPlaylistsSuccess';
  constructor(
    public payload:PlaylistResponse,
    public status='success',
    public message='Playlist Retrieved Successfully'
  ){}
}

export class GetPlaylistsError{
  static readonly type='GetPlaylistsError'
  constructor(
    public message:string,
    public status='error'
  ){}
}

export class GetPlaylist{
  static readonly type='GetPlaylist';
  constructor(
    public playlistID:number
  ){}
}

export class GetPlaylistSuccess{
  static readonly type='GetPlaylistSuccess';
  constructor(
    public payload:Playlist,
    public status='success',
    public message='Playlist Retrieved Successfully'
  ){}
}

export class GetPlaylistError{
  static readonly type='GetPlaylistError';
  constructor(
    public message:string,
    public status='error'
  ){}
}

export class GetXMLOutput{
  static readonly type='GetXMLOutput';
  constructor(
    public playlistID:number,
    public name:string
  ){}
}

export class GetXMLOutputSuccess{
  static readonly type='GetXMLOutputSuccess';
  constructor(
    public payload:any,
    public status='success',
    public message='XML Retrieved Successfully'
  ){}
}

export class GetXMLOutputError{
  static readonly type='GetXMLOutputError';
  constructor(
    public message:string,
    public status='error'
  ){}
}

export class GetPlaylistContent{
  static readonly type='GetPlaylistContent';
  constructor(public playlistID:number){}
}

export class GetPlaylistContentSuccess{
  static readonly type='GetPlaylistContentSuccess';
  constructor(
    public payload:PlaylistContent[],
    public status='success',
    public message='Playlist Content Retrieved Successfully'
  ){}
}

export class GetPlaylistContentError{
  static readonly type='GetPlaylistContentError';
  constructor(
    public message:string,
    public status='error'
  ){}
}

export class SavePlaylist{
  static readonly type='SavePlaylist';
  constructor(
    public playlist: PlaylistCreation
  ){}
}

export class SavePlaylistSuccess{
  static readonly type='SavePlaylistSuccess';
  constructor(
    public payload: Playlist,
    public status='success',
    public message='Playlist Saved Successfully'
  ){}
}

export class SavePlaylistError{
  static readonly type='SavePlaylistError';
  constructor(
    public message:any,
    public status='error'
  ){}
}

export class DeletePlaylist {
  static readonly type = 'DeletePlaylist';
  constructor(public playlistID: number) { }
}

export class DeletePlaylistSuccess {
  static readonly type = 'DeletePlaylistSuccess';
  constructor(
    public payload: number,
    public status = 'success',
    public message = 'Playlist Deleted Successfully'
  ) { }
}

export class DeletePlaylistError {
  static readonly type = 'DeletePlaylistError';
  constructor(public message: string, public status = 'error') { }
}

export class TogglePlaylistApproveStatus {
  static readonly type = 'TogglePlaylistApproveStatus';
  constructor(
    public playlistIDs: Array<number>,
    public approveStatus: boolean
  ) { }
}

export class TogglePlaylistApproveStatusSuccess {
  static readonly type = 'TogglePlaylistApproveStatusSuccess';
  constructor(
    public payload: Array<number>,
    public approveStatus: boolean,
    public status = 'success',
    public message = 'Playlist Status Updated Successfully'
  ) { }
}

export class TogglePlaylistApproveStatusError {
  static readonly type = 'TogglePlaylistApproveStatusError';
  constructor(public message: string, public status = 'error') { }
}

export class TogglePlaylistMuteStatus {
  static readonly type = 'TogglePlaylistMuteStatus';
  constructor(
    public playlistID: number,
    public muteStatus: boolean
  ) { }
}

export class TogglePlaylistMuteStatusSuccess {
  static readonly type = 'TogglePlaylistMuteStatusSuccess';
  constructor(
    public playlistID: number,
    public muteStatus: boolean,
    public status = 'success',
    public message = 'Playlist Muted Updated Successfully'
  ) { }
}

export class TogglePlaylistMuteStatusError {
  static readonly type = 'TogglePlaylistMuteStatusError';
  constructor(public message: string, public status = 'error') { }
}

export class DeletePlaylistContent{
  static readonly type='DeletePlaylistContent';
  constructor(public playlistID: number, public contentID: number){}
}

export class DeletePlaylistContentSuccess{
  static readonly type='DeletePlaylistContentSuccess';
  constructor(
    public contentID: number,
    public message: string,
    public status= 'success'
  ){}
}

export class DeletePlaylistContentError{
  static readonly type='DeletePlaylistContentError';
  constructor(public message: string, public status='error'){}
}


export class RearrangePlaylistContent {
  static readonly type = 'RearrangePlaylistContent';
  constructor(
    public playlistID: number,
    public playlistContent: Array<PlaylistContent>
  ) { }
}

export class RearrangePlaylistContentSuccess {
  static readonly type = 'RearrangePlaylistContentSuccess';
  constructor(
    public payload: Array<PlaylistContent>,
    public status = 'success',
    public message = 'Playlistcontent Rearranged Successfully'
  ) { }
}

export class RearrangePlaylistContentError {
  static readonly type = 'RearrangePlaylistContentError';
  constructor(public message: string, public status = 'error') { }
}
