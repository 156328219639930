import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthStateModel, AuthState } from '@shared/states/auth/auth.state';

@Injectable({
  providedIn: 'root'
})
export class SharedHttpService {
  auth$ = this.store.select<AuthStateModel>(AuthState);

  
  constructor(private http: HttpClient, private store: Store) {}

  get(
    url: string,
    headers?: HttpHeaders,
    params?: HttpParams,
    responseType?: any
  ): Observable<any> {
    return this.http.get(url, {
      headers,
      params,
      responseType
    });
  }

  post(
    url: string,
    body: any,
    headers?: HttpHeaders,
    params?: HttpParams
  ): Observable<any> {
    return this.http.post(url, body, {
      headers,
      params
    });
  }

  put(
    url: string,
    body: any,
    headers?: HttpHeaders,
    params?: HttpParams
  ): Observable<any> {
    return this.http.put(url, body, {
      headers,
      params
    });
  }

  delete(
    url: string,
    headers?: HttpHeaders,
    params?: HttpParams
  ): Observable<any> {
    return this.http.delete(url, {
      headers,
      params
    });
  }
}
