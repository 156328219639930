import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class YouTubePlayerService {
  YOUTUBE_API = 'https://www.youtube.com/iframe_api';
  script: HTMLScriptElement;
  constructor() { }

  loadAPI(): void {
    this.script = document.createElement('script');
    this.script.src = this.YOUTUBE_API;
    document.body.appendChild(this.script);
  }
  getVideoID(videoURL: string): string {
    return videoURL
      .split('/')
      .reverse()[0]
      .replace('watch?v=', '');
  }

  getVideoThumbnail(videoID: string): string {
    return `https://img.youtube.com/vi/${videoID}/0.jpg`;
  }
}
