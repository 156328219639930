import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  ElementRef,
  OnChanges,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  HostListener,
  Inject
} from '@angular/core';

import { PlayerStatus } from '../types';
import { YouTubePlayerService } from './youtube-player.service';
import { FeaturesService } from '@shared/services/features/features.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'portal-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss']
})
export class YoutubePlayerComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('openApp', { static: true }) openApp: ElementRef<HTMLAnchorElement>;
  @Input('duration') set duration(value: number) {
    this._duration = value || Infinity;
  }
  @Input() src: string;
  @Input() autoplay = 1;
  @Input() mode: 'player' | 'thumbnail' = 'player';
  // Send and index value if there is more that one youtube player at the same time
  @Input() index = -1;
  @Output() youtubeChange: EventEmitter<PlayerStatus> = new EventEmitter(null);
  player: any;
  _src: string;
  _duration: number;
  openOnApp: boolean;

  @HostListener('window:resize', ['$event'])
  resize() {
    if (this.el && this.mode !== 'thumbnail') {
      // Note(Danny): Forcing 16:9 ratio on YT videos...
      this.setRatio();
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private youTubePlayerService: YouTubePlayerService,
    private el: ElementRef,
    private featuresService: FeaturesService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // Create Source Snapshot
    this._src = this.src;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { autoplay } = changes;
    if (typeof autoplay?.previousValue === 'number' && autoplay?.previousValue !== autoplay.currentValue) {
      if (autoplay.currentValue === 1) {
        this.player.playVideo();
      } else {
        this.player.pauseVideo();
      }
    }
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector(`#player-${this.index}`)) {
      // Note(Danny): Forcing 16:9 ratio on YT videos...
      this.setRatio();
    }
    if (this.mode === 'player') {
      // Init Player
      this.initPlayer();
    }
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.destroy();
    }
  }

  initPlayer(): void {
    if (!!(window as any).YT) {
      this.createPlayer();
    } else {
      this.youTubePlayerService.loadAPI();
    }
    // Create Player after API loads
    (window as any).onYouTubeIframeAPIReady = () => {
      this.createPlayer();
    };
  }

  goToApp(): void {
    window.open(this._src, '_blank');
  }

  setRatio(): void {
    let attr = 'height';
    let ratio = (this.el.nativeElement.clientWidth / 16) * 9;
    if (ratio > this.document.body.clientHeight) {
      attr = 'width';
      ratio = (this.document.body.clientHeight / 9) * 16;
      this.renderer.setStyle(this.el.nativeElement.querySelector(`#player-${this.index}`), 'height', `${this.document.body.clientHeight}px`);
    }
    this.renderer.setStyle(this.el.nativeElement.querySelector(`#player-${this.index}`), attr, `${ratio}px`);
  }

  createPlayer(): void {
    this.player = new (window as any).YT.Player(`player-${this.index}`, {
      width: '100%',
      height: '100%',
      videoId: this.youTubePlayerService.getVideoID(this._src),
      events: {
        onPlayerReady: event => {
          this._playerReady(event);
        },
        onStateChange: event => {
          this._stateChange(event);
        },
        onError: () => {
          this._error();
        }
      },
      playerVars: {
        autoplay: this.autoplay,
        modestBranding: 1,
        iv_load_policy: 3,
        cc_lang_pref: 'en',
        cc_load_policy: 1,
        rel: 0,
        end: this._duration,
        origin: document.location.origin
      }
    });
  }

  thumbnail(): string {
    const videoID = this.youTubePlayerService.getVideoID(this._src);
    return this.youTubePlayerService.getVideoThumbnail(videoID);
  }

  preview(): void {
    this.youtubeChange.emit(PlayerStatus.PREVIEW);
  }

  previewLoad(): void {
    this.youtubeChange.emit(PlayerStatus.PLAYING);
  }

  _playerReady(event): void {
    event.target.playVideo();
  }

  _stateChange(event): void {
    const status = event.data;
    if (status === PlayerStatus.BUFFERING && this.featuresService.isMobile()) {
      this.openOnApp = true;
      this.player.stopVideo();
    }
    if (status === PlayerStatus.CUED && this.openOnApp) {
      this.openOnApp = false;
      this.openApp.nativeElement.click();
    }
    this.youtubeChange.emit(status);
  }

  _error(): void {
    this.youtubeChange.emit(PlayerStatus.ENDED);
  }
}
