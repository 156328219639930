import { AdContent } from '@shared/types';

export class GetAdContent {
  static readonly type = 'GetAdContent';
  constructor(public portalID: number) {}
}

export class GetAdContentSuccess {
  static readonly type = 'GetAdContentSuccess';
  constructor(
    public payload: AdContent,
    public message: string,
    public status: string
  ) {}
}

export class GetAdContentError {
  static readonly type = 'GetAdContentError';
  constructor(public message: string, public status: string) {}
}
