import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  UpdateEntrieNetworksSuccess,
  UpdateEntriePanelSuccess,
  UpdateEntrieNetworksError,
  UpdateEntriePanelError,
  UpdateEntrieStartEndDateSuccess,
  UpdateEntrieStartEndDateError,
  UpdateEntrieDurationError,
  UpdateEntrieDurationSuccess,
  UpdateStarredStatusError,
  UpdateStarredStatusSuccess,
  UpdateApprovedStatusError,
  UpdateApprovedStatusSuccess,
  UpdateFileSuccess,
  UpdateFileError,
  DeleteFileError,
  DeleteFileSuccess,
  RetryEntrySuccess,
  RetryEntryError
} from '@shared/states/entries/entries.actions';
import {
  UpdateEntriePanelHomeSuccess,
  UpdateEntriePanelHomeError,
  UpdateApprovedStatusHomeSuccess,
  UpdateApprovedStatusHomeError,
  UploadFileSuccess,
  UploadFileError,
  RetryTranscodeHomeSuccess,
  RetryTranscodeHomeError,
} from '@shared/states/files/files.actions';
import { HttpHeaders } from '@angular/common/http';
import {
  UploadLogoSuccess,
  UploadLogoError
} from '@shared/states/visual/visual.actions';
import {
  EntriesBodyParams,
  FilesResponse,
  HttpOptions,
  CHIAFile,
  FILE_TYPE,
  LogoUpload
} from '@shared/types';
import { environment } from 'environments/environment';
import { SharedHttpService } from '../api/shared-http/shared-http.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'file';
  private port = '8004';
  private success = 'success';
  private error = 'error';
  readonly imageRegExp = new RegExp(/^(https|http):\/\/.*(\.jpg|\.jpeg)$/, 'i');
  readonly videoRegExp = new RegExp(/^(https|http):\/\/.*(\.mp4)$/, 'i');
  readonly youtubeRegExp = new RegExp(
    /^(https|http):\/\/(www\.youtube\.com\/watch\?v=|youtu.be|www\.youtube\.com\/embed\/).{11}$/,
    'i'
  );
  pageRegExp = new RegExp(/^(https|http):\/\/.*\.([a-z]{2,3})(\/.*)*/, 'i');
  constructor(private httpService: SharedHttpService, private store: Store) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  updateEntrieNetworks(fileID: number, body: Array<number>): void {
    const path = `${this.API}/file/update/networks/${fileID}`;
    this.httpService.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateEntrieNetworksSuccess({ fileID, networkIDs: body })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieNetworksError(message));
      }
    );
  }

  updateEntriePanels(fileID: number, panelID: number): void {
    const path = `${this.API}/file/update/${fileID}/panel/${panelID}`;
    this.httpService.put(path, {}).subscribe(
      (res: any) => {
        this.store.dispatch(new UpdateEntriePanelSuccess({ fileID, panelID }));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntriePanelError(message));
      }
    );
  }

  updateEntriePanelsHome(fileID: number, panelID: number): void {
    const path = `${this.API}/file/update/${fileID}/panel/${panelID}`;
    this.httpService.put(path, {}).subscribe(
      (res: any) => {
        const message = 'Panel Updated Successfully';
        this.store.dispatch(
          new UpdateEntriePanelHomeSuccess(
            { fileID, panelID },
            message,
            this.success
          )
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new UpdateEntriePanelHomeError(message, this.error)
        );
      }
    );
  }

  updateEntrieStartEndDate(
    fileID: number,
    body: { startDate: string; endDate: string }
  ): void {
    const path = `${this.API}/file/update/startAndEndDate/${fileID}`;
    this.httpService.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateEntrieStartEndDateSuccess({ fileID, dates: body })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieStartEndDateError(message));
      }
    );
  }

  updateEntriesDuration(fileID: number, duration: number): void {
    const path = `${this.API}/file/update/${fileID}/duration/${duration}`;
    this.httpService.put(path, {}).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateEntrieDurationSuccess({ fileID, duration })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieDurationError(message));
      }
    );
  }

  updateStarredStatus(starred: boolean, body: Array<number>): void {
    const path = `${this.API}/file/starred/${starred}`;

    this.httpService.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateStarredStatusSuccess({
            starredStatus: starred,
            fileIDs: body
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateStarredStatusError(message));
      }
    );
  }

  updateApprovedStatus(approved: boolean, body: Array<number>): void {
    const path = `${this.API}/file/curate/${approved}`;

    this.httpService.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateApprovedStatusSuccess({
            approvedStatus: approved,
            fileIDs: body
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateApprovedStatusError(message));
      }
    );
  }
  

  // downloadFile(fileID: number, fileVersion: 'transcoded' | 'original'): void {
  //   const path = `${this.API}/portals/file/download/${fileVersion}/${fileID}`;
  //   window.open(path, '_blank');
  // }

  upDateFile(fileID: number, fileReq: CHIAFile, file: any): void {
    const path = `${this.API}/file/update/${fileID}`;
    const formData = new FormData();
    const options: HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    formData.append('fileReq', JSON.stringify(fileReq));
    formData.append('file', file ? file : null);

    this.httpService.put(path, formData).subscribe(
      (res: any) => {
        this.store.dispatch(new UpdateFileSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateFileError(message));
      }
    );
  }

  deleteFile(fileID: number): void {
    const path = `${this.API}/file/delete/file/${fileID}`;

    this.httpService.delete(path).subscribe(
      (res): any => {
        this.store.dispatch(new DeleteFileSuccess({ fileID }));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new DeleteFileError(message));
      }
    );
  }

  retryTranscodeHome(fileID: number): void {
    const path = `${this.API}/file/transcode/retry/${fileID}`;

    this.httpService.put(path, fileID).subscribe(
      (res): any => {
        const message = 'Transcode File In-Progress';
        this.store.dispatch(
          new RetryTranscodeHomeSuccess(fileID, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new RetryTranscodeHomeError(message, this.error));
      }
    );
  }

  retryTranscodeEntries(fileID): void {
    const path = `${this.API}/file/transcode/retry/${fileID}`;

    this.httpService.put(path, fileID).subscribe(
      (res): any => {
        const message = 'Transcode File In-Progress';
        this.store.dispatch(
          new RetryEntrySuccess(fileID, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new RetryEntryError(message, this.error));
      }
    );
  }

  updloadUniversityLogo(universityID: number, logo: any): void {
    const path = `${this.API}/file/upload/${universityID}`;
    const formData = new FormData();

    formData.append('file', logo);

    this.httpService.post(path, formData).subscribe(
      (res: LogoUpload) => {
        this.store.dispatch(new UploadLogoSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UploadLogoError(message));
      }
    );
  }

  updloadFile(universityID: number, file: any): void {
    const path = `${this.API}/file/upload/${universityID}`;
    const formData = new FormData();
    formData.append('file', file);

    this.httpService.post(path, formData).subscribe(
      res => {
        const message = 'File Uploaded Successfully';
        this.store.dispatch(new UploadFileSuccess(res, this.success, message));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UploadFileError(message, this.error));
      }
    );
  }

  getFileType(url: string): FILE_TYPE {
    if (this.imageRegExp.test(url)) {
      return FILE_TYPE.Image;
    }
    if (this.videoRegExp.test(url)) {
      return FILE_TYPE.Video;
    }

    if (this.youtubeRegExp.test(url)) {
      return FILE_TYPE.YouTube;
    }

    if (this.pageRegExp.test(url)) {
      return FILE_TYPE.Page;
    }

    return FILE_TYPE.Unknown;
  }
}
