import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { SharedHttpService } from '../shared-http/shared-http.service';
import {
  GetSocialHUBSuccess,
  GetSocialHUBError
} from '@shared/states/home/home.actions';
import { CHIAFile, Entry, HttpOptions, LogoUpload, SocialHUB } from '@shared/types';
import {
  UpdateApprovedStatusSuccess,
  UpdateApprovedStatusError,
  UpdateStarredStatusSuccess,
  UpdateStarredStatusError,
  RetryEntrySuccess,
  RetryEntryError,
  UpdateEntriePanelSuccess,
  UpdateEntriePanelError,
  UpdateEntrieDurationSuccess,
  UpdateEntrieDurationError,
  UpdateEntrieStartEndDateSuccess,
  UpdateEntrieStartEndDateError,
  DeleteFileSuccess,
  DeleteFileError
} from '@shared/states/manage/manage.actions';
import { environment } from 'environments/environment';
import { Observable, of, Subject } from 'rxjs';
import {
  UpdateEntrieAdminPanelError,
  UpdateEntrieAdminPanelSuccess,
  UpdateEntrieDurationHome2Error,
  UpdateEntrieDurationHome2Success,
  UpdateEntrieDurationHomeError,
  UpdateEntrieDurationHomeSuccess,
  UpdateEntrieNetworksHomeError,
  UpdateEntrieNetworksHomeSuccess,
  UpdateEntriePanelHomeError,
  UpdateEntriePanelHomeSuccess,
  UpdateEntrieStartEndDateHomeError,
  UpdateEntrieStartEndDateHomeSuccess,
  UpdateHomeFileError,
  UpdateHomeFileSuccess,
  UpdateStarredStatusAdminError,
  UpdateStarredStatusAdminSuccess
} from '@shared/states/files/files.actions';
import { HttpHeaders } from '@angular/common/http';
import { UpdateFileError, UpdateFileSuccess } from '../../../states/entries/entries.actions';
import { UploadLogoError, UploadLogoSuccess } from '../../../states/visual/visual.actions';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'file';
  private port = '8005';
  private success = 'success';
  private error = 'error';
  httpService: any;

  constructor(private sharedHttp: SharedHttpService, private store: Store,
    private router: Router, 
    private route: ActivatedRoute) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  getSocialHUB(formID: number): void {
    const path = `${this.API}/portals/home/socialhub/getcontent/${formID}`;

    this.sharedHttp.get(path).subscribe(
      (res: SocialHUB) => {
        const message = res.message;
        this.store.dispatch(
          new GetSocialHUBSuccess(res, this.success, message)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetSocialHUBError(message, this.error));
      }
    );
  }

  getSocialHubByFilters(formID: number, panel?: string, network?: string, adType?: string, tag?: string, affiliation?: string): Observable<any> {
    var subject$ = new Subject<Entry[]>();
    let path = `${this.API}/portals/home/socialhub/getcontent/${formID}?`;
    let _res: Entry[];

    if (panel)
      path = path.concat("panel=" + panel + "&")

    if (network)
      path = path.concat("network=" + network + "&")

    if (adType)
      path = path.concat("adtype=" + adType + "&")

    if (tag)
      path = path.concat("tag=" + tag + "&")

    if (affiliation)
      path = path.concat("affiliation=" + affiliation)

    this.sharedHttp.get(path).subscribe((res: SocialHUB) => {
      if (res) {
        subject$.next(res.entries);
      }
    })
    return subject$.asObservable();
  }

  updateApprovedStatus(approved: boolean, body: Array<number>): void {
    const path = `${this.API}/file/curate/${approved}`;

    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateApprovedStatusSuccess({
            approvedStatus: approved,
            fileIDs: body
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateApprovedStatusError(message));
      }
    );
  }

  updateStarredStatus(starred: boolean, body: Array<number>): void {
    const path = `${this.API}/file/starred/${starred}`;

    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateStarredStatusSuccess({
            starredStatus: starred,
            fileIDs: body
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateStarredStatusError(message));
      }
    );
  }

  updateStarredStatusAdmin(starred: boolean, body: Array<number>): void {
    const path = `${this.API}/file/starred/${starred}`;

    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateStarredStatusAdminSuccess({
            starredStatus: starred,
            fileIDs: body
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateStarredStatusAdminError(message));
      }
    );
  }

  retryTranscodeEntries(fileID): void {
    const path = `${this.API}/file/transcode/retry/${fileID}`;

    this.sharedHttp.put(path, fileID).subscribe(
      (res): any => {
        const message = 'Transcode File In-Progress';
        this.store.dispatch(
          new RetryEntrySuccess(fileID, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new RetryEntryError(message, this.error));
      }
    );
  }

  updateEntriePanels(fileID: number, panelID: number): void {
    const path = `${this.API}/file/update/${fileID}/panel/${panelID}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        this.store.dispatch(new UpdateEntriePanelSuccess({ fileID, panelID }));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntriePanelError(message));
      }
    );
  }

  updateEntriePanels2(fileID: number, panelID: number): void {
    const path = `${this.API}/file/update/${fileID}/panel/${panelID}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        const message = 'Panel Updated Successfully';
        this.store.dispatch(new UpdateEntriePanelHomeSuccess({ fileID, panelID }, message, this.success));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntriePanelHomeError(message, this.error));
      }
    );
  }

  updateEntrieAdminPanels(fileID: number, panelID: number): void {
    const path = `${this.API}/file/update/${fileID}/panel/${panelID}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        this.store.dispatch(new UpdateEntrieAdminPanelSuccess({ fileID, panelID }));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieAdminPanelError(message));
      }
    );
  }



  updateEntriesDuration(fileID: number, duration: number): void {
    const path = `${this.API}/file/update/${fileID}/duration/${duration}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateEntrieDurationSuccess({ fileID, duration })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieDurationError(message));
      }
    );
  }

  updateEntriesDurationAdmin(fileID: number, duration: number): void {
    const path = `${this.API}/file/update/${fileID}/duration/${duration}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        const message = 'Duration Updated Successfully';
        this.store.dispatch(
          new UpdateEntrieDurationHomeSuccess({ fileID, duration }, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieDurationHomeError(message, this.error));
      }
    );
  }

  updateEntriesDurationAdmin2(fileID: number, duration: number): void {
    const path = `${this.API}/file/update/${fileID}/duration/${duration}`;
    this.sharedHttp.put(path, {}).subscribe(
      (res: any) => {
        const message = 'Duration Updated Successfully';
        this.store.dispatch(
          new UpdateEntrieDurationHome2Success({ fileID, duration }, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieDurationHome2Error(message, this.error));
      }
    );
  }

  updateEntrieNetworksAdmin(fileID: number, body: Array<number>): void {
    const path = `${this.API}/file/update/networks/${fileID}`;
    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        const message = 'Networks Updated Successfully';
        this.store.dispatch(
          new UpdateEntrieNetworksHomeSuccess(
            { fileID, networkIDs: body },
            message,
            this.success
          )
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new UpdateEntrieNetworksHomeError(message, this.error)
        );
      }
    );
  }

  downloadFile(fileID: number, fileVersion: 'transcoded' | 'original'): void {
    const path = `${this.API}/portals/file/download/${fileVersion}/${fileID}`;
    window.open(path, '_blank');
  }

  updateEntrieStartEndDate(
    fileID: number,
    body: { startDate: string; endDate: string }
  ): void {
    const path = `${this.API}/file/update/startAndEndDate/${fileID}`;
    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateEntrieStartEndDateSuccess({ fileID, dates: body })
        );
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntrieStartEndDateError(message));
      }
    );
  }

  updateEntrieStartEndDateAdmin(
    fileID: number,
    body: { startDate: string; endDate: string }
  ): void {
    const path = `${this.API}/file/update/startAndEndDate/${fileID}`;
    this.sharedHttp.put(path, body).subscribe(
      (res: any) => {
        const message = 'Dates Updated Successfully';
        this.store.dispatch(
          new UpdateEntrieStartEndDateHomeSuccess(
            { fileID, dates: body },
            message,
            this.success
          )
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new UpdateEntrieStartEndDateHomeError(message, this.error)
        );
      }
    );
  }

  deleteFile(fileID: number): void {
    const path = `${this.API}/file/delete/file/${fileID}`;

    this.sharedHttp.delete(path).subscribe(
      (res): any => {
        this.store.dispatch(new DeleteFileSuccess({ fileID }));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new DeleteFileError(message));
      }
    );
  }

  upDateAdminFile(fileID: number, fileReq: any, file: any): void {
    const path = `${this.API}/file/update/v2/${fileID}`;
    const formData = new FormData();

    formData.append('fileReq', JSON.stringify(fileReq));
    formData.append('file', file ? file : null);

    this.sharedHttp.put(path, formData).subscribe(
      (res: any) => {
        const message = 'File Updated Successfully';
        this.store.dispatch(
          new UpdateHomeFileSuccess(res, this.success, message)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateHomeFileError(message, this.error));
      }
    );
  }

  upDateFile(fileID: number, fileReq: CHIAFile, file: any): void {
    const path = `${this.API}/file/update/${fileID}`;
    const formData = new FormData();
    const options: HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    formData.append('fileReq', JSON.stringify(fileReq));
    formData.append('file', file ? file : null);

    this.httpService.put(path, formData).subscribe(
      (res: any) => {
        this.store.dispatch(new UpdateFileSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateFileError(message));
      }
    );
  }


  updloadUniversityLogo(universityID: number, logo: any): void {
    const path = `${this.API}/file/upload/${universityID}`;
    const formData = new FormData();

    formData.append('file', logo);

    this.httpService.post(path, formData).subscribe(
      (res: LogoUpload) => {
        this.store.dispatch(new UploadLogoSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UploadLogoError(message));
      }
    );
  }



}



