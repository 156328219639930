import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  Renderer2,
  OnChanges,
  SimpleChanges,
  Inject,
  HostListener,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { HomeState } from '@shared/states/home/home.state';
import { HomeStateModel, Orientation } from '@shared/types';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PlayerStatus } from '../types';

@Component({
  selector: 'portal-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnInit, OnChanges, AfterViewInit {
  home$ = this.store.select<HomeStateModel>(HomeState);

  
  @ViewChild('videoPlayer', { static: true }) video: ElementRef<
    HTMLVideoElement
  >;
  @Input() src: string;
  @Input('duration') set duration(value: number) {
    this._duration = value || Infinity;
  }
  @Input() mode: 'player' | 'thumbnail' = 'player';
  @Input() autoplay = true;
  @Output() videoChange: EventEmitter<PlayerStatus> = new EventEmitter(null);
  _src: string;
  _duration: number;
  orientation: Orientation;
  landscape = Orientation.LANDSCAPE_MOBILE;
  destroy$ = new Subject()

  get PlayerStatus() {
    return PlayerStatus;
  }


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2, private store: Store) {
    this.home$.pipe(takeUntil(this.destroy$)).subscribe(({ orientation }) => {
      this.orientation = orientation;
    });
  }

  ngOnInit() {
    // Note(Danny): Video forced to start at 100ms to load thumbnail on Safari.
    this._src = `${this.src}#t=0.1`;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { autoplay } = changes;
    if (typeof autoplay?.previousValue === 'boolean' && autoplay?.previousValue !== autoplay.currentValue) {
      if (autoplay.currentValue === true) {
        this.playing();
      } else {
        this.pause();
      }
    }
  }

  ngAfterViewInit() {
    // Default Configuration
    const { nativeElement: player } = this.video;
    player.autoplay = this.autoplay;
    if (this.mode === 'thumbnail') {
      this.renderer.removeAttribute(player, 'controls');
    }
    this.renderer.listen(player, 'loadedmetadata', () => {
      if (this.mode === 'thumbnail') {
        // Thumbnail fully loaded
        this.playing();
      }
    });

  }

  preview(): void {
    this.videoChange.emit(PlayerStatus.PREVIEW);
  }

  playing(): void {
    this.videoChange.emit(PlayerStatus.PLAYING);
  }

  pause(): void {
    this.video.nativeElement.pause();
    this.videoChange.emit(PlayerStatus.PAUSED);
  }
}
