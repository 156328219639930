import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { SubmissionFormStateModel } from '@shared/types';
import {
  GetSubmissionForm,
  GetSubmissionFormSuccess,
  GetSubmissionFormError,
  UploadSubmissionFormFile,
  UploadSubmissionFormFileSuccess,
  UploadSubmissionFormFileError,
  UpdateSubmissionForm,
  UpdateSubmissionFormSuccess,
  UpdateSubmissionFormError,
  GetLocalForm,
  GetLocalFormSuccess,
  GetLocalFormError,
  GetLocalSubmissionForm,
  GetLocalSubmissionFormSuccess,
  GetLocalSubmissionFormError,
  GetNonSSOLocalForm,
  GetFormAffiliations,
  GetFormAffiliationsSuccess,
  GetFormAffiliationsError,
  GetFormPanel,
  GetFormPanelSuccess,
  GetFormPanelError,
  GetFormTags,
  GetFormTagSuccess,
  GetFormTagError,
  GetFormAdTypeError,
  GetFormAdTypeSuccess,
  GetFormAdTypes,
  GetSubmissionForms,
  GetSubmissionFormsSuccess,
  GetSubmissionFormsError,
  GetFormPanels,
  GetFormNetworks,
  GetFormNetworksSuccess,
  GetFormNetworksError,
  GetFormPanelsError,
  GetFormPanelsSuccess,
  GetAdminNetworks,
  GetAdminNetworksSuccess,
  GetAdminNetworksError,
  GetSocialHubAdmin,
  GetSocialHubAdminSuccess,
  GetSocialHubAdminError,
  GetAdminPanelsByNetwork,
  GetAdminPanelsByNetworkSuccess,
  GetAdminPanelsByNetworkError
} from './submission-form.actions';
import { SubmissionFormService } from '@shared/services/api/submission-form/submission-form.service';
import { GetSocialHUB } from '../home/home.actions';

@State<SubmissionFormStateModel>({
  name: 'submissionForm',
  defaults: {
    submissionForms:null,
    submissionForm: null,
    localSubmissionForm: null,
    localForm: null,
    message: null,
    loading: false,
    status: null,
    affiliations: null,
    panels:null,
    tags:null,
    adTypes:null,
    formPanels:null,
    formNetworks:null,
    networksAdmin:null,
    socialHubAdmin:null,
    networkRelationPanels:null
  }
})
@Injectable()
export class SubmissionFormState {
  constructor(protected submissionFormService: SubmissionFormService) { }

  @Selector()
  static getNetworks(state:SubmissionFormStateModel){
    return state.formNetworks;
  }

  @Selector()
  static getPanels(state: SubmissionFormStateModel) {
    return state.formPanels;
  }

  @Action(GetSubmissionForm)
  getSubmissionForm(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetSubmissionForm
  ) {
    const { universityID } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getSubmissionForm(universityID);
  }

  @Action(GetSubmissionFormSuccess)
  getSubmissionFormSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetSubmissionFormSuccess
  ) {
    const { payload, message, status } = action;
    patchState({
      loading: false,
      message,
      status,
      submissionForm: payload
    });
  }

  @Action(GetSubmissionFormError)
  getSubmissionFormErrGetSubmissionFormError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetSubmissionFormError
  ) {
    const { message, status } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetSubmissionForms)
  getSubmissionForms(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetSubmissionForms
  ){
    const { universityID, userName }=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });
    this.submissionFormService.getSubmissionForms(universityID, userName);
  }

  @Action(GetSubmissionFormsSuccess)
  getSubmissionFormsSuccess(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetSubmissionFormsSuccess
  ){
    const {submissionForms, status}=action;
    patchState({
      submissionForms,
      message:'Submission Forms Retrieve',
      loading:false,
      status
    });
  }

  @Action(GetSubmissionFormsError)
  getSubmissionFormsError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetSubmissionFormsError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      status,
      message
    });
  }

  @Action(GetAdminNetworks)
  getAdminNetworks(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetAdminNetworks
  ){
    const {universityID}=action;
    patchState({
      loading:true,
      message:null,
      status:null
    });

    this.submissionFormService.getNetworksAdmin(universityID);
  }

  @Action(GetAdminNetworksSuccess)
  getAdminNetworksSuccess(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetAdminNetworksSuccess
  ){
    const {payload, message, status}=action;
    patchState({
      loading:false,
      message,
      status,
      networksAdmin:payload
    });
  }

  @Action(GetAdminNetworksError)
  getAdminNetworksError(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetAdminNetworksError
  ){
    const {message, status}=action;
    patchState({
      loading:false,
      message,
      status
    });
  }

  @Action(UploadSubmissionFormFile)
  uploadSubmissionFormFile(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UploadSubmissionFormFile
  ) {
    const { file } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.upLoadSubmissionFormFile(file);
  }

  @Action(UploadSubmissionFormFileSuccess)
  uploadSubmissionFormFileSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UploadSubmissionFormFileSuccess
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UploadSubmissionFormFileError)
  uploadSubmissionFormFileError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UploadSubmissionFormFileError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateSubmissionForm)
  updateSubmissionForm(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UpdateSubmissionForm
  ) {
    const { submissionForm, isAdminPortal } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.updateSubmissionForm(submissionForm, isAdminPortal);
  }

  @Action(UpdateSubmissionFormSuccess)
  updateSubmissionFormSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UpdateSubmissionFormSuccess
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(UpdateSubmissionFormError)
  updateSubmissionFormError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: UpdateSubmissionFormError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetLocalForm)
  getLocalForm(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalForm
  ) {
    const { univeristyID, portalName, user } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getLocalForm(univeristyID, portalName, user);
  }

  @Action(GetNonSSOLocalForm)
  getNonSSOLocalForm(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetNonSSOLocalForm
  ) {
    const { univeristyID, portalName } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.submissionFormService.getNonSSOLocalForm(univeristyID, portalName);
  }

  @Action(GetLocalFormSuccess)
  getLocalFormSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalFormSuccess
  ) {
    const { payload, message, status } = action;

    patchState({
      localForm: payload,
      loading: false,
      message,
      status
    });
  }

  @Action(GetLocalFormError)
  getLocalFormError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalFormError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetLocalSubmissionForm)
  getLocalSubmissionForm(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalSubmissionForm
  ) {
    const { univeristyID, portalName } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getLocalSubmissionForm(univeristyID, portalName);
  }

  @Action(GetLocalSubmissionFormSuccess)
  GetLocalSubmissionFormSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalSubmissionFormSuccess
  ) {
    const { payload, message, status } = action;

    patchState({
      localSubmissionForm: payload,
      loading: false,
      message,
      status
    });
  }

  @Action(GetLocalSubmissionFormError)
  getLocalSubmissionFormError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetLocalSubmissionFormError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetAdminPanelsByNetwork)
  getAdminPanelsByNetwork(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetAdminPanelsByNetwork
  ) {
    const { universityID, userId } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.submissionFormService.getAdminPanelsByNetwork(universityID, userId);
  }

  @Action(GetAdminPanelsByNetworkSuccess)
  getAdminPanelsByNetworkSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetAdminPanelsByNetworkSuccess
  ) {
    const { payload, message, status } = action;

    patchState({
      networkRelationPanels: payload,
      loading: false,
      message,
      status
    });
  }

  @Action(GetAdminPanelsByNetworkError)
  getAdminPanelsByNetworkError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetAdminPanelsByNetworkError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetFormAffiliations)
  getFormAffiliations(ctx: StateContext<SubmissionFormStateModel>, action: GetFormAffiliations) {
    const { formID } = action;
    this.submissionFormService.getPortalAffiliations(formID);
  }

  @Action(GetFormAffiliationsSuccess)
  getFormAffiliationsSuccess({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormAffiliationsSuccess) {
    const { affiliations, status } = action;

    patchState({
      affiliations,
      status,
    });
  }

  @Action(GetFormAffiliationsError)
  getFormAffiliationsError({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormAffiliationsError) {
    const { message, status } = action;

    patchState({
      affiliations: [],
      status,
      message
    });
  }

  @Action(GetFormPanel)
  getFormPanel(ctx:StateContext<SubmissionFormStateModel>, action: GetFormPanel){
    const{formID}=action;
    this.submissionFormService.getPortalPanels(formID);
  }

  @Action(GetFormPanelSuccess)
  getFormPanelSuccess({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormPanelSuccess) {
    const { panels, status } = action;

    patchState({
      panels,
      status,
    });
  }

  @Action(GetFormPanelError)
  getFormPanelError({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormPanelError) {
    const { message, status } = action;

    patchState({
      panels: [],
      status,
      message
    });
  }

  @Action(GetFormPanels)
  getFormPanels(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormPanels
  ) {
    const { formID } = action;
    patchState({
      loading: true,
      message: null,
      status: null,
      formPanels: null
    });

    this.submissionFormService.getSubmissionFormsPanels(formID);
  }

  @Action(GetFormPanelsSuccess)
  getFormPanelsSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormPanelsSuccess
  ) {
    const { status, panels } = action;

    patchState({
      loading: false,
      message: 'Form Panels Retrieve',
      formPanels: panels,
      status
    });
  }

  @Action(GetFormPanelsError)
  getFormPanelsError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormPanelsError
  ) {
    const { status, message } = action;
    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetFormNetworks)
  getFormNetworks(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormNetworks
  ) {
    const { formID } = action;
    patchState({
      loading: true,
      message: null,
      status: null
    });
    this.submissionFormService.getSubmissionFormsNetworks(formID);
  }

  @Action(GetFormNetworksSuccess)
  getFormNetworksSuccess(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormNetworksSuccess
  ) {
    const { networks, status } = action;

    patchState({
      loading: false,
      message: 'Form Networks Retrieve',
      formNetworks: networks,
      status
    });
  }

  @Action(GetFormNetworksError)
  getFormNetworksError(
    { patchState }: StateContext<SubmissionFormStateModel>,
    action: GetFormNetworksError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetFormTags)
  getFormTags(ctx:StateContext<SubmissionFormStateModel>, action: GetFormTags){
    const{formID}=action;
    this.submissionFormService.getPortalTags(formID);
  }

  @Action(GetFormTagSuccess)
  getFormTagSuccess({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormTagSuccess) {
    const { tags, status } = action;

    patchState({
      tags,
      status,
    });
  }

  @Action(GetFormTagError)
  getFormTagError({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormTagError) {
    const { message, status } = action;

    patchState({
      tags: [],
      status,
      message
    });
  }

  @Action(GetFormAdTypes)
  getFormAdTypes(ctx:StateContext<SubmissionFormStateModel>, action: GetFormAdTypes){
    const{formID}=action;
    this.submissionFormService.getPortalAdTypes(formID);
  }

  @Action(GetFormAdTypeSuccess)
  getFormAdTypeSuccess({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormAdTypeSuccess) {
    const { adTypes, status } = action;

    patchState({
      adTypes,
      status,
    });
  }

  @Action(GetFormAdTypeError)
  getFormAdTypeError({ patchState }: StateContext<SubmissionFormStateModel>, action: GetFormAdTypeError) {
    const { message, status } = action;

    patchState({
      tags: [],
      status,
      message
    });
  }

  @Action(GetSocialHubAdmin)
  getSocialHubAdmin(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetSocialHubAdmin
  ){
    const {universityID, user_name, body}=action;

    patchState({
      loading:true,
      message:null,
      status:null
    });
    this.submissionFormService.getSocialHubAdmin(universityID, user_name, body);
  }

  @Action(GetSocialHubAdminSuccess)
  getSocialHubAdminSuccess(
    {patchState}:StateContext<SubmissionFormStateModel>,
    action: GetSocialHubAdminSuccess
  ){
    const {socialHubAdmin, status}=action;
    patchState({
      loading:false,
      message:'Social Hub is Retrieved Successfully.',
      status,
      socialHubAdmin
    })
  }

  @Action(GetSocialHubAdminError)
  getSocialHubAdminError({ patchState }: StateContext<SubmissionFormStateModel>,
    action: GetSocialHubAdminError) {
    const { message, status } = action;

    patchState({
      loading:false,
      status,
      message
    });
  }

}
