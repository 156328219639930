<video
  #videoPlayer
  controls
  (playing)="playing()"
  (pause)="pause()"
  [ngClass]="['focusable', mode, orientation === landscape ? 'landscape' : '']"
  preload="metadata"
>
  <source [src]="_src" type="video/mp4" />
</video>
<button
  mat-icon-button
  *ngIf="mode === 'thumbnail'"
  class="play-icon"
  tabindex="-1"
  fxLayout="row"
  fxLayoutAlign="center center"
  matTooltip="Preview Content"
  aria-label="Preview Content Dialog"
  (click)="preview()"
>
  <mat-icon>play_arrow</mat-icon>
</button>
