import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { PublicPortalStateModel } from '@shared/types';
import { PublicPortalService } from '@shared/services/api/public-portal/public-portal.service';
import {
  GetPublicPortal,
  GetPublicPortalSuccess,
  GetPublicPortalError,
  GetFormNetworksPublic,
  GetFormNetworksPublicSuccess,
  GetFormNetworksPublicError
} from './public-portal.actions';

@State<PublicPortalStateModel>({
  name: 'publicPortal',
  defaults: {
    portalID: null,
    menuItems: null,
    logo: null,
    theme: null,
    slider: null,
    loading: null,
    message: null,
    status: null,
    networks: null,
    affiliations: null,
    tags:null,
    panels:null,
    adTypes:null
  }
})
@Injectable()
export class PublicPortalState {
  constructor(private publicPortalService: PublicPortalService) { }

  @Action(GetPublicPortal)
  getPublicPortal(
    { patchState }: StateContext<PublicPortalStateModel>,
    action: GetPublicPortal
  ) {
    const { univeristyID } = action;

    patchState({
      loading: true,
      message: null,
      status: null
    });

    this.publicPortalService.getPublicPortal(univeristyID);
  }

  @Action(GetPublicPortalSuccess)
  getPublicPortalSuccess(
    { patchState }: StateContext<PublicPortalStateModel>,
    action: GetPublicPortalSuccess
  ) {
    const { payload, message, status } = action;
    const { id: portalID, menuItems: menus, themeFeatures } = payload;
    const theme = themeFeatures.themeFeatures;
    const {
      logo_Url,
      logo_height,
      logo_width,
      revolutionSliderContent
    } = themeFeatures;
    const menuItems = menus.sort((menuA, menuB) => menuA.order - menuB.order);

    patchState({
      loading: false,
      message,
      status,
      portalID,
      menuItems,
      theme,
      slider: revolutionSliderContent,
      logo: {
        logo_Url,
        logo_height,
        logo_width
      }
    });
  }

  @Action(GetPublicPortalError)
  getPublicPortalErrorGetPublicPortalError(
    { patchState }: StateContext<PublicPortalStateModel>,
    action: GetPublicPortalError
  ) {
    const { message, status } = action;

    patchState({
      loading: false,
      message,
      status
    });
  }

  @Action(GetFormNetworksPublic)
  getFormNetworks(
    ctx: StateContext<PublicPortalStateModel>,
    action: GetFormNetworksPublic
  ) {
    const { formID } = action;

    this.publicPortalService.getPortalNetworks(formID);
  }

  @Action(GetFormNetworksPublicSuccess)
  getFormNetworksSuccess(
    { patchState }: StateContext<PublicPortalStateModel>,
    action: GetFormNetworksPublicSuccess
  ) {
    const { networks } = action;
    patchState({ networks });
  }

  @Action(GetFormNetworksPublicError)
  getFormNetworksError(
    { patchState }: StateContext<PublicPortalStateModel>,
    action: GetFormNetworksPublicError
  ) {
    const { message, status } = action;
    patchState({
      networks: [],
      message,
      status
    });
  }
}
