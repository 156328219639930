import { SocialHUB, Network, ViewMode, Orientation, Option, Filters, FiltersConfig } from '@shared/types';

export class GetSocialHUB {
  static readonly type = 'GetSocialHUB';
  constructor(public formID: number) { }
}

export class GetSocialHUBSuccess {
  static readonly type = 'GetSocialHUBSuccess';
  constructor(
    public payload: SocialHUB,
    public status: string,
    public message: string
  ) { }
}

export class GetSocialHUBError {
  static readonly type = 'GetSocialHUBError';
  constructor(public message: string, public status: string) { }
}

export class SetViewMode {
  static readonly type = 'SetViewMode';
  constructor(public mode: ViewMode) { }
}

export class SetOrientation {
  static readonly type = 'SetOrientation';
  constructor(public orientation: Orientation) { }
}

export class SetFilterByNetworkOpts {
  static readonly type = 'SetFilterByNetworkOpts';
  constructor(public filterByNetwork: Option[]) { }
}

export class SetFilterByAffiliationOpts {
  static readonly type = 'SetFilterByAffiliationOpts';
  constructor(public filterByAffiliation: Option[]) { }
}

export class SetFilterByTagOpts{
  static readonly type='SetFilterByTagOpts';
  constructor(public filterByTags:Option[]){}
}

export class SetFilterByPanelOpts{
  static readonly type='SetFilterByPanelOpts';
  constructor (public filterByPanels:Option[]){}
}

export class SetFilterByAdTypeOpts{
  static readonly type='SetFilterByAdTypeOpts';
  constructor(public filterByAdTypes:Option[]){}
}

export class FiltersChange {
  static readonly type = 'FiltersChange';
  constructor(public config: FiltersConfig) { }
}
