import {
  Component,
  OnInit,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  trigger,
  style,
  animate,
  transition,
  useAnimation,
  AnimationEvent
} from '@angular/animations';
import { Store, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HideMainTemplateService } from '@shared/services/features/hide-main-template.service';
import { FadeIn, FadeOut } from '@shared/animations';
import { PlaylistState } from '@shared/states/playlists/playlists.state';
import { Playlist, PlaylistContent, PlaylistStateModel } from '../table/types';
import { PlayerStatus } from '../feed-player/types';
import { GetPlaylistContentError, GetPlaylistContentNonSSO, GetPlaylistContentSuccess, GetPlaylistNonSSO } from '@shared/states/playlists/playlists.actions';

@Component({
  selector: 'portal-feed-player',
  templateUrl: './feed-player-admin.component.html',
  styleUrls: ['./feed-player-admin.component.scss'],
  animations: [
    trigger('rightToLeft', [
      transition('void => inL', [
        style({
          transform: 'translateX(100%)'
        }),
        animate('1.5s', style({ transform: 'translateX(0)' }))
      ]),
      transition('inL => void', [
        animate('1.5s', style({ transform: 'translateX(-100%)' }))
      ]),
      transition('void => inR', [
        style({
          transform: 'translateX(-100%)'
        }),
        animate('1.5s', style({ transform: 'translateX(0)' }))
      ]),
      transition('inR => void', [
        animate('1.5s', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('fadeCtrls', [
      transition(':enter', [
        useAnimation(FadeIn, { params: { time: '0.1s' } })
      ]),
      transition(':leave', [
        useAnimation(FadeOut, { params: { time: '0.1s' } })
      ])
    ])
  ]
})
export class FeedPlayerAdminComponent {
  @HostBinding('style.position') position = 'absolute';
  @HostBinding('style.left') left = '0';
  @HostBinding('style.top') top = '0';
  @HostBinding('style.overflow') overflow = 'hidden';
  playlists$ = this.store.select<PlaylistStateModel>(PlaylistState);
  animationState = 'inL';
  ngUnsubscribe$ = new Subject();
  id: number;
  config: Playlist;
  files: Array<PlaylistContent>;
  fileIndex = 0;
  source: string;
  duration: number;
  defaultDuration: number;
  style: Partial<CSSStyleDeclaration>;
  animationTrigger: boolean;
  timer: any;
  notContent: boolean;
  controls: boolean;
  hideControlsTime = 4;
  pause: boolean;
  ctrlTimer: any;
  overCtrl: boolean;
  fileEnded: boolean;
  mute: boolean;

  constructor(
    private hideMainTemplateService: HideMainTemplateService,
    private route: ActivatedRoute,
    private store: Store,
    private actions: Actions,
    private cdRef: ChangeDetectorRef
  ) {
    this.hideMainTemplateService.hideContent(false);
    this.route.queryParams.subscribe(({ playlistID }) => {
      this.id = Number.parseInt(playlistID, 10);
      this.playlists$
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe(playlists$ => {
          const { playlists } = playlists$;
          if (playlists && playlists.length > 0) {
            this.config = playlists[0];
            this.mute = this.config.mute;
            this.defaultDuration = this.config.standard_Duration;
            this.style = {
              width: `${this.config.width}px`,
              height: `${this.config.height}px`
            };
            if (!this.timer) {
              this.setTimer();
            }
          }
        });
      this.actions
        .pipe(
          takeUntil(this.ngUnsubscribe$),
          ofActionSuccessful(GetPlaylistContentSuccess, GetPlaylistContentError)
        )
        .subscribe(action$ => {
          if (action$ instanceof GetPlaylistContentSuccess) {
            const { payload } = action$;
            this.files = payload;

            if (this.files && this.files.length > 0) {
              this.notContent = false;
              this.fileIndex =
                this.fileIndex <= this.files.length - 1 ? this.fileIndex : 0;
              this.play();
            } else {
              this.notContent = true;
            }
          }
          if (action$ instanceof GetPlaylistContentError) {
            if (!this.files) {
              this.notContent = true;
              this.fileIndex = 0;
            }
          }
        });
      this.store.dispatch([
        new GetPlaylistNonSSO(this.id),
        new GetPlaylistContentNonSSO(this.id)
      ]);
    });
  }

  setTimer(): void {
    this.timer = setInterval(() => {
      this.store.dispatch(new GetPlaylistContentNonSSO(this.id));
    }, this.config.reload_time * 1000);
  }

  play(): void {
    const { cdnLink, duration } = this.files[this.fileIndex];
    this.source = cdnLink;
    this.duration = duration || this.defaultDuration;
  }

  next(): void {
    this.fileIndex =
      this.fileIndex + 1 <= this.files.length - 1 ? this.fileIndex + 1 : 0;
    this.play();
    this.animationTrigger = !this.animationTrigger;
    /* YT Player runs outside of Angular's zone when duration's file is higher than duration's YT video,
     Manual change detection need it */
    this.cdRef.detectChanges();
  }

  previous(): void {
    this.fileIndex =
      this.fileIndex - 1 >= 0 ? this.fileIndex - 1 : this.files.length - 1;
    this.play();
    this.animationTrigger = !this.animationTrigger;
    /* YT Player runs outside of Angular's zone when duration's file is higher than duration's YT video,
     Manual change detection need it */
    this.cdRef.detectChanges();
  }

  toLeft(): void {
    this.animationState = 'inR';
    setTimeout(() => {
      this.previous();
    });
  }

  toRight(): void {
    this.animationState = 'inL';
    setTimeout(() => {
      this.next();
    });
  }

  feedPlayerChange(event: PlayerStatus): void {
    if (event === PlayerStatus.ENDED || event === PlayerStatus.ERROR) {
      this.fileEnded = true;
      if (!this.pause) {
        this.fileEnded = false;
        this.next();
      }
    }
  }

  displayCtrls(event: MouseEvent): void {
    if (event.altKey) {
      this.controls = true;
      this.clearCtrlsTimer();
      if (!this.overCtrl) {
        this.setCtrlsTimer();
      }
    }
  }
  setCtrlsTimer(): void {
    this.ctrlTimer = setTimeout(
      () => (this.controls = false),
      this.hideControlsTime * 1000
    );
  }

  clearCtrlsTimer() {
    if (this.ctrlTimer) {
      clearTimeout(this.ctrlTimer);
    }
  }

  copyToClipboard(input: HTMLInputElement): void {
    input.select();
    document.execCommand('copy');
  }

  pausePlayPlayer(): void {
    this.pause = !this.pause;
    if (!this.pause && this.fileEnded) {
      this.next();
    }
  }

  animationDone(event: AnimationEvent): void {
    if (event.fromState === 'void') {
      this.animationState = 'inL';
    }
  }
}
