import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ControlValidationService {
  constructor() {}

  commonErrors(
    control: AbstractControl,
    inputName: string
  ): { [key: string]: any } {
    const { errors } = control;
    if (errors) {
      const { minlength, maxlength, min, max } = errors;
      return {
        required: `${inputName} is required`,
        minlength: `${inputName} must be at least ${minlength &&
          minlength.requiredLength} characters long`,
        maxlength: `${inputName} cannot be more than ${maxlength &&
          maxlength.requiredLength} characters long`,
        pattern: `${inputName} format is incorrect`,
        min: `${inputName} must be at least ${min && min.min}`,
        max: `${inputName} cannot be more than ${max && max.max}`,
        email: `${inputName} format should be prefix@sub-domain.domain`,
        matDatepickerParse: `${inputName} date is invalid`
      };
    }
  }
}
