import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import {
  SubmissionForm,
  FileUpload,
  EntriesBodyParams,
  EntriesResponse,
  Panel,
  LocalForm,
  EntryColumn,
  Network,
  Option,
  SocialHUB,
  EntrySubmitter,
  NetworkRelationPanel
} from '@shared/types';
import {
  GetSubmissionFormSuccess,
  GetSubmissionFormError,
  UploadSubmissionFormFileSuccess,
  UploadSubmissionFormFileError,
  UpdateSubmissionFormSuccess,
  UpdateSubmissionFormError,
  GetLocalFormSuccess,
  GetLocalFormError,
  GetLocalSubmissionFormSuccess,
  GetLocalSubmissionFormError,
  GetFormAffiliationsError,
  GetFormAffiliationsSuccess,
  GetFormPanelSuccess,
  GetFormPanelError,
  GetFormTagSuccess,
  GetFormTagError,
  GetFormAdTypeSuccess,
  GetFormAdTypeError,
  GetSubmissionFormsSuccess,
  GetSubmissionFormsError,
  GetFormNetworksSuccess,
  GetFormNetworksError,
  GetAdminNetworksSuccess,
  GetAdminNetworksError,
  GetSocialHubAdminSuccess,
  GetSocialHubAdminError,
  GetAdminPanelsByNetworkError,
  GetAdminPanelsByNetworkSuccess
} from '@shared/states/submission-form/submission-form.actions';
import { SharedHttpService } from '../shared-http/shared-http.service';
import {
  GetEntriesSuccess,
  GetEntriesError,
  GetFormPanelsSuccess,
  GetFormPanelsError,
  GetEntryColumnsSuccess,
  GetEntryColumnsError,
  UpdateEntryColumnsSuccess,
  UpdateEntryColumnsError
} from '@shared/states/manage/manage.actions';
import { environment } from 'environments/environment';
import { FeaturesService } from '@shared/services/features/features.service';
import { GetEntryAdminColumnsError, GetEntryAdminColumnsSuccess, UpdateSubmitterHomeError, UpdateSubmitterHomeSuccess, UpdateApprovedStatusHomeSuccess, UpdateApprovedStatusHomeError } from '@shared/states/files/files.actions';

@Injectable({
  providedIn: 'root'
})
export class SubmissionFormService {
  private API: string;
  private apiUrl = environment.apiUrl;
  private apiService = 'form';
  private port = '8004';
  private success = 'success';
  private error = 'error';
  public selectedSubmissionForm: Subject<SubmissionForm> = new Subject();
  constructor(
    private httpServices: SharedHttpService,
    private store: Store,
    private featureService:FeaturesService) {
    this.API = environment.production
      ? this.apiUrl.replace('{service}', this.apiService)
      : this.apiUrl.replace('{port}', this.port);
  }

  getSubmissionForm(universityID: number): void {
    const path = `${this.API}/portals/submissionform/portal/getForm/${universityID}`;
    this.httpServices.get(path).subscribe(
      (res: SubmissionForm) => {
        const message = 'Portal Form Retrieved Successfully';
        this.store.dispatch(
          new GetSubmissionFormSuccess(res, this.success, message)
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(new GetSubmissionFormError(error.message));
      }
    );
  }

  getNetworksAdmin(universityID: number): void {
    const user=this.featureService.getCredential('userId');
    const path = `${this.API}/admin/get/networks/${universityID}/user/${user}`;
    this.httpServices.get(path).subscribe(
      (res: any) => {
        const message = 'Networks Retrieved Successfully';
        this.store.dispatch(
          new GetAdminNetworksSuccess(res, this.success, message)
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(new GetAdminNetworksError(this.error, error.message));
      }
    );
  }

  getSubmissionForms(universityID: number, userName:string): void {
    //const path = `${this.API}/submissionform/find/${universityID}`;
    const path = `${this.API}/admin/get/${universityID}/formnames/${userName}`;
    this.httpServices.get(path).subscribe(
      (res: Array<SubmissionForm>) => {
        const message = 'Submission Forms Retrieved Successfully';
        this.store.dispatch(
          new GetSubmissionFormsSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetSubmissionFormsError(message, this.error));
      }
    );
  }

  upLoadSubmissionFormFile(file: File): void {
    const path = `${this.API}/portals/submissionform/portal/content/upload/file`;
    const formData = new FormData();
    formData.append('file', file);

    this.httpServices.post(path, formData).subscribe(
      (res: FileUpload) => {
        const { message, temporalUrl } = res;
        this.store.dispatch(
          new UploadSubmissionFormFileSuccess(
            temporalUrl,
            message,
            this.success
          )
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(
          new UploadSubmissionFormFileError(error.message, this.error)
        );
      }
    );
  }

  updateSubmissionForm(submissionForm: SubmissionForm, isAdminPortal:boolean): void {
    const path = isAdminPortal
    ?`${this.API}/admin/content/submit`
    :`${this.API}/portals/submissionform/portal/content/submit`;
    this.httpServices.post(path, submissionForm).subscribe(
      res => {
        const message = 'AD Updated Successfully';
        this.store.dispatch(
          new UpdateSubmissionFormSuccess(res, message, this.success)
        );
      },
      err => {
        const { error } = err;
        this.store.dispatch(
          new UpdateSubmissionFormError(error.message, this.error)
        );
      }
    );
  }

  updateSubmitterHome(fileID: number, submitter: EntrySubmitter): void {
    const path = `${this.API}/submissionform/update/submitter/${fileID}`;

    this.httpServices.put(path, submitter).subscribe(
      (res: any) => {
        this.store.dispatch(
          new UpdateSubmitterHomeSuccess({
            ...submitter,
            files_id: fileID
          })
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateSubmitterHomeError(message));
      }
    );
  }

  getSubmissionFormsList(customerID: number, body: EntriesBodyParams): void {
    const path = `${this.API}/submissionform/list/${customerID}`;

    this.httpServices.post(path, body).subscribe(
      (res: EntriesResponse) => {
        this.store.dispatch(new GetEntriesSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetEntriesError(message));
      }
    );
  }

  getSubmissionFormsPanels(formID: number): void {
    const path = `${this.API}/submissionform/getformpanels/${formID}`;
    this.httpServices.get(path).subscribe(
      (res: Array<Panel>) => {
        this.store.dispatch(new GetFormPanelsSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetFormPanelsError(message));
      }
    );
  }

  getLocalForm(universityID: number, portalName: string, user: string): void {
    const path = `${this.API}/controllocal/${universityID}/get/${portalName}?user=${user}`;

    this.httpServices.get(path).subscribe(
      (res: LocalForm) => {
        const message = 'Local Form Retrieved Successfully';
        this.store.dispatch(
          new GetLocalFormSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetLocalFormError(message, this.error));
      }
    );
  }

  getNonSSOLocalForm(universityID: number, portalName: string): void {
    const path = `${this.API}/portals/${universityID}/get/${portalName}`;

    this.httpServices.get(path).subscribe(
      (res: LocalForm) => {
        const message = 'Local Form Retrieved Successfully';
        this.store.dispatch(
          new GetLocalFormSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetLocalFormError(message, this.error));
      }
    );
  }

  getLocalSubmissionForm(universityID: number, portalName: string): void {
    const path = `${this.API}/controllocal/${universityID}/getForm/${portalName}`;

    this.httpServices.get(path).subscribe(
      (res: SubmissionForm) => {
        const message = 'Local Submission Form Retrieved Successfully';
        this.store.dispatch(
          new GetLocalSubmissionFormSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new GetLocalSubmissionFormError(message, this.error)
        );
      }
    );
  }

  getAdminPanelsByNetwork(universityID: number, userId: string): void {
    const path = `${this.API}/admin/get/netpanrel/university/${universityID}/user/${userId}`;

    this.httpServices.get(path).subscribe(
      (res: NetworkRelationPanel[]) => {
        const message = 'Panels Retrieved Successfully';
        this.store.dispatch(
          new GetAdminPanelsByNetworkSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new GetAdminPanelsByNetworkError(message, this.error)
        );
      }
    );
  }
  getEntryColumns(formID: number): void {
    const path = `${this.API}/controllocal/entries/get/${formID}/columns`;
    this.httpServices.get(path).subscribe(
      (res: Array<EntryColumn>) => {
        const message = 'Columns Restrieved Successfully';
        this.store.dispatch(
          new GetEntryColumnsSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetEntryColumnsError(message, this.error));
      }
    );
  }

  getEntryAdminColumns(formID: number): void {
    const path = `${this.API}/controllocal/entries/get/${formID}/columns`;
    this.httpServices.get(path).subscribe(
      (res: Array<EntryColumn>) => {
        const message = 'Columns Restrieved Successfully';
        this.store.dispatch(
          new GetEntryAdminColumnsSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetEntryAdminColumnsError(message, this.error));
      }
    );
  }



  updateEntryColumns(formID: number, columns: Array<EntryColumn>): void {
    const path = `${this.API}/controllocal/update/${formID}/entries/column/configuration`;

    this.httpServices.put(path, columns).subscribe(
      (res: Array<EntryColumn>) => {
        const message = 'Columns Configuration Saved Successfully';
        this.store.dispatch(
          new UpdateEntryColumnsSuccess(res, message, this.success)
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new UpdateEntryColumnsError(message, this.error));
      }
    );
  }

  getPortalAffiliations(formID: number): void {
    const path = `${this.API}/portals/submissionform/getformaffiliations/${formID}`;
    this.httpServices.get(path).subscribe((res: any[]) => {
      const affiliation: Option[] = res.map(r => ({ value: r.id, label: r.typeName }))
      this.store.dispatch(new GetFormAffiliationsSuccess(affiliation))
    }, err => {
      const { message } = err.error;
      this.store.dispatch(new GetFormAffiliationsError(message));
    })
  }

  getPortalPanels(formID:number):void{
    const path=`${this.API}/portals/submissionform/getformpanels/${formID}`;
    this.httpServices.get(path).subscribe((res:any)=>{
      const panels:Option[]=res.map(r=>({value:r.id, label:r.name}))
      this.store.dispatch(new GetFormPanelSuccess(panels))
    }, err=>{
      const {message}=err.error;
      this.store.dispatch(new GetFormPanelError(message));
    })
  }

  getPortalTags(formID:number):void{
    const path=`${this.API}/portals/submissionform/getformtags/${formID}`;
    this.httpServices.get(path).subscribe((res:any)=>{
      const tags:Option[]=res.map(r=>({value:r.id, label:r.tagName}))
      this.store.dispatch(new GetFormTagSuccess(tags))
    }, err=>{
      const {message}=err.error;
      this.store.dispatch(new GetFormTagError(message));
    })
  }

  getPortalAdTypes(formID:number):void{
    const path=`${this.API}/portals/submissionform/getformadtypes/${formID}`;
    this.httpServices.get(path).subscribe((res:any)=>{
      const adTypes:Option[]=res.map(r=>({value:r.id, label:r.name}))
      this.store.dispatch(new GetFormAdTypeSuccess(adTypes))
    }, err=>{
      const {message}=err.error;
      this.store.dispatch(new GetFormAdTypeError(message));
    })
  }

  getSubmissionFormsNetworks(formID: number): void {
    const path = `${this.API}/submissionform/getformnetworks/${formID}`;
    this.httpServices.get(path).subscribe(
      (res: Array<Network>) => {
        this.store.dispatch(new GetFormNetworksSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetFormNetworksError(message));
      }
    );
  }

  getSocialHubAdmin(universityID: number, user_email:string, body:EntriesBodyParams): void {
    const path = `${this.API}/admin/get/${universityID}/socialhub/${user_email}`;
    this.httpServices.post(path, body).subscribe(
      (res: SocialHUB) => {
        this.store.dispatch(new GetSocialHubAdminSuccess(res));
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(new GetSocialHubAdminError(message));
      }
    );
  }

  updateApprovedStatusHome(approved: boolean, body: Array<number>): void {
    const path = `${this.API}/admin/file/curate/${approved}`;

    this.httpServices.put(path, body).subscribe(
      (res: any) => {
        const message = 'Approved Status Updated Successfully';
        this.store.dispatch(
          new UpdateApprovedStatusHomeSuccess(
            {
              approvedStatus: approved,
              fileIDs: body
            },
            message,
            this.success
          )
        );
      },
      err => {
        const { message } = err.error;
        this.store.dispatch(
          new UpdateApprovedStatusHomeError(message, this.error)
        );
      }
    );
  }
}
