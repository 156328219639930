import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'chia-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() icon: string;
  @Input() customIcon: boolean;
  @HostBinding('style.display') get display() {
    return 'inline-block';
  }
  @HostBinding('style.height') get height() {
    return '24px';
  }
  @HostBinding('style.width') get width() {
    return '24px';
  }
  @HostBinding('style.fontSize') get fontSize() {
    return '24px';
  }
  @HostBinding('style.lineHeight') get lineHeight() {
    return '24px';
  }

  constructor() {}

  ngOnInit() {}
}
