import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FILE_TYPE } from '@shared/types';

@Component({
  selector: 'portal-preview-content',
  templateUrl: './preview-content.component.html',
  styleUrls: ['./preview-content.component.scss']
})
export class PreviewContentComponent implements OnInit, AfterViewInit {
  @ViewChild('content') content: ElementRef<HTMLDivElement>;
  FILE_TYPE = FILE_TYPE;
  facebookShare: string;
  twitterShare: string;
  contentOverflow: string;
  url:string;
  type:FILE_TYPE;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    if (this.data) {
      const { description, cdn_Link } = this.data.entry;
      this.url=cdn_Link;
      this.facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${cdn_Link}&quote=${description}`;
      this.twitterShare = `https://twitter.com/intent/tweet?text=${description}&url=${cdn_Link}`;
    }
  }

  ngAfterViewInit() {
    const { clientHeight: bodyHeight } = this.document.body;
    const { clientHeight: contentHeight } = this.content.nativeElement;
    if (contentHeight > bodyHeight) {
      this.contentOverflow = `${bodyHeight - 80}px`;
    }

  }

  fileTypeRecieved(type: FILE_TYPE): void {
    this.type = type;
    this.cdRef.detectChanges();
  }
}
