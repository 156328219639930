import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FeaturesService } from '@shared/services/features/features.service';
import { Entry, FILE_TYPE, HomeStateModel, Orientation } from '@shared/types';
import { PlayerStatus } from '@shared/modules/feed-player/types';
import { Store } from '@ngxs/store';
import { HomeState } from '@shared/states/home/home.state';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'portal-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss']
})
export class SlideshowComponent implements OnInit, AfterViewInit {
  home$ = this.store.select<HomeStateModel>(HomeState);

  
  @ViewChild('slides', { static: false }) slides: ElementRef<HTMLDivElement>;
  @Input() entries: Entry[] = [];
  @Input() selected = -1;
  @Input() hasWrapper = false;
  FILE_TYPE = FILE_TYPE;
  loaded = false;
  manager: any;
  transformX: number;
  showInfo = true;
  ytPlay = 0;
  videoPlay = false;
  screenX: number;
  canRender = 'none';
  unsubscribe$ = new Subject();
  isMobile: boolean;

  @HostListener('window:resize', ['$event'])
  resize() {
    this.screenX = this.el.nativeElement.clientWidth;
    this.transformX = -(this.selected * this.screenX);
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
  }

  constructor(
    private featuresService: FeaturesService,
    private renderer: Renderer2,
    private store: Store,
    private matDialog: MatDialog,
    private el: ElementRef) {
    this.isMobile = this.featuresService.isMobile();
    this.home$.pipe(takeUntil(this.unsubscribe$)).subscribe(({ orientation }) => {
      if (orientation === Orientation.LANDSCAPE_MOBILE) {
        this.showInfo = false;
      }
    })
  }

  ngOnInit(): void {
    this.selected = this.selected ?? 0;
  }

  ngAfterViewInit(): void {
    this.screenX = this.el.nativeElement.clientWidth;
    this.transformX = -(this.selected * this.screenX);
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);

    this.manager = new (window as any).Hammer.Manager(this.slides.nativeElement);
    this.manager.add(new (window as any).Hammer.Pan({ threshold: 0, pointers: 0 }));
    this.manager.on('pan', (event) => {
      if ((this.selected === 0 && event.deltaX > 0) || (this.selected === this.entries.length - 1 && event.deltaX < 0) || this.screenX > 960) {
        return;
      }
      const movementX = event.deltaX + this.transformX;
      this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${movementX}px)`);
      if (event.isFinal) {
        // Minimun pixel translation to active slide.
        if (Math.abs(event.deltaX) > 50) {
          if (event.deltaX < 0) {
            this.nextSlide();
          } else {
            this.prevSlide();
          }
        } else {
          this.incompleteSlide();
        }
      }
    });
  }

  indexPosition(i: number): Partial<CSSStyleDeclaration> {
    return {
      left: `calc((100% / ${this.entries.length}) * ${i})`,
      width: `calc(100% / ${this.entries.length})`
    };
  }

  fixedWidth(): string {
    return `calc(100% * ${this.entries.length})`;
  }

  getFileType(link: string): FILE_TYPE {
    return this.featuresService.getFileType(link);
  }

  isRenderRange(index: number): boolean {
    return Math.abs(this.selected - index) <= 2;
  }

  renderDelay(): Promise<any> {
    return new Promise(res => setTimeout(res, 400));
  }

  incompleteSlide(): void {
    this.renderer.addClass(this.slides.nativeElement, 'animating');
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
    this.slideEnd();
  }

  nextSlide(): void {
    this.ytPlay = 0;
    this.videoPlay = false;
    this.selected = this.selected + 1;
    this.transformX = -(this.selected * this.screenX);
    this.renderer.addClass(this.slides.nativeElement, 'animating');
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
    this.slideEnd();
  }

  prevSlide(): void {
    this.ytPlay = 0;
    this.videoPlay = false;
    this.selected = this.selected - 1;
    this.transformX = -(this.selected * this.screenX);
    this.renderer.addClass(this.slides.nativeElement, 'animating');
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
    this.slideEnd();
  }

  async slideEnd(): Promise<void> {
    // Note(Danny): Remove class when animation ends...
    await this.renderDelay();
    this.renderer.removeClass(this.slides.nativeElement, 'animating');
  }

  ytStatus(event: PlayerStatus): void {
    if (event === PlayerStatus.PLAYING) {
      this.ytPlay = 1;
    }
  }

  videoStatus(event: PlayerStatus): void {
    if (event === PlayerStatus.PLAYING) {
      this.videoPlay = true;
    }
  }

  toggleAutoplay(): void {
    this.ytPlay = this.ytPlay === 0 ? 1 : 0
  }

  shareOn(where: string): void {
    const { description, cdn_Link } = this.entries[this.selected];
    const url =
      where === 'facebook' ?
        `https://www.facebook.com/sharer/sharer.php?u=${cdn_Link}&quote=${description}` :
        `https://twitter.com/intent/tweet?text=${description}&url=${cdn_Link}`;
    window.open(url, '_blank');
  }

  leave(): void {
    this.matDialog.closeAll();
  }

}
