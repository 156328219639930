import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import {
  UniversityState,
  UniversityStateModel
} from '@shared/states/university/university.state';
import { takeUntil, switchMap } from 'rxjs/operators';
import { FeaturesService } from '@shared/services/features/features.service';
import { MenuItem, MenuItemType, PublicPortalStateModel } from '@shared/types';
import { PublicPortalState } from '@shared/states/public-portal/public-portal.state';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @ViewChild('txtColor') txtColor: ElementRef<
    HTMLSpanElement
  >;
  univeristy$ = this.store.select<UniversityStateModel>(UniversityState);
  publicPortal$ = this.store.select<PublicPortalStateModel>(PublicPortalState);
  unsubscribe$ = new Subject();
  menus: Array<MenuItem>;
  universityName: string;
  apogee: string;
  logo = 'assets/icons/logo.png';
  domain = encodeURIComponent(this.document.location.origin);
  facebookShare: string;
  twitterShare: string;
  hideTemplate: boolean;
  isIntegrate: boolean;

  constructor(@Inject(DOCUMENT) private document: Document,private store: Store, private featuresService: FeaturesService) {
    this.isIntegrate = this.document.location.pathname === '/integrate';
    this.apogee = this.featuresService.getCompany();
    this.subscriptions();
  }

  subscriptions(): void {
    this.featuresService.templateHidden
      .pipe(
        switchMap(hidden => {
          this.hideTemplate = hidden;
          return this.featuresService.footerTextColor;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(color => {
        if (color) {
          this.logo = this.featuresService.getCompanyLogo(color);
        }
      });
    this.featuresService.fixedMenu.subscribe(menus => {
      if (menus) {
        this.menus = menus;
      }
    });
    this.univeristy$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(universitystate$ => {
        const { university } = universitystate$;
        if (university) {
          this.universityName = university.name;
          this.facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${this.domain}&quote=${this.universityName}`;
          this.twitterShare = `https://twitter.com/intent/tweet?text=${this.universityName}&url=${this.domain}`;
        }
      });
    this.publicPortal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(publicportal => {
        const { menuItems, loading } = publicportal;
        if (!this.menus && !loading && menuItems) {
          this.menus = menuItems.filter(
            menu => menu.type === MenuItemType.LINK
          );
        }
      });
  }

  navigateToTop(event: Event): void {
    event.preventDefault();
    this.featuresService.navigateToTop();
    this.featuresService.navigatedToTop.next(true);
  }
}
