<img
  [class.portrait]="isPortrait"
  [src]="_src"
  alt=""
  draggable="false"
  #image
/>
<button
  tabindex="-1"
  mat-icon-button
  *ngIf="mode === 'thumbnail'"
  class="play-icon"
  fxLayout="row"
  fxLayoutAlign="center center"
  matTooltip="Preview Content"
  aria-label="Preview Content Dialog"
  (click)="preview()"
>
  <mat-icon>remove_red_eye</mat-icon>
</button>
