import {
  SubmissionForm,
  SubmissionFormField,
  EntrySubmitter,
  SubmissionFormSection,
  LocalForm,
  Option,
  Panel,
  Network,
  SocialHUB,
  NetworkRelationPanel,
  EntriesBodyParams
} from '@shared/types';

export class GetSubmissionForm {
  static readonly type = 'GetSubmissionForm';
  constructor(public universityID: number) { }
}

export class GetSubmissionFormSuccess {
  static readonly type = 'GetSubmissionFormSuccess';
  constructor(
    public payload: SubmissionForm,
    public status: string,
    public message: string
  ) { }
}

export class GetSubmissionFormError {
  static readonly type = 'GetSubmissionFormError';
  constructor(public message: string, public status = 'error') { }
}

export class GetSubmissionForms{
  static readonly type='GetSubmissionForms';
  constructor(public universityID:number, public userName: string){}
}

export class GetSubmissionFormsSuccess{
  static readonly type='GetSubmissionFormsSuccess';
  constructor(
    public submissionForms: SubmissionForm[],
    public message: string,
    public status:string
  ){}
}

export class GetSubmissionFormsError{
  static readonly type='GetSubmissionFormsError';
  constructor(public message:string, public status: string){}
}

export class GetAdminNetworks{
  static readonly type='GetAdminNetworks';
  constructor(
    public universityID: number){}
}

export class GetAdminNetworksSuccess{
  static readonly type='GetAdminNetworksSuccess';
  constructor(
    public payload: Network[],
    public status: string,
    public message: string ){}
}

export class GetAdminNetworksError{
  static readonly type='GetAdminNetworksError';
  constructor(
    public status: string,
    public message: string ){}
}

export class UploadSubmissionFormFile {
  static readonly type = 'UploadSubmissionFormFile';
  constructor(public file: File) { }
}

export class UploadSubmissionFormFileSuccess {
  static readonly type = 'UploadSubmissionFormFileSuccess';
  constructor(
    public payload: string,
    public message: string,
    public status: string
  ) { }
}

export class UploadSubmissionFormFileError {
  static readonly type = 'UploadSubmissionFormFileError';
  constructor(public message: string, public status: string) { }
}

export class UpdateSubmissionForm {
  static readonly type = 'UpdateSubmissionForm';
  constructor(public submissionForm: SubmissionForm, public isAdminPortal:boolean) { }
}

export class UpdateSubmissionFormSuccess {
  static readonly type = 'UpdateSubmissionFormSuccess';
  constructor(
    public payload: SubmissionForm,
    public message: string,
    public status: string
  ) { }
}

export class UpdateSubmissionFormError {
  static readonly type = 'UpdateSuUpdateSubmissionFormErrorbmissionError';
  constructor(public message: string, public status: string) { }
}

export class GetLocalForm {
  static readonly type = 'GetLocalForm';
  constructor(
    public univeristyID: number,
    public portalName: string,
    public user: string
  ) { }
}

export class GetNonSSOLocalForm {
  static readonly type = 'GetNonSSOLocalForm';
  constructor(public univeristyID: number, public portalName: string) { }
}

export class GetLocalFormSuccess {
  static readonly type = 'GetLocalFormSuccess';
  constructor(
    public payload: LocalForm,
    public message: string,
    public status: string
  ) { }
}

export class GetLocalFormError {
  static readonly type = 'GetLocalFormError';
  constructor(public message: string, public status: string) { }
}

export class GetLocalSubmissionForm {
  static readonly type = 'GetLocalSubmissionForm';
  constructor(public univeristyID: number, public portalName: string) { }
}

export class GetLocalSubmissionFormSuccess {
  static readonly type = 'GetLocalSubmissionFormSuccess';
  constructor(
    public payload: SubmissionForm,
    public message: string,
    public status: string
  ) { }
}

export class GetLocalSubmissionFormError {
  static readonly type = 'GetLocalSubmissionFormError';
  constructor(public message: string, public status: string) { }
}

export class GetAdminPanelsByNetwork {
  static readonly type = 'GetAdminPanelsByNetwork';
  constructor(public universityID: number, public userId: string) { }
}

export class GetAdminPanelsByNetworkSuccess {
  static readonly type = 'GetAdminPanelsByNetworkSuccess';
  constructor(
    public payload: NetworkRelationPanel[],
    public message: string,
    public status: string
  ) { }
}

export class GetAdminPanelsByNetworkError {
  static readonly type = 'GetAdminPanelsByNetworkError';
  constructor(public message: string, public status: string) { }
}

export class GetFormAffiliations {
  static readonly type = 'GetFormAffiliations';
  constructor(public formID: number) { }
}
export class GetFormAffiliationsSuccess {
  static readonly type = 'GetFormAffiliationsSuccess';
  constructor(public affiliations: Option[], public status = 'success') { }
}
export class GetFormAffiliationsError {
  static readonly type = 'GetFormAffiliationsError';
  constructor(public message: string, public status = 'error') { }
}

export class GetFormPanel{
  static readonly type='GetFormPanel';
  constructor(public formID: number) { }
}
export class GetFormPanelSuccess {
  static readonly type = 'GetFormPanelSuccess';
  constructor(public panels: Option[], public status = 'success') { }
}
export class GetFormPanelError {
  static readonly type = 'GetFormPanelError';
  constructor(public message: string, public status = 'error') { }
}

export class GetFormPanels {
  static readonly type = 'GetFormPanels';
  constructor(public formID: number) { }
}

export class GetFormPanelsSuccess {
  static readonly type = 'GetFormPanelsSuccess';
  constructor(public panels: Array<Panel>, public status = 'success') { }
}

export class GetFormPanelsError {
  static readonly type = 'GetFormPanelsError';
  constructor(public message: string, public status = 'error') { }
}

export class GetFormNetworks {
  static readonly type = 'GetFormNetworks';
  constructor(public formID: number) { }
}

export class GetFormNetworksSuccess {
  static readonly type = 'GetFormNetworksSuccess';
  constructor(public networks: Array<Network>, public status = 'success') { }
}

export class GetFormNetworksError {
  static readonly type = 'GetFormNetworksError';
  constructor(public message: string, public status = 'error') { }
}

export class GetFormTags{
  static readonly type='GetFormTags';
  constructor(public formID: number) { }
}
export class GetFormTagSuccess {
  static readonly type = 'GetFormTagSuccess';
  constructor(public tags: Option[], public status = 'success') { }
}
export class GetFormTagError {
  static readonly type = 'GetFormTagError';
  constructor(public message: string, public status = 'error') { }
}

export class GetFormAdTypes{
  static readonly type='GetFormAdTypes';
  constructor(public formID: number) { }
}
export class GetFormAdTypeSuccess {
  static readonly type = 'GetFormAdTypeSuccess';
  constructor(public adTypes: Option[], public status = 'success') { }
}
export class GetFormAdTypeError {
  static readonly type = 'GetFormAdTypeError';
  constructor(public message: string, public status = 'error') { }
}

export class GetSocialHubAdmin{
  static readonly type='GetSocialHubAdmin';
  constructor(public universityID, public user_name:string, public body: EntriesBodyParams){}
}

export class GetSocialHubAdminSuccess{
  static readonly type='GetSocialHubAdminSuccess';
  constructor(public socialHubAdmin:SocialHUB, public status='success'){}
}

export class GetSocialHubAdminError{
  static readonly type='GetSocialHubAdminError';
  constructor(public message:string, public status='error'){}
}

export class UpdateSubmitter {
  static readonly type = 'UpdateSubmitter';
  constructor(public fileID: number, public submitter: EntrySubmitter) { }
}


export class UpdateSubmitterSuccess {
  static readonly type = 'UpdateSubmitterSuccess';
  constructor(
    public payload: any,
    public status = 'success',
    public message = 'Submitter Updated Successfully'
  ) { }
}

export class UpdateSubmitterError {
  static readonly type = 'UpdateSubmitterError';
  constructor(public message: string, public status = 'error') { }
}
