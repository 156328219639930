import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

import { FeedPlayerAdminComponent } from './feed-player-admin.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { IconModule } from '../icon/icon.module';
import { FeedPlayerModule } from '../feed-player/feed-player.module';

@NgModule({
  declarations: [FeedPlayerAdminComponent],
  imports: [
    CommonModule,
    FlexModule,
    FeedPlayerModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule
  ],
  exports: [FeedPlayerAdminComponent],
  providers: []
})
export class FeedPlayerAdminModule {}
