
import { A11yModule } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { YouTubePlayer } from '@angular/youtube-player';
import { NgbCarousel, NgbCarouselConfig, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { PlayerStatus } from '@shared/modules/feed-player/types';
import { YoutubePlayerComponent } from '@shared/modules/feed-player/youtube-player/youtube-player.component';
import { YouTubePlayerService } from '@shared/modules/feed-player/youtube-player/youtube-player.service';
import { FileService } from '@shared/services/api/file-service/file.service';
import { FeaturesService } from '@shared/services/features/features.service';
import { GetEntryColumns } from '@shared/states/manage/manage.actions';
import { SubmissionFormState } from '@shared/states/submission-form/submission-form.state';
import { Entry, FieldType, FILE_TYPE, SubmissionFormStateModel } from '@shared/types';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'portal-slide-show-ad',
  templateUrl: './slide-show-ad.component.html',
  styleUrls: ['./slide-show-ad.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class SlideShowADComponent implements OnInit, OnDestroy {
  submissionForm$ = this.store.select<SubmissionFormStateModel>(SubmissionFormState);

  
  @ViewChild('slides', { static: false }) slides: ElementRef<HTMLDivElement>;
  @ViewChild('myCarousel') myCarousel:NgbCarousel;
  @ViewChildren(YouTubePlayer) player1: QueryList<YouTubePlayer>;

  unsubscribe$=new Subject();
  panel:string;
  network:string;
  adtype:string;
  tag:string;
  affiliation:string;
  formID:number;
  params:Params;
  entries: Entry[] = [];
  selected = 0;
  hasWrapper = true;
  FILE_TYPE = FILE_TYPE;
  loaded = false;
  manager: any;
  transformX: number;
  showInfo = false;
  ytPlay = 0;
  videoPlay:boolean = false;
  screenX: number;
  canRender = 'none';
  isMobile: boolean=false;
  showMetadata: boolean;
  //ngbSlide
  paused:boolean=false;
  pausedMp4:boolean=true;
  index:number;
  showT:boolean=true;
  timeoutMetadat: any;
  statusPlaying:PlayerStatus.PLAYING;
  statusPaused:PlayerStatus.PAUSED;
  playerStatus:PlayerStatus;

  @HostListener('window:resize', ['$event'])
  resize() {
    this.screenX = this.el.nativeElement.clientWidth;
    this.transformX = -(this.selected * this.screenX);
    if(this.slides)
      this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
  }

  constructor(
      @Inject(DOCUMENT) private document: Document,
      private renderer:Renderer2,
      private store:Store,
      private featuresService:FeaturesService,
      private el:ElementRef,
      private route:ActivatedRoute,
      private fileService:FileService,
      private youTubePlayerService: YouTubePlayerService,
      private config:NgbCarouselConfig) {
        this.showMetadata=false;
        config.pauseOnHover=false;
      }

  ngOnInit(): void {
    this.selected = this.selected ?? 0;
    this.getParams();
    this.suscriptions();
  }

  togglePaused() {
    if (this.paused) {
      this.myCarousel.cycle();
    } else {
      this.myCarousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlid(slideEvent: NgbSlideEvent) {
    if (slideEvent.direction === 'start' && this.selected == 0) {
        this.selected = this.entries.length - 1;
    } else if (slideEvent.direction === 'end') {
        if (this.selected == this.entries.length - 1) { 
          this.selected = 0;
        } else { 
          this.selected += 1;
        }
      } else { 
        this.selected -= 1
      }
      if(this.getFileType(this.entries[this.selected].cdn_Link)== FILE_TYPE.Image){
        this.myCarousel.cycle();
      }
  }

  onSlide(slideEvent: NgbSlideEvent){

    var myVideo: any = document.getElementById("playermp4"+this.selected);
    if(myVideo){
      if(!myVideo.paused)
        myVideo.pause();
    }

    if(this.player1.length>0){
      //smontejo-->find how get index inside array correct only video youtube and match with length player1
      let yt=this.player1.get(this.selected);
      if(yt)
        yt.stopVideo();
    }
  }

  setPlayer(index:number){
    this.index=index;
    //  if (this.el.nativeElement.querySelector(`#player-${this.index}`)) {
    //   this.setRatio();
    // }

    //this.initPlayer();
    return true;;
  }

  // _startVideo(): void {
  //   this.player = new (window as any).YT.Player(`player-${this.index}`, {
  //     width: '100%',
  //     height: '100%',
  //     //videoId: this.youTubePlayerService.getVideoID(this._src),
  //     //videoId:'7XXrdEudcpM',
  //     events: {
  //       onStateChange: event => {
  //         this._stateChange(event);
  //       },
  //       onError: () => {
  //         this._error();
  //       }
  //     },
  //     playerVars: {
  //       autoplay: 1,
  //       modestbranding: 1,
  //       controls: 1,
  //       disablekb: 1,
  //       rel: 0,
  //       showinfo: 0,
  //       fs: 0,
  //       playsinline: 1,
  //     }
  //   });
  // }

  // startVideo() {
  //   //this.reframed = false;
  //   this.player = new window['YT'].Player('player', {
  //     //videoId: '1yEi1G4JSMI',
  //     playerVars: {
  //       autoplay: 1,
  //       modestbranding: 1,
  //       controls: 1,
  //       disablekb: 1,
  //       rel: 0,
  //       showinfo: 0,
  //       fs: 0,
  //       playsinline: 1

  //     },
  //     events: {
  //       'onStateChange': this._stateChange.bind(this),
  //       'onError': this._error.bind(this),
  //       //'onReady': this._st.bind(this),
  //     }
  //   });
  // }

  // initPlayer(): void {
  //   // Return if Player is already created
  //   if (window['YT']) {
  //     this.startVideo();
  //     return;
  //   }

  //   var tag = document.createElement('script');
  //   tag.src = 'https://www.youtube.com/iframe_api';
  //   var firstScriptTag = document.getElementsByTagName('script')[0];
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //   /* 3. startVideo() will create an <iframe> (and YouTube player) after the API code downloads. */
  //   window['onYouTubeIframeAPIReady'] = () => this.startVideo();

  // }

  setRatio(): void {
    let attr = 'height';
    let ratio = (this.el.nativeElement.clientWidth / 16) * 9;
    if (ratio > this.document.body.clientHeight) {
      attr = 'width';
      ratio = (this.document.body.clientHeight / 9) * 16;
      this.renderer.setStyle(this.el.nativeElement.querySelector(`#player-${this.index}`), 'height', `${this.document.body.clientHeight}px`);
    }
    this.renderer.setStyle(this.el.nativeElement.querySelector(`#player-${this.index}`), attr, `${ratio}px`);
  }

  _stateChange(event:any): void {
    const status = event.data;
    if(status==0)//pause
      this.myCarousel.cycle();

    if (status == -1 || status == 1)//play
      this.myCarousel.pause();

    if(status==2)
      this.myCarousel.cycle();//End
    // if (status === PlayerStatus.BUFFERING && this.featuresService.isMobile()) {
    //   this.openOnApp = true;
    //   this.player.stopVideo();
    // }
    // if (status === PlayerStatus.CUED && this.openOnApp) {
    //   this.openOnApp = false;
    //   this.openApp.nativeElement.click();
    // }
    //this.youtubeChange.emit(status);
  }

  test(): void {
    this.screenX = this.el.nativeElement.clientWidth;
    this.transformX = -(this.selected * this.screenX);
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);

    this.manager = new (window as any).Hammer.Manager(this.slides.nativeElement);
    this.manager.add(new (window as any).Hammer.Pan({ threshold: 0, pointers: 0 }));
    this.manager.on('pan', (event) => {
      if ((this.selected === 0 && event.deltaX > 0) || (this.selected === this.entries.length - 1 && event.deltaX < 0) || this.screenX > 960) {
        return;
      }
      const movementX = event.deltaX + this.transformX;
      this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${movementX}px)`);
      if (event.isFinal) {
        // Minimun pixel translation to active slide.
        if (Math.abs(event.deltaX) > 50) {
          if (event.deltaX < 0) {
            this.nextSlide();
          } else {
            this.prevSlide();
          }
        } else {
          this.incompleteSlide();
        }
      }
    });
  }

  incompleteSlide(): void {
    this.renderer.addClass(this.slides.nativeElement, 'animating');
    this.renderer.setStyle(this.slides.nativeElement, 'transform', `translateX(${this.transformX}px)`);
    this.slideEnd();
  }

  getParams() {
    if (this.route.snapshot.queryParams['panel'])
      this.panel = this.route.snapshot.queryParams['panel'];

    if (this.route.snapshot.queryParams['network'])
      this.network =this.route.snapshot.queryParams['network'];

    if (this.route.snapshot.queryParams['adtype'])
      this.adtype = this.route.snapshot.queryParams['adtype'];

    if (this.route.snapshot.queryParams['tag'])
      this.tag = this.route.snapshot.queryParams['tag'];

    if (this.route.snapshot.queryParams['affiliation'])
      this.affiliation = this.route.snapshot.queryParams['affiliation'];
  }

suscriptions(){
    this.submissionForm$.pipe(takeUntil(this.unsubscribe$)).subscribe(form$=>{
      const {submissionForm}=form$;
      if(submissionForm){
        this.formID=submissionForm.id;
        this.getEntriesByFilter();
      }
    });
  }

  getEntriesByFilter() {
    this.fileService.getSocialHubByFilters(this.formID, this.panel, this.network, this.adtype, this.tag, this.affiliation)
      .subscribe(res => {
        if (res) {
          if(res.length>0){
            //res[11].cdn_Link="https://www.youtube.com/watch?v=huimjv35-Y4";
            res.map((x, index)=>{
              let _type=this.getFileType(x.cdn_Link);
                if(_type==FILE_TYPE.YouTube || _type==FILE_TYPE.Video)
                {
                  if(_type==FILE_TYPE.YouTube){
                    let videoId=this.getId(x.cdn_Link);
                    x.cdn_Link="https://www.youtube.com/embed/"+videoId;
                  }
                }

                if(_type== FILE_TYPE.Page || _type== FILE_TYPE.Unknown){
                  res=res.filter(item => item !== x)
                }
              });
            //res[0].cdn_Link=res[0].cdn_Link+'?autoplay=1';
            this.entries=res;
          }
        }
      });
  }

  _ready(event){
    //console.warn(event);
  }
  getId(url:string){
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }

  shareOn(socialNetwork: string){
    const { description, cdn_Link } = this.entries[this.selected];
    const url =
      socialNetwork === 'facebook' ?
        `https://www.facebook.com/sharer/sharer.php?u=${cdn_Link}&quote=${description}` :
        `https://twitter.com/intent/tweet?text=${description}&url=${cdn_Link}`;
    window.open(url, '_blank');
  }

  leave(){
  }

  nextSlide(): void {

    this.myCarousel.next();
  }

  prevSlide(): void {
    this.myCarousel.prev();
  }

  async slideEnd(): Promise<void> {
    // Note(Danny): Remove class when animation ends...
    await this.renderDelay();
    this.renderer.removeClass(this.slides.nativeElement, 'animating');
  }

  renderDelay(): Promise<any> {
    return new Promise(res => setTimeout(res, 400));
  }

  fixedWidth(): string {
    return `calc(100% * ${this.entries.length})`;
  }

  getFileType(link: string): FILE_TYPE {
    return this.featuresService.getFileType(link);
  }

  indexPosition(i: number): Partial<CSSStyleDeclaration> {
    return {
      left: `calc((100% / ${this.entries.length}) * ${i})`,
      width: `calc(100% / ${this.entries.length})`
    };
  }

  isRenderRange(index: number): boolean {
    return Math.abs(this.selected - index) <= 2;
  }

  toggleAutoplay(): void {
    this.ytPlay = this.ytPlay === 0 ? 1 : 0
  }

  ytStatus(event: PlayerStatus): void {
    if (event === PlayerStatus.PLAYING) {
      this.ytPlay = 1;
    }
  }

  tileChange(event: PlayerStatus): void {
    if (event === PlayerStatus.PREVIEW) {
      // if (this.fileType === FILE_TYPE.Page) {
      //   this.openPage();
      // } else {
      //   this.previewContent();
      // }
      //console.warn(event);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }
  _error(): void {
    //this.youtubeChange.emit(PlayerStatus.ENDED);
  }
  delayMetadata(){
    this.timeoutMetadat = setTimeout( () => { this.showMetadata=false; }, 2000 );
  }
  checkShow(){
    this.showMetadata=true;
    if(this.timeoutMetadat){
      clearTimeout(this.timeoutMetadat);
    }
  }

  onPlayPauseMp4(event:any){
    var myVideo: any = document.getElementById("playermp4"+this.selected);
    if(myVideo){
      if(!myVideo.paused){
        myVideo.pause();
      }
      else{
        myVideo.play();
      }
      this.pausedMp4=myVideo.paused;
    }
  }

  videoStatus(event: PlayerStatus): void {
    if (event === PlayerStatus.PLAYING) {
      this.myCarousel.pause();
    }else{
      this.myCarousel.cycle();
      this.pausedMp4=true;
    }
  }
}
