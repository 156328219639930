export const environment = {
  domain: document.domain,
  production: true,
  //apiUrl: 'https://{service}.orcatv.com',
  apiUrl: 'https://{service}.apogee-ces.us',
  forgotPasswordUrl: 'https://displays.orcatv.com/system/create/password.html',
  login(domain: string) {
    const API = this.apiUrl.replace('{service}', 'user');
    return `${API}/portals/request/authentication?requestedResource=${domain}`;
    //fix until sso and cookies work with DNS ########HARDCODED######### 
    //return `http://ec2-3-15-100-47.us-east-2.compute.amazonaws.com:8001/portals/request/authentication?requestedResource=${domain}`;//this is the good one according backend
    //return `http://ec2-3-12-56-215.us-east-2.compute.amazonaws.com:8001/portals/request/authentication?requestedResource=${domain}`;
  }
};
