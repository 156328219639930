import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { FloatingFieldComponent } from './floating-field.component';
import { InputsModule } from '../inputs/inputs.module';

@NgModule({
  declarations: [FloatingFieldComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    FlexModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    InputsModule,
    MatProgressSpinnerModule,
    MatTooltipModule
  ],
  exports: [FloatingFieldComponent]
})
export class FloatingFieldsModule {}
