<div
  class="home"
  [fxLayout]="!viewmode ? 'row wrap' : 'column'"
  fxLayoutAlign="center center"
>
  <!-- SLIDER -->
  <portal-revolution-slider
    *ngIf="revolutionSlider && revolutionSlider.length > 0"
    [fxFlex]="!viewmode ? '100%' : '308px'"
    fxShow.gt-sm
    fxHide
    [class.integrate]="isIntegrate"
    [revolutionSlider]="revolutionSlider"
  ></portal-revolution-slider>
  <!-- FILTERS -->
  <portal-filters
    *ngIf="orientation !== mobileLandscape"
    class="mat-elevation-z1"
    [fxFlex]="!!viewmode ? '42px' : '100%'"
    [fxFlex.gt-sm]="!!viewmode ? '67.5px' : '100%'"
    fxLayoutAlign.gt-xs="center center"
    fxLayoutAlign="flex-end center"
    [class.slideshow]="!!viewmode"
  ></portal-filters>
  <!-- GRID  -->
  <mat-grid-list
    *ngIf="socialHUB && socialHUB.length > 0 && !loading && !viewmode"
    aria-label="Social HUB"
    role="group"
    class="social-hub"
    [cols]="cols"
    [gutterSize]="tileSpacing"
    [rowHeight]="tileRatio"
  >
    <mat-grid-tile *ngFor="let entry of socialHUB; let i = index">
      <portal-hub-tile
        class="mat-elevation-z4"
        [entry]="entry"
        [tileIndex]="i"
        [autoplay]="false"
        mode="thumbnail"
      ></portal-hub-tile>
      <div
        [fxHide.gt-xs]="orientation !== mobileLandscape"
        fxShow
        (click)="slideShow(i)"
        class="mobile-handler"
      ></div>
    </mat-grid-tile>
  </mat-grid-list>
  <!-- SLIDESHOW -->
  <div
    class="social-hub slideshow"
    aria-label="Social HUB"
    role="group"
    fxFlex="auto"
    [fxFlex.gt-sm]="!!viewmode ? '800px' : 'auto'"
    *ngIf="socialHUB && socialHUB.length > 0 && !loading && !!viewmode"
  >
    <portal-slideshow
      *ngIf="!!viewmode"
      [entries]="socialHUB"
      [selected]="0"
    ></portal-slideshow>
  </div>
  <div
    class="loader"
    *ngIf="loading"
    fxFlex="100%"
    fxLayoutAlign="center center"
  >
    <mat-spinner diameter="60"></mat-spinner>
  </div>
  <mat-card
    *ngIf="(!socialHUB || socialHUB.length === 0) && loading === false"
    fxLayout="column"
    fxLayoutAlign="start center"
    [style.boxShadow]="'none'"
  >
    <mat-icon>ondemand_video</mat-icon>
    <h2 class="mat-h2">No Ads</h2>
    <p class="mat-body">You don’t seem to have submitted any Ads.</p>
    <p class="mat-body">
      Start by uploading content and it will come up in your feed.
    </p>
    <a mat-stroked-button routerLink="/submit">
      <mat-icon>arrow_upward</mat-icon>
      SUBMIT AN AD
    </a>
  </mat-card>
  <portal-wrong *ngIf="error && !loading" fxFlex="100%"></portal-wrong>
</div>
